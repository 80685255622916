import { types } from '../actions/types';

const initialState = {
    loading: false,
    data: {},
    error: {},
};

const getKycStatusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SEND_REQUEST_GET_KYC_STATUS:
            return {
                ...state,
                loading: true,
            };
        case types.SEND_REQUEST_GET_KYC_STATUS_SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
            };
        case types.SEND_REQUEST_GET_KYC_STATUS_FAILURE:
            return {
                ...state,
                data: {},
                error: payload,
                loading: false,
            };
        case types.CLEAR_REQUEST_GET_KYC_STATUS:
            return {
                ...state,
                data: {},
                loading: false,
            };
        default:
            return state;
    }
};

export default getKycStatusReducer;
