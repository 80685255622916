import DashboardLayout from '../../deals/DashboardLayout'
import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  Formik,
  FastField,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back } from "../../../../assets/img";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomCheckbox,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UPDATE_NOMINEE_DETAILS, SET_PAGE } from "../../../../store/actions";
import {
  FetchAllCountries,
  FetchAllStates,
  FetchAllCities,
  SaveQuestions,
  GetUserDetailsUpdated,
} from "../../../../api";
import backArrow from '../../../../assets/img/back.svg'

import Cookies from "js-cookie";
import { toast } from "react-toastify";

const NomineeInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nomineeDetails = useSelector(
    (state) => state.indianCitizenship.nomineeDetails
  );
  const [is_editable, setIsEditable] = useState(null);
  const [edit_access, setEditAccess] = useState(true);

  const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();

  const { mutateAsync: saveNominee, isLoading: isSaveNomineeDetailsLoading } =
    SaveQuestions();

  const [isMinor, setIsMinor] = React.useState(false);
  const [index, setIndex] = useState(0);

  const onNomineeCheckboxChanged = () => {
    setIsMinor(!isMinor);
  };

  const NomineeDetailsValidationSchema = {
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date().required("Date is required"),
    addressLineOne: Yup.string().required("First name is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal code is required"),
    nomineePanNumber: Yup.string().required("Nominee PAN number is required"),
    relationshipWithInvestor: Yup.string().required(
      "Relationship with investor is required"
    ),
  };

  const GuardianDetailsValidationSchema = {
    firstNameGuardian: Yup.string().required("First name is required"),
    lastNameGuardian: Yup.string().required("Last name is required"),
    addressLineOneGuardian: Yup.string().required(
      "Address line one is required"
    ),
    countryGuardian: Yup.string().required("Country is required"),
    stateGuardian: Yup.string().required("State is required"),
    cityGuardian: Yup.string().required("City is required"),
    postalCodeGuardian: Yup.string().required("Postal code is required"),
  };

  const theValidationSchema = !isMinor
    ? NomineeDetailsValidationSchema
    : {
      ...NomineeDetailsValidationSchema,
      ...GuardianDetailsValidationSchema,
    };

  const [checkDirectNominee, setcheckDirectNominee] = React.useState(true);
  useEffect(() => {
    setIsEditable(userData?.nominee_editable)
  }, [isDocsLoading])


  const toggleEdit = () => {
    // 👇️ passed function to setState
    setEditAccess(edit_access => !edit_access);
  };
  useEffect(() => {
    if (Cookies.get("redirectPath") != null) {
      const redirectPath = Cookies.get("redirectPath");
      if (redirectPath == "nominee") {
        var checkDirNominee = Cookies.get("checkNominee");
        if (checkDirNominee == "" || checkDirNominee != "Yes") {
          toast.error("Please verify mobile otp first");
          navigate("/verify-mobile-number");
        }

        setcheckDirectNominee(false);
        setIndex(0);

        Cookies.remove("checkNominee");
        Cookies.remove("redirectPath");
      }
    }
  }, []);

  const checkNomineeMinor = (nominee_dob) => {
    let today = new Date();
    let birthDate = new Date(nominee_dob);
    let age_now = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    if (age_now < 18) {
      setIsMinor(true)
      formik.values.minorNomineeCheckbox = true
    } else {
      setIsMinor(false)
      formik.values.minorNomineeCheckbox = false
    }
  }

  const formik = useFormik({
    initialValues: nomineeDetails,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(theValidationSchema),
    onSubmit: (values) => {
      let nomineeAddress = [];
      nomineeAddress.push({
        country_id: values.country,
        state_id: values.state,
        city_id: values.city,
        address_line_1: values.addressLineOne,
        address_line_2: values.addressLineTwo,
        zip_code: values.postalCode,
        address_type: "residential",
        user_type: "nominee",
      });
      if (values.minorNomineeCheckbox) {
        nomineeAddress.push({
          country_id: values.countryGuardian,
          state_id: values.stateGuardian,
          city_id: values.cityGuardian,
          address_line_1: values.addressLineOneGuardian,
          address_line_2: values.addressLineTwoGuardian,
          zip_code: values.postalCodeGuardian,
          address_type: "residential",
          user_type: "gaurdian",
        });
      }

      saveNominee(
        {
          payload: {
            nominee_details: {
              first_name: values.firstName,
              middle_name: values.middleName,
              last_name: values.lastName,
              dob: values.dateOfBirth,
              nominee_pan_number: values.nomineePanNumber,
              relation_of_nominee: values.relationshipWithInvestor,
              gurdian_first_name: values.firstNameGuardian,
              gurdian_last_name: values.lastNameGuardian,
            },
            address: nomineeAddress,
            page: "5",
            is_nominee_added: 'Yes',
            re_submit: false,
          },
        },
        {
          onSuccess: () => {
            dispatch({
              type: UPDATE_NOMINEE_DETAILS,
              payload: {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                country: values.country,
                state: values.state,
                city: values.city,
                postalCode: values.postalCode,
                dateOfBirth: values.dateOfBirth,
                nomineePanNumber: values.nomineePanNumber,
                relationshipWithInvestor: values.relationshipWithInvestor,
                minorNomineeCheckbox: values.minorNomineeCheckbox,
                firstNameGuardian:
                  (values.minorNomineeCheckbox && values.firstNameGuardian) ||
                  "",
                lastNameGuardian:
                  (values.minorNomineeCheckbox && values.lastNameGuardian) ||
                  "",
                addressLineOneGuardian:
                  (values.minorNomineeCheckbox &&
                    values.addressLineOneGuardian) ||
                  "",
                addressLineTwoGuardian:
                  (values.minorNomineeCheckbox &&
                    values.addressLineTwoGuardian) ||
                  "",
                countryGuardian:
                  (values.minorNomineeCheckbox && values.countryGuardian) || "",
                stateGuardian:
                  (values.minorNomineeCheckbox && values.stateGuardian) || "",
                cityGuardian:
                  (values.minorNomineeCheckbox && values.cityGuardian) || "",
                postalCodeGuardian:
                  (values.minorNomineeCheckbox && values.postalCodeGuardian) ||
                  "",
              },
            });
            navigate(-1)
            // dispatch({ type: SET_PAGE, payload: 7 });
            // toast.success("Nominee is Updated")
            // if (checkDirectNominee) {
            //   navigate("/indian-citizenship/personalize-your-profile");
            // } else {
            //   navigate("/registration-status");
            // }
          },
        }
      );
    },
  });
  const { data: countries, isLoading: isCountryLoading } = FetchAllCountries();
  const { data: states, isLoading: isStateLoading } = FetchAllStates(
    formik.values?.country || ""
  );
  const { data: cities, isLoading: isCityLoading } = FetchAllCities(
    formik.values?.state || ""
  );
  const { data: states2, isLoading: isStateLoading2 } = FetchAllStates(
    formik.values?.countryGuardian || ""
  );
  const { data: cities2, isLoading: isCityLoading2 } = FetchAllCities(
    formik.values?.stateGuardian || ""
  );

  useEffect(() => {
    checkNomineeMinor(formik.values.dateOfBirth)
  }, [formik.values.dateOfBirth])

  return (
    <>
      <DashboardLayout>
        {/* <div className='d-flex align-items-center justify-content-between cr-heading'>
          <div className="md-title text-start fw-700">
            Nominee Details
          </div>

          {is_editable && <div>
            <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
          </div>}

        </div> */}

        <div className='d-flex align-items-center justify-content-between cr-heading'>
          <div className="md-title text-start fw-700">
            <button className='back-btn' onClick={() => index === 1 ? setIndex(0) : navigate("/my-profile")} type='button'><img src={backArrow} alt='arrow' /></button>   Nominee Details
          </div>

          {is_editable && <div>
            <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
          </div>}

        </div>

        <div className="pt-4 pb-4 ps-5 pe-5">
          <div className="pt-0 pb-0 kyc-form-block pyp-block">
            <FormikProvider value={formik} sx={{ flex: 1 }}>
              <Form className="custom-form" onSubmit={formik.handleSubmit}>
                {index === 0 && (
                  <>
                    <h5 className="card-title md-title mb-4 text-center fw-700">
                      Nominee Information
                    </h5>
                    <div className="row" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      {/* First Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Nominee First Name</label>
                        <Field
                          name="firstName"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-6 form-group">
                        <label>Nominee Middle Name</label>
                        <Field
                          name="middleName"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Nominee Last Name</label>
                        <Field
                          name="lastName"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Date of Birth */}
                      <div className="col-md-6 form-group">
                        <label className="required">Date of Birth</label>
                        <Field
                          name="dateOfBirth"
                          component={CustomInputComponent}
                          type="date"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Address line one */}
                      <div className="col-md-6 form-group">
                        <label className="required">Address Line 1</label>
                        <Field
                          name="addressLineOne"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Address line two */}
                      <div className="col-md-6 form-group">
                        <label>Address Line 2</label>
                        <Field
                          name="addressLineTwo"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Country */}
                      <div className="col-md-6 form-group">
                        <label className="required">Country</label>
                        <Field
                          name="country"
                          component={CustomSelectComponent}
                          options={countries}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* State */}
                      <div className="col-md-6 form-group">
                        <label className="required">State</label>
                        <Field
                          name="state"
                          component={CustomSelectComponent}
                          options={states}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* City */}
                      <div className="col-md-6 form-group">
                        <label className="required">City</label>
                        <Field
                          name="city"
                          component={CustomSelectComponent}
                          options={cities}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Postal code */}
                      <div className="col-md-6 form-group">
                        <label className="required">Zip / Postal Code</label>
                        <Field
                          name="postalCode"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Nominee PAN number */}
                      <div className="col-md-6 form-group">
                        <label className="required">Nominee PAN Number</label>
                        <Field
                          name="nomineePanNumber"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                        {/* <div className="xs-title text-start mt-1">
                          *if nominee is minor mention Aadhar Card Number
                        </div> */}
                      </div>

                      {/* Relationship with investor */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Relationship With Investor
                        </label>
                        <Field
                          name="relationshipWithInvestor"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Is the nominee minor? */}
                      {/* <div className="col-12 form-group">
                        <Field
                          name="minorNomineeCheckbox"
                          component={CustomCheckbox}
                          label="Nominee is minor?"
                          onChange={onNomineeCheckboxChanged}
                          disabled={edit_access}
                        />
                      </div> */}
                    </div>
                  </>
                )}
                {index === 1 && formik.values.minorNomineeCheckbox && (
                  <>
                    <h5 className="card-title md-title  mt-4 mb-4 text-center heading-fordesktop">
                      Nominee Guardian Details
                    </h5>
                    <div className="row" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      {/* First Name Guardian */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Guardian First Name
                        </label>
                        <Field
                          name="firstNameGuardian"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"

                        />
                      </div>

                      {/* Last Name Guardian */}
                      <div className="col-md-6 form-group">
                        <label className="required">Guardian Last Name</label>
                        <Field
                          name="lastNameGuardian"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Address line one guardian */}
                      <div className="col-md-6 form-group">
                        <label className="required">Address Line 1</label>
                        <Field
                          name="addressLineOneGuardian"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Address line two guardian */}
                      <div className="col-md-6 form-group">
                        <label>Address Line 2</label>
                        <Field
                          name="addressLineTwoGuardian"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Country Guardian */}
                      <div className="col-md-6 form-group">
                        <label className="required">Country</label>
                        <Field
                          name="countryGuardian"
                          component={CustomSelectComponent}
                          options={countries}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* State guardian */}
                      <div className="col-md-6 form-group">
                        <label className="required">State</label>
                        <Field
                          name="stateGuardian"
                          component={CustomSelectComponent}
                          options={states2}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* City Guardian */}
                      <div className="col-md-6 form-group">
                        <label className="required">City</label>
                        <Field
                          name="cityGuardian"
                          component={CustomSelectComponent}
                          options={cities2}
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>

                      {/* Postal code guardian */}
                      <div className="col-md-6 form-group">
                        <label className="required">Zip / Postal Code</label>
                        <Field
                          name="postalCodeGuardian"
                          component={CustomInputComponent}
                          type="text"
                          disabled={edit_access}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </>
                )}
                {isSaveNomineeDetailsLoading ? (
                  <BtnSpinner />
                ) : index === 0 && formik.values.minorNomineeCheckbox ? (
                  <div className="mt-3 text-center">
                    <button
                      type="button"
                      onClick={() => {setIndex(1); window.scrollTo(0, 0)}}
                      className="theme-btn btn w-25 ml-15"
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div className="mt-3 text-center">
                    {/* {checkDirectNominee && (
                        <button
                          type="submit"
                          className="gray-btn btn idil-btn"
                          onClick={() => {
                            dispatch({ type: SET_PAGE, payload: 4 });
                            navigate(
                              "/indian-citizenship/personalize-your-profile"
                            );
                          }}
                        >
                          I'LL DO IT LATER
                        </button>
                      )} */}
                    {!edit_access &&
                      <button
                        type="button"
                        className="theme-btn btn w-25 ml-15"
                        onClick={() => formik.submitForm()}
                      >
                        Submit
                      </button>
                    }
                  </div>
                )}
              </Form>
            </FormikProvider>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default NomineeInformation