import { useQuery, useMutation } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const InvestorDocs = ({ citizenship_type = "" }) => {
  return useQuery(
    ["investor-info", citizenship_type],
    async () => {
      return await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/user-info`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
      });
    },
    {
      select: (data) => {
        const user_info = data.data.data;
        const docs = user_info?.documents?.filter(
          (doc) => doc.slug === "identity_documents"
        );
        let IDProof;
        let AddressProof;
        let CertificateOfIncorporation;
        let ByLawsCertificate;
        let MoaAndAoa;
        let response;



        switch (citizenship_type) {
          case "indian-citizenship":
            IDProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "identity_proof"
            );
            AddressProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "address_proof"
            );
            response = {
              panNumber: user_info.pan_number ? user_info.pan_number : '',
              fileIdentityProof: IDProof ? IDProof : [],
              fileAddressProof: AddressProof ? AddressProof : [],
              //group_id: docs[0].group_id,
              group_id: '64116d0def7a130d48a0de5c',
            };
            break;

          case "huf":
            IDProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "identity_proof"
            );
            AddressProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "address_proof"
            );
            response = {
              panNumber: user_info.pan_number ? user_info.pan_number : '',
              fileIdentityProof: IDProof ? IDProof : [],
              fileAddressProof: AddressProof ? AddressProof : [],
              //group_id: docs[0].group_id,
              group_id: '64116d0def7a130d48a0de5c',
            };
            break;

          case "indian-corporate":

            CertificateOfIncorporation = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "certificate_of_incorporation"
            );
            MoaAndAoa = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "moa_and_aoa"
            );
            AddressProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "address_proof"
            );
            response = {
              certificateOfIncorporation: CertificateOfIncorporation ? CertificateOfIncorporation : [],
              moaAndAoa: MoaAndAoa ? MoaAndAoa : [],
              fileAddressProof: AddressProof ? AddressProof : [],
              group_id: '64116d0def7a130d48a0de5c',
            };
            break;

          case "foreign-citizenship":
            IDProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "identity_proof"
            );
            AddressProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "address_proof"
            );
            response = {
              fileIdentityProof: IDProof ? IDProof : [],
              fileAddressProof: AddressProof ? AddressProof : [],
              group_id: '64116d0def7a130d48a0de5c',
            };
            break;

          case "foreign-corporate":
            CertificateOfIncorporation = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "certificate_of_incorporation"
            );
            ByLawsCertificate = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "by_laws_certificate"
            );
            AddressProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "address_proof"
            );
            response = {
              certificateOfIncorporation: CertificateOfIncorporation ? CertificateOfIncorporation : [],
              byLawsCertificate: ByLawsCertificate ? ByLawsCertificate : [],
              fileAddressProof: AddressProof ? AddressProof : [],
              group_id: '64116d0def7a130d48a0de5c',
            };
            break;

          default:
            CertificateOfIncorporation = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "certificate_of_incorporation"
            );
            ByLawsCertificate = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "by_laws_certificate"
            );
            MoaAndAoa = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "moa_and_aoa"
            );
            IDProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "identity_proof"
            );
            AddressProof = docs[0]?.documents.filter(
              (doc) => doc.upload_type === "address_proof"
            );
            response = {
              certificateOfIncorporation: CertificateOfIncorporation ? CertificateOfIncorporation : [],
              byLawsCertificate: ByLawsCertificate ? ByLawsCertificate : [],
              moaAndAoa: MoaAndAoa ? MoaAndAoa : [],
              fileIdentityProof: IDProof ? IDProof : [],
              fileAddressProof: AddressProof ? AddressProof : AddressProof,
              group_id: '64116d0def7a130d48a0de5c',
            };
            break;
        }

        // console.log('rrr',response);
        return response;
      },
      refetchOnWindowFocus: true,
    }
  );
};




export const SaveChooseOption = () => {
  return useMutation(
    ({ payload }) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/save-individual-type`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: payload,
      });
    },
    {
      retry: 0,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const SavePersonalInformation = () => {
  return useMutation(
    ({ payload }) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/save-personal-info`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: payload,
      });
    },
    {
      retry: 0,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const SaveAddress = () => {
  return useMutation(
    ({ payload }) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/save-address`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: payload,
      });
    },
    {
      retry: 0,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const SaveBankInformation = () => {
  return useMutation(
    ({ payload }) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/save-bank-info`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: payload,
      });
    },
    {
      retry: 0,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const UploadInvestorDocuments = () => {
  return useMutation(
    ({ payload }) => {
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/documents/upload`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: payload,
      });
    },
    {
      retry: 0,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const SaveQuestions = () => {
  return useMutation(
    ({ payload }) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/save-question-info`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: payload,
      });
    },
    {
      retry: 0,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const DeleteDocuments = () => {
  return useMutation(
    ({ payload: id }) => {
      return axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL_WEB}/investor/documents`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: {
          document_id: id,
        },
      });
    },
    {
      retry: 0,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};
