import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import storage from "redux-persist/lib/storage";

export const LoginApi = () => {
  return useMutation(
    (fields) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/login`,
        headers: {
          // 'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
        data: fields,
      });
    },
    {
      /*refetchOnWindowFocus: false,
        staleTime: Infinity,*/
      retry: 0,
      onSuccess: (data) => {
        var res = data.data;
        if (res.statusCode === 200) {
          storage.removeItem("persist:root");
          Cookies.remove("userDetail");
          //  toast.success(res.message);
          Cookies.set("jwtToken", res.data.token);
          return res.data;
        } else {
          toast.error("Some error occured,Contact to admin");
        }
      },
    }
  );
};

export const SendOtp = () => {
  const navigate = useNavigate();
  return useMutation(
    (fields) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/send-otp`,
        headers: {
          // 'Authorization': `Bearer ${Cookies.get('jwt')}`
        },
        data: fields,
      });
    },
    {
      /*refetchOnWindowFocus: false,
       staleTime: Infinity,*/
      // retry: 0,
      onSuccess: (res) => {
        if (res.data.statusCode === 200) {
          Cookies.set("jwtTokenemailVerify", res.data.data.token);
          Cookies.set("verifyEmail", res.data.data.email);
          navigate("/verify-otp");
        } else {
          toast.error("Some error occured,Contact to admin");
        }
      },
    }
  );
};

export const ReSendOtp = () => {
  return useMutation(
    (fields) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/re-send-otp`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtTokenemailVerify")}`,
        },
        data: fields,
      });
    },
    {
      /*refetchOnWindowFocus: false,
       staleTime: Infinity,*/
      // retry: 0,
      onSuccess: (data) => {
        //navigate("/verify-otp");
        var res = data.data;
        if (res.statusCode === 200) {
          // Cookies.set('jwtTokenemailVerify', res.token);
        } else {
          toast.error("Some error occured,Contact to admin");
        }
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const VerifyOtp = () => {
  const navigate = useNavigate();
  return useMutation(
    (fields) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/verify-email`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtTokenemailVerify")}`,
        },
        data: fields,
      });
    },
    {
      /*refetchOnWindowFocus: false,
       staleTime: Infinity,*/
      // retry: 0,
      onSuccess: (data) => {
        storage.removeItem("persist:root");
        Cookies.remove("userDetail");
        Cookies.remove("jwtTokenemailVerify");
        Cookies.remove("verifyEmail");
        var res = data.data;
        Cookies.set("jwtToken", res.data.token);
        if (res.data.kyc_status_slug == "approved") { 
          navigate("/dashboard", {replace: true});
        } else {
          navigate("/choose-option", {replace: true});
        }
      },
    }
  );
};

export const ForgotPin = () => {
  const navigate = useNavigate();
  return useMutation(
    (fields) => {
      return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/forgot-pin`,
        headers: {
          // 'Authorization': `Bearer ${Cookies.get('jwtTokenemailVerify')}`
        },
        data: fields,
      });
    },
    {
      /*refetchOnWindowFocus: false,
       staleTime: Infinity,*/
      // retry: 0,
      onSuccess: (data) => {
        toast.success("Pin sent successfully on email");
        navigate("/login");
      },
    }
  );
};

/* mobile verification otp code */

export const MobileSendOtp = () => {
  const navigate = useNavigate();
  return useMutation(
    (fields) => {
      console.log("gg",fields.mobile_number);
      Cookies.set("jwtMobileVerify", fields.mobile_number);
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/send-mobile-otp`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: fields,
      });
    },
    {
      /*refetchOnWindowFocus: false,
       staleTime: Infinity,*/
      // retry: 0,
      onSuccess: (res) => {
        if (res.data.statusCode === 200) {
          navigate("/verify-mobile-otp");
        } else {
          toast.error("Some error occured,Contact to admin");
        }
      },
    }
  );
};

export const MobileReSendOtp = () => {
  return useMutation(
    (fields) => {
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/send-mobile-otp`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: {mobile_number:Cookies.get("jwtMobileVerify")},
      });
    },
    {
      /*refetchOnWindowFocus: false,
       staleTime: Infinity,*/
      // retry: 0,
      onSuccess: (data) => {
        //navigate("/verify-otp");
        var res = data.data;
        if (res.statusCode === 200) {
          // Cookies.set('jwtTokenemailVerify', res.token);
        } else {
          toast.error("Some error occured,Contact to admin");
        }
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const MobileVerifyOtp = () => {
  const navigate = useNavigate();
  return useMutation(
    (fields) => {
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL}/investor/mobile-verification`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
        data: fields,
      });
    },
    {
      /*refetchOnWindowFocus: false,
       staleTime: Infinity,*/
      // retry: 0,
      onSuccess: (data) => {
        Cookies.remove("jwtMobileVerify");
        navigate("/choose-option", {replace: true});
        var checkUSerType = Cookies.get("checkUSerType");
        Cookies.set("checkNominee", "Yes");
        if (checkUSerType === "indian_citizen") {
          navigate("/indian-citizenship/nominee-details");
        } else if (checkUSerType === "foreign_citizen") {
          navigate("/foreign-citizenship/nominee-details");
        } else {
          navigate("/dashboard");
        }
      },
    }
  );
};
