import { useQuery } from "react-query";
import { Logout } from "../views/auth/Logout";
import Cookies from "js-cookie";
import axios from "axios";


export const GetDealLists = ({ listId, page }) => {
  return useQuery(
    ["get-deal-list", listId, page],
    async () => {
      var URL =
        listId === "all-deal"
          ? `${process.env.REACT_APP_API_BASE_URL}/deal/?page=${page}`
          : `${process.env.REACT_APP_API_BASE_URL}/deal/${listId}/?page=${page}`;
      return await axios({
        method: "get",
        // url: `${process.env.REACT_APP_API_BASE_URL}/deal/${listId}`,
        url: URL,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
      });
    },
    {
      select: (data) => {
        if (data.status !== 200) Logout();
        if (data.data.statusCode !== 200) Logout();
        return data.data;
      },
      refetchOnWindowFocus: true,
      // staleTime: Infinity,
    }
  );
};

export const GetDealDetails = ({ slug }) => {
  return useQuery(
    ["get-deal-details", slug],
    async () => {
      return await axios({
        method: "get",
        // url: `${process.env.REACT_APP_API_BASE_URL}/deal/${listId}`,
        url: `${process.env.REACT_APP_API_BASE_URL}/deal/detail/web/${slug}?page=1`,
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtToken")}`,
        },
      });
    },
    {
      select: (data) => {
        if (data.status !== 200) Logout();
        if (data.data.statusCode !== 200) Logout();
        return data.data;
      },
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
    }
  );
};
