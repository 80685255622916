import React, { useState, useEffect } from "react";
import DashboardLayout from '../../../main/deals/DashboardLayout'
import { Field, Form, Formik, FastField } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back, CrossIcon, PanCardImg } from "../../../../assets/img";
import {
  CustomInputComponent,
  FileInputComponent,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UPDATE_DOCUMENTS, SET_PAGE } from "../../../../store/actions";
import { UploadInvestorDocuments, InvestorDocs, GetUserDetailsUpdated } from "../../../../api";
import backArrow from '../../../../assets/img/back.svg'

const UploadDocument = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [idValidation, setidValidation] = useState(false);
  const checkUSerType = useSelector((state) => state.userDetails);
  const [is_editable, setIsEditable] = useState(null);
  const [edit_access, setEditAccess] = useState(true);
  const { data: userData, isLoading: isDocsDataLoading } = GetUserDetailsUpdated();

  const personalInformation = useSelector(
    (state) => state.indianCitizenship.personalInformation
  );
  // const documents = useSelector((state) => state.indianCitizenship.documents);
  const { mutateAsync: uploadDocs, isLoading: isUploadDocsLoading } =
    UploadInvestorDocuments();
  const { data: docs, isLoading: isDocsLoading } = InvestorDocs({
    citizenship_type: "indian-citizenship",
  });

  const [ischeckValidate, setcheckValidate] = React.useState(true);
  const [ischeckValidatefileAddressProof, setcheckValidatefileAddressProof] =
    React.useState(true);

  useEffect(() => {
    console.log("userData", userData)
    setIsEditable(userData?.kyc_editable)
  }, [isDocsDataLoading])

  const toggleEdit = () => {
    // 👇️ passed function to setState
    setEditAccess(edit_access => !edit_access);
  };

  useEffect(() => {
    if (searchParams.has("editable")) {
      setEditAccess(searchParams.get("editable") == 'true')
      console.log("editable", searchParams.get("editable"))
    }
  }, [searchParams])

  useEffect(() => {
    if (
      !isDocsLoading &&
      docs.fileIdentityProof &&
      docs.fileIdentityProof.length > 0
    ) {
      setcheckValidate(false);
    } else {
      setcheckValidate(true);
    }

    if (
      !isDocsLoading &&
      docs.fileAddressProof &&
      docs.fileAddressProof.length > 0
    ) {
      setcheckValidatefileAddressProof(false);
    } else {
      setcheckValidatefileAddressProof(true);
    }
  }, [isDocsLoading, docs]);

  const UploadDocumentsValidationSchema = Yup.object().shape({
    panNumber: Yup.string().required("Pan number is required"),
    fileIdentityProof: ischeckValidate
      ? Yup.array()
        .min(1, "Identity proof required")
        .of(Yup.string().trim().required())
      : Yup.array(),
    fileAddressProof: ischeckValidatefileAddressProof
      ? Yup.array()
        .min(1, "Identity proof required")
        .of(Yup.string().trim().required())
      : Yup.array(),
  });

  if (!isDocsLoading) {
    console.log("pppAPI", docs);
  }

  let documentHeading = 'Indian Citizen'

  if (userData?.data?.user_individual_type && userData?.data?.user_individual_type == 'indian_citizen') {
    if (userData?.data?.country_citizenship_id != '101') {
      documentHeading = 'NRI'
    }
  } else if (userData?.data?.user_individual_type == 'indian_corporate') {
    documentHeading = 'Indian Corporate/Firm'
  } else if (userData?.data?.user_individual_type == 'huf') {
    documentHeading = 'Indian HUF'
  } else if (userData?.data?.user_individual_type == 'foreign_citizen') {
    documentHeading = 'Foreign Citizen'
  } else if (userData?.data?.user_individual_type == 'foreign_corporate') {
    documentHeading = 'Foreign Corporate'
  }

  return (
    <>
      <DashboardLayout>
        <div className='header-mobile d-md-none border-b'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <Link className="back-btn blue-text d-block" to={"/my-profile/bank-details?editable=" + edit_access}>
                <img src={backArrow} alt="arrow" /> KYC Details
              </Link>
            </div>
            {is_editable && <div>
              <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
            </div>}
          </div>
        </div>

        <div className='d-flex align-items-center justify-content-between cr-heading header-desktop'>
          <div className="md-title text-start fw-700">
            <button className='back-btn' onClick={() => navigate("/my-profile/bank-details?editable=" + edit_access)} type='button'><img src={backArrow} alt='arrow' /></button>    KYC Details
          </div>

          {is_editable && <div>
            <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
          </div>}

        </div>
        <div className="pt-4 pb-4 ps-5 pe-5">
          <div className="pt-0 pb-0 kyc-form-block pyp-block">
            <div className="card-body">
              {isDocsLoading ? (
                <div className="text-center">
                  <span className="spinner-border spinner-border-lg text-primary"></span>
                </div>
              ) : (
                <>

                  <h5 className="card-title md-title mb-4 text-center fw-700">
                    Upload Document ({documentHeading})
                  </h5>
                  <Formik
                    initialValues={
                      docs
                        ? docs
                        : {
                          panNumber: "",
                          fileIdentityProof: [],
                          fileAddressProof: [],
                        }
                    }
                    enableReinitialize={true}
                    validationSchema={UploadDocumentsValidationSchema}
                    onSubmit={(values) => {
                      // console.log("submit", values);

                      //return false;

                      const formData = new FormData();

                      if (values.fileIdentityProof) {
                        for (
                          let i = 0;
                          i < values.fileIdentityProof.length;
                          i++
                        ) {
                          let file = values.fileIdentityProof[i];
                          if (file?.size) {
                            formData.append("name", "Pan Card");
                            formData.append("documents", file);
                            // formData.append("group_id", values.group_id);
                            formData.append("upload_type", "identity_proof");
                          } else {
                            formData.append("name", file.name);
                            formData.append(
                              `documents[${file.document_id}]`,
                              ""
                            );
                            // formData.append("group_id", file.group_id);
                            formData.append("upload_type", file.upload_type);
                          }
                        }
                      }

                      if (values.fileAddressProof) {
                        for (
                          let i = 0;
                          i < values.fileAddressProof.length;
                          i++
                        ) {
                          let file = values.fileAddressProof[i];
                          if (file?.size) {
                            formData.append("name", "Address Proof");
                            formData.append("documents", file);
                            // formData.append("group_id", values.group_id);
                            formData.append("upload_type", "address_proof");
                          } else {
                            formData.append("name", file.name);
                            formData.append(
                              `documents[${file.document_id}]`,
                              ""
                            );
                            // formData.append("group_id", file.group_id);
                            formData.append("upload_type", file.upload_type);
                          }
                        }
                      }

                      formData.append("pan_number", values.panNumber);

                      formData.append("page", "4");
                      setidValidation(false);
                      uploadDocs(
                        { payload: formData },
                        {
                          onSuccess: () => {
                            dispatch({ type: SET_PAGE, payload: 5 });
                            // navigate("/indian-citizenship/more-about-you");
                            navigate("/my-profile/declaration?editable=" + edit_access);
                          },
                        }
                      );
                    }}
                  >
                    {({
                      errors,
                      touched,
                      setFieldValue,
                      initialValues,
                      values,
                    }) => (
                      <Form className="custom-form">
                        {console.log("valuesAPI", values)}
                        <div className="row" style={{ opacity: edit_access ? 0.5 : 1 }}>
                          {/* PAN number */}
                          <div className="col-md-12 form-group">
                            <label>
                              {" "}
                              {personalInformation.individual_type == "huf"
                                ? "HUF "
                                : ""}{" "}
                              PAN Number <span className="required"></span>{" "}
                            </label>
                            <Field
                              name="panNumber"
                              component={CustomInputComponent}
                              type="text"
                              disabled={edit_access}
                              className="form-control"

                            />
                          </div>

                          <div className="col-md-6 form-group">
                            <label>
                              Proof of Identity
                              <span className="required"></span>{" "}
                              <span className="xs-title">
                                (PAN Card, Passport, Driving License, Voter ID)
                              </span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Identity Proof
                              </button>
                              <Field
                                name="fileIdentityProof"
                                component={FileInputComponent}
                                label="Proof of Identity"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(fileIdentityProofDocs) => {
                                  if (
                                    fileIdentityProofDocs &&
                                    fileIdentityProofDocs.length > 0
                                  ) {
                                    setcheckValidate(false);
                                  } else {
                                    setcheckValidate(true);
                                  }
                                }}
                                disabled={edit_access}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>
                              Address Proof<span className="required"></span>{" "}
                              <span className="xs-title">
                                (Passport, Addhar Card, Driving License)
                              </span>
                            </label>
                            <div className="custom-upload">
                              <button className="custom-upload-btn">
                                Upload Address Proof
                              </button>
                              <Field
                                name="fileAddressProof"
                                component={FileInputComponent}
                                label="Address proof"
                                desc="*Upload self attested document"
                                checkFileExixtsORNot={(fileAddressProofDocs) => {
                                  if (
                                    fileAddressProofDocs &&
                                    fileAddressProofDocs.length > 0
                                  ) {
                                    setcheckValidatefileAddressProof(false);
                                  } else {
                                    setcheckValidatefileAddressProof(true);
                                  }
                                }}

                              />
                            </div>
                          </div>
                        </div>

                        {edit_access == false ? isUploadDocsLoading ? (
                          <BtnSpinner />
                        ) : (
                          <div className="mt-3 text-center">
                            {/* <button
                              type="submit"
                              className="gray-btn btn idil-btn"
                              onClick={() => {
                                dispatch({ type: SET_PAGE, payload: 4 });
                                navigate(
                                  "/indian-citizenship/personalize-your-profile"
                                );
                              }}
                            >
                              I'LL DO IT LATER
                            </button> */}
                            <button
                              type="submit"
                              className="theme-btn btn w-25 ml-15"
                            >
                              Next
                            </button>
                          </div>
                        ) : <div className="mt-3 text-center">
                          <button type="button" onClick={() => navigate("/my-profile/declaration?editable=" + edit_access)} className="theme-btn btn w-25">
                            Next
                          </button>
                        </div>}

                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default UploadDocument