import React from "react";
import { NavLink } from "react-router-dom";
import crossIcon from "../../../assets/img/close-icon.svg";

const DealLeaftMenu = ({ closeHmButton, showMyBackrr }) => {
  console.log("showMyBackrr", showMyBackrr)
  return (
    <>
      <div className="common-left" onClick={closeHmButton}>
        <div className="custom-card position-relative">
          <ul className="left-menu">
            <li>
              <NavLink to="/dashboard">
                <span className="lmenu-icon svg-icon"><i class="bi bi-grid"></i></span> Home
              </NavLink>
            </li>
            <li>
              <NavLink to={`/deal-list?deal-id=all-deal`}>
                <span className="lmenu-icon deals-icon"></span> Deals
              </NavLink>
            </li>
            <li>
              <NavLink to="/my-profile">
                <span className="lmenu-icon svg-icon profile-svg">
                  <i className="bi bi-person"></i>
                </span> My Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/portfolio">
                <span className="lmenu-icon svg-icon portfolio-svg">
                  <i className="bi bi-pie-chart"></i>
                </span> Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink to="/favorites">
                <span className="lmenu-icon svg-icon">
                  <i className="bi bi-star"></i>
                </span> Favorites
              </NavLink>
            </li>
            <li>
              <NavLink to="/statement-of-account">
                <span className="lmenu-icon svg-icon">
                  <i className="bi bi-file-earmark-bar-graph"></i>
                </span> Statement Of Account
              </NavLink>
            </li>
            <li>
              <NavLink to="https://www.realtimeaf.com/" target="_blank">
                <span className="lmenu-icon svg-icon">
                  <i className="bi bi-file-earmark-person"></i>
                </span> About Us
              </NavLink>
            </li>
            {/* <li>
                <NavLink to="/rate-and-feedback">
                  <span className="lmenu-icon svg-icon">
                    <i className="bi bi-chat-right-text"></i>
                  </span> Rate & Feedback
                </NavLink>
              </li> */}
            <li>
              <NavLink to="/contact-us">
                <span className="lmenu-icon contact-icon"></span> Contact Us
              </NavLink>
            </li>
            {showMyBackrr == 'Yes' && <li>
              <NavLink to="/my-backrrs">
                <span className="lmenu-icon svg-icon">
                  <i className="bi bi-person"></i>
                </span>
                My Backrrs
              </NavLink>
            </li>}
            {/* <li>
                <NavLink to="/faqs">
                  <span className="lmenu-icon svg-icon">
                    <i className="bi bi-question-circle"></i>
                  </span>
                  FAQ's
                </NavLink>
              </li> */}
          </ul>
          {/* <div className="logout-menu">
              <NavLink to="/logout">
                <span className="lmenu-icon logout-icon"></span> Logout
              </NavLink>
            </div> */}
        </div>
      </div>
    </>
  );
};

export default DealLeaftMenu;
