import { types } from './types';

export function getMyBackrrs(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRRS,
    payload: data,
  };
}

export function getMyBackrrsSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRRS_SUCCESS,
    payload: data,
  };
}

export function getMyBackrrsFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRRS_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetMyBackrrs() {
  return {
    type: types.CLEAR_REQUEST_GET_MY_BACKRRS,
    payload: {},
  };
}
