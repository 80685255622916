import { types } from './types';

export function getMyBackrrsPortfolio(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO,
    payload: data,
  };
}

export function getMyBackrrsPortfolioSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_SUCCESS,
    payload: data,
  };
}

export function getMyBackrrsPortfolioFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetMyBackrrsPortfolio() {
  return {
    type: types.CLEAR_REQUEST_GET_MY_BACKRR_PORTFOLIO,
    payload: {},
  };
}
