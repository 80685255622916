import { types } from '../actions/types';

const initialState = {
  loading: false,
  data: {},
  error: {},
};

const getIndustriesTypesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SEND_REQUEST_GET_INDUSTRIES_TYPES:
      return {
        ...state,
        loading: true,
      };
    case types.SEND_REQUEST_GET_INDUSTRIES_TYPES_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case types.SEND_REQUEST_GET_INDUSTRIES_TYPES_FAILURE:
      return {
        ...state,
        data: {},
        error: payload,
        loading: false,
      };
    case types.CLEAR_REQUEST_GET_INDUSTRIES_TYPES:
      return {
        ...state,
        data: {},
        loading: false,
      };
    default:
      return state;
  }
};

export default getIndustriesTypesReducer;
