import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import {
  CustomInputComponent,
  CustomRadioComponent,
  SingleFileInputComponent,
} from "../../../../components/formik/FieldComponents";
import { GetUserDetailsUpdated, FetchAcountTypes, SaveBankInformation } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_BANK_DETAILS,
  UPDATE_BANK_DETAILS_FC,
  UPDATE_BANK_DETAILS_FCorp,
  UPDATE_BANK_DETAILS_HUF,
  UPDATE_BANK_DETAILS_IC
} from "../../../../store/actions";
import DashboardLayout from "../../deals/DashboardLayout";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { getCMRRequiredKey, isEmptyObject, isRequired, notEmpty } from "../../../../utils/utils";
import { getConstants } from "../../../../utils/constants";

function DematAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [edit_access, setEditAccess] = useState(true);
  const [bankData, setBankData] = useState(null)
  const [showDemat, setShowDemat] = useState(false)
  const [requiredFields, setRequiredFields] = useState([])

  const checkUSerType = useSelector((state) => state.userDetails);
  const { data: acountTypesData } = FetchAcountTypes({ accountType: checkUSerType.userType });

  const bankDetails = useSelector(
    (state) => state.indianCitizenship.bankDetails
  );
  const bankDetailsIC = useSelector(
    (state) => state.indianCorporate.bankDetails
  );
  const bankDetailsHUF = useSelector(
    (state) => state.indianhuf.bankDetails
  );
  const bankDetailsFC = useSelector(
    (state) => state.foreignCitizenship.bankDetails
  );
  const bankDetailsFCorp = useSelector(
    (state) => state.foreignCorporate.bankDetails
  );

  const { mutateAsync: saveBankInformation, isLoading: isSaveBankInformationLoading } = SaveBankInformation();
  const { data: userData } = GetUserDetailsUpdated();

  useEffect(() => {
    if (userData != null) {
      setRequiredFields(userData.validation_fields)
      if (userData.data != null) {
        let data = userData.data;
        console.log("UserdATA", data)
        const docs = data?.documents?.filter((doc) => doc.slug === "onboarding_documents");
        console.log("docs", docs)
        let cmr = docs?.length > 0 ? docs[0]?.documents?.filter(item => { return item.upload_type == "client_master_report" }) : []
        console.log("cmr", cmr)

        let firstname = data.first_name;
        let middlename = data.middle_name;
        let lastname = data.last_name;
        let fullname = null;
        if (middlename == undefined || middlename?.trim()?.length == 0) {
          fullname = firstname + ' ' + lastname
        } else {
          fullname = firstname + ' ' + middlename + ' ' + lastname;
        }
        console.log("fullname", fullname)
        if (notEmpty(data?.bankAccounts[0]?.demat_account)) {
          if (isEmptyObject(data?.bankAccounts[0]?.demat_account)) {
            if (cmr?.length > 0) {
              setEditAccess(true)
            } else {
              setEditAccess(false)
            }
          } else {
            setEditAccess(true)
          }
        } else {
          setEditAccess(false)
        }
        if (data.bankAccounts.length > 0) {
          let bank = data.bankAccounts[0];
          let bankName = null
          let ifscCode = null
          let swiftCode = null
          let accountType = null
          if (bank.account_type_id != null) {
            if (bank?.account_type_id == '63aac72be36fdf0309e81b61' || bank?.account_type_id == '63aac748e36fdf0309e81b65' || bank?.account_type_id == '63aac765e36fdf0309e81b6c' || bank?.account_type_id == '63aac76ce36fdf0309e81b6f' || bank?.account_type_id == '63aac75ae36fdf0309e81b69') {
              bankName = bank?.bank_id?._id
              ifscCode = bank?.ifsc_code
            } else {
              bankName = bank?.bank_name
              swiftCode = bank?.swift_code
            }
          } else {
            if (data?.user_individual_type == 'indian_citizen' || data?.user_individual_type == 'indian_corporate' || data?.user_individual_type == 'huf') {
              bankName = bank?.bank_id?._id
              ifscCode = bank?.ifsc_code
            } else {
              bankName = bank?.bank_name
              swiftCode = bank?.swift_code
            }
          }
          if (acountTypesData?.filter(item => item.value == bank?.account_type_id)?.length == 0) {
            accountType = null
          } else {
            accountType = bank?.account_type_id != null ? bank.account_type_id : null
          }

          let jointAccounts = bank.accounts;
          let firstJointHolder = null
          if (jointAccounts.length > 0) {
            firstJointHolder = jointAccounts[0];
          }

          let dematAccount = accountType == '63aac72be36fdf0309e81b61' || accountType == '63aac748e36fdf0309e81b65' || accountType == '63aac75ae36fdf0309e81b69' || accountType == '63aac765e36fdf0309e81b6c' || accountType == '63aac76ce36fdf0309e81b6f'
          setShowDemat(dematAccount)

          if (checkUSerType.userType == "indian_citizen") {
            dispatch({
              type: UPDATE_BANK_DETAILS,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else if (checkUSerType.userType == "indian_corporate") {
            dispatch({
              type: UPDATE_BANK_DETAILS_IC,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else if (checkUSerType.userType == "huf") {
            dispatch({
              type: UPDATE_BANK_DETAILS_HUF,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else if (checkUSerType.userType == "foreign_citizen") {
            dispatch({
              type: UPDATE_BANK_DETAILS_FC,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          } else {
            dispatch({
              type: UPDATE_BANK_DETAILS_FCorp,
              payload: {
                bankName: bankName,
                accountHolderName: bank?.account_holder_name,
                accountNumber: bank?.bank_account_number,
                ifscCode: ifscCode,
                bankBranch: bank?.branch_name,
                accountType: accountType,
                jhDetailsCheckbox: jointAccounts.length > 0,
                bank_name: bankName,
                swift_code: swiftCode,
                tin_number: data.tin_number,
                jointHolderName: firstJointHolder?.joint_holder_name,
                jointHolderMobileNumber: firstJointHolder?.joint_holder_mobile_number,
                jointHolderEmail: firstJointHolder?.joint_holder_email,
                jointHolderPAN: firstJointHolder?.joint_holder_pan_number,
                // id_name: bank?.demat_account?.id_name,
                dp_id: bank?.demat_account?.dp_id,
                customer_id: bank?.demat_account?.customer_id,
                client_id: bank?.demat_account?.client_id,
                account_details_type: bank?.demat_account?.account_details_type,
                demat_name: fullname,
                fileCMR: cmr
              },
            });
          }
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (checkUSerType.userType == "indian_citizen") {
      setBankData(bankDetails)
    } else if (checkUSerType.userType == "indian_corporate") {
      setBankData(bankDetailsIC)
    } else if (checkUSerType.userType == "huf") {
      setBankData(bankDetailsHUF)
    } else if (checkUSerType.userType == "foreign_citizen") {
      setBankData(bankDetailsFC)
    } else {
      setBankData(bankDetailsFCorp)
    }
  }, [bankDetails, bankDetailsIC, bankDetailsHUF, bankDetailsFC, bankDetailsFCorp])

  const toggleEdit = () => {
    setEditAccess(edit_access => !edit_access);
  };

  const DemateDetailsValidationSchema = {
    // id_name: Yup.string().required("Name as per ID proof is required"),
    account_details_type: isRequired(requiredFields, "demat_account[account_details_type]") ? Yup.string().required("Account details is required") : Yup.string(),
    dp_id: isRequired(requiredFields, "demat_account[dp_id]") ? Yup.string().required("DP ID is required") : Yup.string(),
    customer_id: isRequired(requiredFields, "demat_account[customer_id]") ? Yup.string().when("account_details_type", {
      is: (account_details_type) => { return account_details_type == "NSDL" },
      then: () => Yup.string().required("Customer ID is required")
    }) : Yup.string(),
    client_id: isRequired(requiredFields, "demat_account[client_id]") ? Yup.string().when("account_details_type", {
      is: (account_details_type) => { return account_details_type == "CDSL" },
      then: () => Yup.string().required("Customer ID is required")
    }) : Yup.string(),
    demat_name: isRequired(requiredFields, "demat_account[demat_name]") ? Yup.string().required("Name as per Demat account is required") : Yup.string(),
    fileCMR: isRequired(requiredFields, getCMRRequiredKey(bankData?.accountType)) ? Yup.array().min(1, 'Client Master Report is required').required('Client Master Report is required') : Yup.array()
  }

  const getValidationSchema = () => {
    let validations = {}
    if (showDemat) {
      validations = DemateDetailsValidationSchema
    }
    return Yup.object().shape(validations)
  }


  return (
    <DashboardLayout>
      <div className='d-flex align-items-center justify-content-between cr-heading'>
        <div className="md-title text-start fw-700">
          Demat Account Details
        </div>

        <div>
          <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
        </div>

      </div>

      <div className="pt-4 pb-4 ps-5 pe-5">
        <div className="pt-0 pb-5 kyc-form-block pyp-block">
          <Formik
            initialValues={bankData}
            enableReinitialize={true}
            validationSchema={getValidationSchema()}
            onSubmit={(values) => {

              let bankPayload = new FormData();

              bankPayload.append("account_holder_name", values.accountHolderName)
              bankPayload.append("account_type_id", values.accountType)
              bankPayload.append("bank_account_number", values.accountNumber)
              bankPayload.append("branch_name", values.bankBranch)
              bankPayload.append("ifsc_code", values.ifscCode)
              bankPayload.append("bank_name", values.bank_name)
              bankPayload.append("swift_code", values.swift_code)
              bankPayload.append("tin_number", values.tin_number)
              bankPayload.append("page", "3")
              bankPayload.append("re_submit", false)
              bankPayload.append("user_type", "investor")

              if (Boolean(values.jhDetailsCheckbox)) {
                bankPayload.append("accounts[0][joint_holder_email]", values.jointHolderEmail)
                bankPayload.append("accounts[0][joint_holder_mobile_number]", values.jointHolderMobileNumber)
                bankPayload.append("accounts[0][joint_holder_name]", values.jointHolderName)
                bankPayload.append("accounts[0][joint_holder_pan_number]", values.jointHolderPAN)
                bankPayload.append("accounts[0][phone_code]", null)
              }

              // bankPayload.append("demat_account[id_name]", values.id_name != null && values.id_name != undefined && values.id_name != "undefined" ? values.id_name : "")
              bankPayload.append("demat_account[dp_id]", values.dp_id != null && values.dp_id != undefined && values.dp_id != "undefined" ? values.dp_id : "")
              bankPayload.append("demat_account[customer_id]", values.customer_id != null && values.customer_id != undefined && values.customer_id != "undefined" ? values.customer_id : "")
              bankPayload.append("demat_account[client_id]", values.client_id != null && values.client_id != undefined && values.client_id != "undefined" ? values.client_id : "")
              bankPayload.append("demat_account[account_details_type]", values.account_details_type)
              bankPayload.append("demat_account[demat_name]", values.demat_name != null && values.demat_name != undefined && values.demat_name != "undefined" ? values.demat_name : "")

              if (values.fileCMR?.length > 0) {
                let file = values.fileCMR[0]
                if (file?.size) {
                  bankPayload.append("name[]", "Client Master Report")
                  bankPayload.append("documents", file);
                  bankPayload.append("upload_type[]", "client_master_report");
                }
              }
              saveBankInformation(
                {
                  payload: bankPayload,
                },
                {
                  onSuccess: (response) => {
                    if (checkUSerType.userType == "indian_citizen") {
                      dispatch({
                        type: UPDATE_BANK_DETAILS,
                        payload: {
                          bankName: values.bankName,
                          accountHolderName: values.accountHolderName,
                          accountNumber: values.accountNumber,
                          ifscCode: values.ifscCode,
                          bankBranch: values.bankBranch,
                          accountType: values.accountType,
                          jhDetailsCheckbox: values.jhDetailsCheckbox,
                          bank_name: values.bank_name,
                          swift_code: values.swift_code,
                          tin_number: values.tin_number,
                          jointHolderName:
                            (values.jhDetailsCheckbox && values.jointHolderName) || "",
                          jointHolderMobileNumber:
                            (values.jhDetailsCheckbox &&
                              values.jointHolderMobileNumber) ||
                            "",
                          jointHolderEmail:
                            (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                          jointHolderPAN:
                            (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                          // id_name: values.id_name,
                          dp_id: values.dp_id,
                          customer_id: values.customer_id,
                          client_id: values.client_id,
                          account_details_type: values.account_details_type,
                          demat_name: values?.demat_name,
                          fileCMR: values.fileCMR
                        },
                      });
                    } else if (checkUSerType.userType == "indian_corporate") {
                      dispatch({
                        type: UPDATE_BANK_DETAILS_IC,
                        payload: {
                          bankName: values.bankName,
                          accountHolderName: values.accountHolderName,
                          accountNumber: values.accountNumber,
                          ifscCode: values.ifscCode,
                          bankBranch: values.bankBranch,
                          accountType: values.accountType,
                          jhDetailsCheckbox: values.jhDetailsCheckbox,
                          bank_name: values.bank_name,
                          swift_code: values.swift_code,
                          tin_number: values.tin_number,
                          jointHolderName:
                            (values.jhDetailsCheckbox && values.jointHolderName) || "",
                          jointHolderMobileNumber:
                            (values.jhDetailsCheckbox &&
                              values.jointHolderMobileNumber) ||
                            "",
                          jointHolderEmail:
                            (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                          jointHolderPAN:
                            (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                          // id_name: values.id_name,
                          dp_id: values.dp_id,
                          customer_id: values.customer_id,
                          client_id: values.client_id,
                          account_details_type: values.account_details_type,
                          demat_name: values?.demat_name,
                          fileCMR: values.fileCMR
                        },
                      });
                    } else if (checkUSerType.userType == "huf") {
                      dispatch({
                        type: UPDATE_BANK_DETAILS_HUF,
                        payload: {
                          bankName: values.bankName,
                          accountHolderName: values.accountHolderName,
                          accountNumber: values.accountNumber,
                          ifscCode: values.ifscCode,
                          bankBranch: values.bankBranch,
                          accountType: values.accountType,
                          jhDetailsCheckbox: values.jhDetailsCheckbox,
                          bank_name: values.bank_name,
                          swift_code: values.swift_code,
                          tin_number: values.tin_number,
                          jointHolderName:
                            (values.jhDetailsCheckbox && values.jointHolderName) || "",
                          jointHolderMobileNumber:
                            (values.jhDetailsCheckbox &&
                              values.jointHolderMobileNumber) ||
                            "",
                          jointHolderEmail:
                            (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                          jointHolderPAN:
                            (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                          // id_name: values.id_name,
                          dp_id: values.dp_id,
                          customer_id: values.customer_id,
                          client_id: values.client_id,
                          account_details_type: values.account_details_type,
                          demat_name: values?.demat_name,
                          fileCMR: values.fileCMR
                        },
                      });
                    } else if (checkUSerType.userType == "foreign_citizen") {
                      dispatch({
                        type: UPDATE_BANK_DETAILS_FC,
                        payload: {
                          bankName: values.bankName,
                          accountHolderName: values.accountHolderName,
                          accountNumber: values.accountNumber,
                          ifscCode: values.ifscCode,
                          bankBranch: values.bankBranch,
                          accountType: values.accountType,
                          jhDetailsCheckbox: values.jhDetailsCheckbox,
                          bank_name: values.bank_name,
                          swift_code: values.swift_code,
                          tin_number: values.tin_number,
                          jointHolderName:
                            (values.jhDetailsCheckbox && values.jointHolderName) || "",
                          jointHolderMobileNumber:
                            (values.jhDetailsCheckbox &&
                              values.jointHolderMobileNumber) ||
                            "",
                          jointHolderEmail:
                            (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                          jointHolderPAN:
                            (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                          // id_name: values.id_name,
                          dp_id: values.dp_id,
                          customer_id: values.customer_id,
                          client_id: values.client_id,
                          account_details_type: values.account_details_type,
                          demat_name: values?.demat_name,
                          fileCMR: values.fileCMR
                        },
                      });
                    } else {
                      dispatch({
                        type: UPDATE_BANK_DETAILS_FCorp,
                        payload: {
                          bankName: values.bankName,
                          accountHolderName: values.accountHolderName,
                          accountNumber: values.accountNumber,
                          ifscCode: values.ifscCode,
                          bankBranch: values.bankBranch,
                          accountType: values.accountType,
                          jhDetailsCheckbox: values.jhDetailsCheckbox,
                          bank_name: values.bank_name,
                          swift_code: values.swift_code,
                          tin_number: values.tin_number,
                          jointHolderName:
                            (values.jhDetailsCheckbox && values.jointHolderName) || "",
                          jointHolderMobileNumber:
                            (values.jhDetailsCheckbox &&
                              values.jointHolderMobileNumber) ||
                            "",
                          jointHolderEmail:
                            (values.jhDetailsCheckbox && values.jointHolderEmail) || "",
                          jointHolderPAN:
                            (values.jhDetailsCheckbox && values.jointHolderPAN) || "",
                          // id_name: values.id_name,
                          dp_id: values.dp_id,
                          customer_id: values.customer_id,
                          client_id: values.client_id,
                          account_details_type: values.account_details_type,
                          demat_name: values?.demat_name,
                          fileCMR: values.fileCMR
                        },
                      });
                    }

                    toast.success(response?.data?.message)

                    navigate("/my-profile");
                  },
                }
              );
            }}
          >
            {({ errors, touched, values }) => (
              <Form className="custom-form">
                <div className="row" style={{ opacity: edit_access ? 0.5 : 1 }}>

                  {/* <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                    <label className={showDemat && "required"}>Name (As per ID proof)</label>
                    <Field
                      name="id_name"
                      component={CustomInputComponent}
                      type="text"
                      disabled={edit_access}
                      className="form-control"
                    />
                  </div> */}

                  <div className="col-md-6 form-group" style={{ opacity: 0.5 }}>
                    <label className={showDemat && isRequired(requiredFields, "demat_account[demat_name]") && "required"}>
                      Name
                      {" "}
                      <span className='info-btn text-lgray font-16' data-tooltip-id="my-tooltip-1" data-tooltip-content="Contact us if different name in Demat Account"><i class="bi bi-info-circle"></i></span>
                      <Tooltip id="my-tooltip-1" style={{ maxWidth: '40vh' }} />
                    </label>
                    <Field
                      name="demat_name"
                      component={CustomInputComponent}
                      type="text"
                      disabled={true}
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-12 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                    <label className={showDemat && isRequired(requiredFields, "demat_account[account_details_type]") && "required"}>
                      Demat Account Type
                    </label>
                    <Field
                      name="account_details_type"
                      component={CustomRadioComponent}
                      options={[{ label: "NSDL", value: "NSDL" }, { label: "CDSL", value: "CDSL" }]}
                      disabled={edit_access}
                      className="form-group"
                    />
                  </div>
                  <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                    <label className={showDemat && isRequired(requiredFields, "demat_account[dp_id]") && "required"}>DP ID</label>
                    <Field
                      name="dp_id"
                      component={CustomInputComponent}
                      type="text"
                      disabled={edit_access}
                      className="form-control"
                    />
                  </div>
                  {
                    values?.account_details_type == "NSDL" && <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className={showDemat && isRequired(requiredFields, "demat_account[customer_id]") && "required"}>Customer ID</label>
                      <Field
                        name="customer_id"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>
                  }
                  {
                    values?.account_details_type == "CDSL" && <div className="col-md-6 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                      <label className={showDemat && isRequired(requiredFields, "demat_account[client_id]") && "required"}>Customer ID</label>
                      <Field
                        name="client_id"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>
                  }
                  <div className="col-md-12 form-group" style={{ opacity: edit_access ? 0.5 : 1 }}>
                    <label className={showDemat && isRequired(requiredFields, getCMRRequiredKey(bankData?.accountType)) && "required"}>
                      Client Master Report
                      {" "}
                      <span className='info-btn text-lgray font-16' data-tooltip-id="my-tooltip" data-tooltip-content="A digitally signed PDF certificate, known as a Client Master Report (CMR), is provided by a broker to a client including information on their demat account, including demat ID, date of birth, bank details, nomination details, and more."><i class="bi bi-info-circle"></i></span>
                      <Tooltip id="my-tooltip" style={{ maxWidth: '40vh' }} />
                    </label>
                    <div className="custom-upload">
                      <button className="custom-upload-btn">
                        Upload Client Master Report
                      </button>
                      <Field
                        name="fileCMR"
                        component={SingleFileInputComponent}
                        label="Client Master Report"
                        disabled={edit_access}
                      />
                    </div>
                  </div>
                  <span className="xs-title mb-4">({getConstants()?.CMR_NOTE})</span>
                </div>
                {edit_access == false ? isSaveBankInformationLoading ? (
                  <BtnSpinner />
                ) : (
                  <div className="mt-3 text-center">
                    <button
                      type="submit"
                      className="theme-btn btn w-25 ml-15"
                    >
                      Submit
                    </button>
                  </div>
                ) : ""}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DashboardLayout >
  );
}

export default DematAccount;
