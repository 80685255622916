import {
  BackrHomeLogo,
  HomePlaySvg,
  HomeAPPStore,
  HomeAPPScreen,
} from "../../../assets/img";

function About() {
  return (
    <section className="mid-wrap">
      <div className="pt-5 pb-5 kyc-form-block home-card-block">
        <div className="container">
          <div className="card form-card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="w-60 text-center">
                  <div className="hw-download-title">DOWNLOAD</div>
                  <div className="hc-logo text-center">
               
                    <img src={BackrHomeLogo} alt="backrr logo" />
                  </div>
                  <p>
                    Participate in and manage your Real Time Angel Fund startup
                    portfolio on our mobile application.
                  </p>
                  <div className="d-inline-flex align-items-center home-store-link">
                    <div>
                      <a href="https://play.google.com/store/apps/details?id=com.backrr" target="_blank" rel="noreferrer">
                        <img src={HomePlaySvg} alt="google play" />
                      </a>
                    </div>
                    <div>
                      <a href="https://apps.apple.com/in/app/backrr/id1661793028" target="_blank">
                        <img src={HomeAPPStore} alt="app store" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-40 home-app-screen text-center">
                  <img src={HomeAPPScreen} alt="screen" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
