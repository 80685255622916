import React, { useEffect, useRef, useState } from 'react'
import DashboardLayout from '../../views/main/deals/DashboardLayout'
import Rating from 'react-rating'
import { GetUserDetailsUpdated } from '../../api'
import { BlankStar, FillStar } from '../../assets/img';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { clearSaveRating, saveRating } from '../../store/actions/saveRatingAction';
import { clearGetFeedbackCategories, getFeedbackCategories } from '../../store/actions/getFeedbackCategoriesAction';
import { clearSaveFeedback, saveFeedback } from '../../store/actions/saveFeedbackAction'
import backArrow from '../../assets/img/back.svg'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

const RateAndFeedback = () => {

    const dispatch = useDispatch()
    const myFeedbackRef = useRef()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const category = searchParams.get("add");

    const [userRating, setUserRating] = useState(0)
    const [categories, setCategories] = useState([])
    const [feedback, setFeedback] = useState(null)


    const { data: userData, isLoading: isUserLoading } = GetUserDetailsUpdated();

    const saveRatingResponse = useSelector(state => state.saveRatingReducer.data)
    const saveFeedbackResponse = useSelector(state => state.saveFeedbackReducer.data);
    const getFeedbackCategoriesResponse = useSelector(state => state.getFeedbackCategoriesReducer.data);
    const loadingFeedbackCategories = useSelector(state => state.getFeedbackCategoriesReducer.loading);

    useEffect(() => {
        setFeedback(null)
        dispatch(getFeedbackCategories())
    }, [])

    useEffect(() => {
        if (category) {
            console.log("category", category)
        }
    }, [category])

    useEffect(() => {
        if (!isUserLoading) {
            setUserRating(userData?.data?.rating ? parseInt(userData?.data?.rating) : 0)
        }
    }, [userData, isUserLoading]);

    useEffect(() => {
        if (getFeedbackCategoriesResponse != null) {
            if (getFeedbackCategoriesResponse.data != null) {
                setCategories(getFeedbackCategoriesResponse.data)
                dispatch(clearGetFeedbackCategories());
            }
        }
    }, [getFeedbackCategoriesResponse]);

    const saveUserRating = (rate) => {
        if (rate > 0) {
            let request = {
                rating: rate.toString()
            }

            dispatch(saveRating(request))
        }
    }

    useEffect(() => {
        if (saveRatingResponse != null) {
            if (saveRatingResponse.data != null) {
                if (userRating > 3) {
                    toast.success(saveRatingResponse?.message);
                } else {
                    setFeedback(null)
                }
                dispatch(clearSaveRating());
            }
        }
    }, [saveRatingResponse]);

    const singularize = (word) => {
        const endings = {
            ves: 'fe',
            ies: 'y',
            i: 'us',
            zes: 'ze',
            ses: 's',
            es: 'e',
            s: ''
        };
        return word.replace(
            new RegExp(`(${Object.keys(endings).join('|')})$`),
            r => endings[r]
        );
    }

    const saveUserFeedback = (category) => {
        if (feedback != null) {
            if (feedback.trim().length > 0) {
                let request = {
                    message: feedback,
                    master_feedback_category_id: category?._id
                }
                dispatch(saveFeedback(request))
            } else {
                toast.error("Please enter valid message")
            }
        } else {
            toast.error("Please enter message")
        }
    }

    useEffect(() => {
        if (saveFeedbackResponse != null) {
            if (saveFeedbackResponse.data != null) {
                toast.success(saveFeedbackResponse?.message)
                dispatch(clearSaveFeedback())

                myFeedbackRef?.current?.click()

                setFeedback("")
            }
        }
    }, [saveFeedbackResponse]);

    if (loadingFeedbackCategories) {
        return (
            <div className='loading-box'>
                <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <DashboardLayout>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" to="/dashboard">
                            <img src={backArrow} alt="arrow" /> Rate Your Experience
                        </Link>
                    </div>
                </div>
            </div>
            <div className="md-title text-start fw-700 cr-heading header-desktop">
                Rate Your Experience
            </div>
            <div className="pt-4 pb-4 ps-5 pe-5">
                <div className='pt-2'>
                    <p className='black-text'>Your opinion is important to us. This way we can keep improving our app.</p>
                </div>
                <div className='pt-3'>
                    <p className='mb-0'>How would you rate our app?</p>
                    <div>
                        <Rating
                            initialRating={userRating}
                            emptySymbol={<img src={BlankStar} height={20} alt='Blank Star' />}
                            fullSymbol={<img src={FillStar} height={20} alt='Filled Star' />}
                            onClick={(rate) => { saveUserRating(rate); setUserRating(rate) }}
                        />
                    </div>
                </div>
                <div className='pt-3'>
                    <p className='black-text'>Please select your feedback category below.</p>
                    <div className="accordion cs-accordion custom-form" id="rateExperience">
                        {
                            categories?.map((item, index) => {
                                return (
                                    <div key={index} className="accordion-item">
                                        <h2 className="accordion-header" id={"heading" + { index }}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${item.values[0].name}`} aria-expanded="false" aria-controls={`${item.values[0].name}`} ref={myFeedbackRef} onClick={() => { item.total?.total > 0 && navigate('/feedback-list', { state: item }) }}>
                                                {item.values[0].name}
                                                {item.total?.total > 0 && <div style={{ backgroundColor: item.total?.un_read_count == 0 ? '#eee9fc' : "#FF6624" }}>
                                                    <div style={{ color: item.total?.un_read_count == 0 ? "#0000FF" : '#FFFFFF', }}>{item.total?.un_read_count > 0 ? item.total?.un_read_count : item.total?.total}</div>
                                                </div>}
                                            </button>
                                        </h2>
                                        <div id={`${item.values[0].name}`} className={category ? category == item.values[0].name ? "accordion-collapse collapse show" : "accordion-collapse collapse" : "accordion-collapse collapse"} aria-labelledby={"heading" + { index }} data-bs-parent="#rateExperience">
                                            <div className="accordion-body ">
                                                <div className='form-group'>
                                                    <label>Please let us know your {item.values[0].name.toLowerCase()}</label>
                                                    <textarea className='form-control' value={feedback} onChange={e => { setFeedback(e.target.value) }}></textarea>
                                                </div>
                                                <button className='theme-btn sm-btn' type='button' onClick={() => { saveUserFeedback(item) }}>Post New {singularize(item.values[0].name)}</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                        Suggestion
                                    </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#rateExperience">
                                    <div className="accordion-body ">
                                        <div className='form-group'>
                                            <label>Please let us know your suggestion</label>
                                            <textarea className='form-control'></textarea>
                                        </div>
                                        <button className='theme-btn sm-btn' type='button'>Post New Suggestion</button>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading2">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                        Questions
                                    </button>
                                </h2>
                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#rateExperience">
                                    <div className="accordion-body">
                                        <div className='form-group'>
                                            <label>Please let us know your questions</label>
                                            <textarea className='form-control'></textarea>
                                        </div>
                                        <button className='theme-btn sm-btn' type='button'>Post New Questions</button>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading3">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                        Compliments
                                    </button>
                                </h2>
                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#rateExperience">
                                    <div className="accordion-body">
                                        <div className='form-group'>
                                            <label>Please let us know your compliments</label>
                                            <textarea className='form-control'></textarea>
                                        </div>
                                        <button className='theme-btn sm-btn' type='button'>Post New Compliments</button>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default RateAndFeedback