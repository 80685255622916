import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { axiosClient } from "../../utils/helpers";
import Logout from "../../views/auth/Logout";

export const saveRating = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.post('investor/save-rating', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getFeedbackCategories = async () => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('feedback/category');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const saveFavourite = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = "Bearer " + Cookies.get('jwtToken');
    const response = await axiosClient.post('favourite', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
}

export const getPortfolio = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('external-portfolio?sector_name=' + requestJson.sector_name + '&syndicate_name=' + requestJson.syndicate_name);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const changePIN = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.post('investor/change-pin', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const updateProfile = async (requestJson) => {
  console.log('REQUEST=> ', requestJson._parts);
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    const response = await axiosClient.post('investor/update-profile', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    axiosClient.defaults.headers['Content-Type'] = 'application/json';
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else if (err.isAxiosError && !err.response) {
      return updateProfile(requestJson);
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getDynamicMessages = async () => {
  try {
    const response = await axiosClient.get('setting/dynimic-html');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    alert(err)
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getFavourites = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = "Bearer " + Cookies.get('jwtToken');
    const response = await axiosClient.get('favourite?page=' + requestJson.page);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
}

export const saveFeedback = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.post('feedback', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};
export const saveComplaint = async (requestJson) => {
  try {
    // axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.post('admin/investor-complaint/submit-form', requestJson);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getFeedbacksList = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('feedback?page=' + requestJson.page + "&master_feedback_category_id=" + requestJson.master_feedback_category_id);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getFeedbacksDetail = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('feedback-chat?page=' + requestJson.page + "&feedback_id=" + requestJson.feedback_id);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const saveExternalInvestment = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    const response = await axiosClient.post('external-portfolio', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    axiosClient.defaults.headers['Content-Type'] = 'application/json';
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else if (err.isAxiosError && !err.response) {
      return saveExternalInvestment(requestJson);
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const updateExternalInvestment = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    const response = await axiosClient.put('external-portfolio/' + requestJson.id, requestJson.request);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    axiosClient.defaults.headers['Content-Type'] = 'application/json';
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else if (err.isAxiosError && !err.response) {
      return updateExternalInvestment(requestJson);
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const deleteExternalInvestment = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.delete('external-portfolio/round/' + requestJson.round_id);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getExternalInvestment = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('external-portfolio/' + requestJson.investment_id);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getStages = async () => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('master/stage');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getSectors = async () => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('master/sector');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getIndustriesTypes = async () => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('master/industries/get_all');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getMyBackrrs = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('investor/bakrrs/my-bakrrs');
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getMyBackrrDeals = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('deal/my-backrrs/interested-investors/' + requestJson.investor_id + '?page=' + requestJson.page);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const saveMyBackrrInterest = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.post('deal/my-backrrs/save-interest/' + requestJson.investor_id, requestJson.dealRequest);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getMyBackrrsPortfolio = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('investor/my-bakrrs/portfolio/' + requestJson.investor_id + '?v_no=' + requestJson.v_no + '&sector_name=' + requestJson.sector_name + '&syndicate_name=' + requestJson.syndicate_name);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    console.log("err", err?.response)
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getDealWiseMyBackrr = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.post('deal/my-backrrs/interested-investors', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    console.log("err", err?.response)
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getMyAllBackrrsPortfolio = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('investor/my-bakrrs/portfolio' + '?v_no=' + requestJson.v_no + '&sector_name=' + requestJson.sector_name + '&syndicate_name=' + requestJson.syndicate_name);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    console.log("err", err?.response)
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const agreeTerms = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.put('investor/agree-term-condition', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const getKycStatus = async () => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = "Bearer " + Cookies.get('jwtToken')
    const response = await axiosClient.get('investor/kyc-status');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
}

export const getUserInfo = async token => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.get('investor/user-info');
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const saveSignedPdf = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = "Bearer " + Cookies.get('jwtToken')
    axiosClient.defaults.headers['Content-Type'] = "multipart/form-data";
    const response = await axiosClient.put('payment/upload-document/' + requestJson.id, requestJson.request);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    axiosClient.defaults.headers['Content-Type'] = 'application/json';
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message)
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout()
    } else if (err.isAxiosError && !err.response) {
      return saveSignedPdf(requestJson);
    } else {
      return toast.error("There is an issue in response, please try again later")
    }
  }
};

export const getCordinates = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.post('payment/get-coordinates', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};

export const requestKYCUpdation = async (requestJson) => {
  try {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwtToken');
    const response = await axiosClient.put('investor/kyc-request-sent', requestJson);
    console.log('REQUEST=> ', requestJson);
    console.log('RESPONSE=> ', response.data);
    return response.data;
  } catch (err) {
    if (err?.response?.data?.statusCode == 403) {
      return toast.error(err.response.data.message);
    } else if (err?.response?.data?.statusCode == 401) {
      return Logout();
    } else {
      return toast.error('There is an issue in response, please try again later');
    }
  }
};