import {types} from './types';

export function deleteExternalIvestment(data) {
  return {
    type: types.SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT,
    payload: data,
  };
}
export function deleteExternalIvestmentSuccess(data) {
  return {
    type: types.SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_SUCCESS,
    payload: data,
  };
}

export function deleteExternalIvestmentFailure(error) {
  return {
    type: types.SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearDeleteExternalIvestment() {
  return {
    type: types.CLEAR_REQUEST_DELETE_EXTERNAL_INVESTMENT,
    payload: {},
  };
}
