import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { clearGetFeedbacksDetail, getFeedbacksDetail } from '../../store/actions/getFeedbacksDetailAction'
import backArrow from '../../assets/img/back.svg'
import noListingImg from '../../assets/img/no-listing-found.png'
import { formateDateWithTimeChat } from '../../utils/utils'

export default function FeedbackDetail() {

    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [feedbacks, setFeedbacks] = useState([])

    const getFeedbacksDetailResponse = useSelector(state => state.getFeedbacksDetailReducer.data);

    useEffect(() => {
        dispatch(getFeedbacksDetail({ page: 1, feedback_id: state?.feedback_id }))
    }, [])

    useEffect(() => {
        if (getFeedbacksDetailResponse != null) {
            if (getFeedbacksDetailResponse.data != null) {
                setFeedbacks(getFeedbacksDetailResponse?.data)
                dispatch(clearGetFeedbacksDetail());
            }
        }
    }, [getFeedbacksDetailResponse]);

    console.log("state", state)

    return (
        <div>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" onClick={() => { navigate(-1) }}>
                            <img src={backArrow} alt="arrow" /> {state?.category?.values[0]?.name}
                        </Link>
                    </div>
                </div>
            </div>
            <div className='addround-header header-desktop'>
                <div className='container'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <div class="md-title text-start fw-700 cursor-pointer d-flex align-items-center">
                                <button class="back-btn" type="button" onClick={() => { navigate(-1) }}><img src={backArrow} alt="arrow" className='d-inline-block' /></button>
                                <div className='pt-1 blue-text'>{state?.category?.values[0]?.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {
                    feedbacks?.length > 0 &&
                    feedbacks?.map((item, index) => {
                        return (
                            <div>
                                <div>
                                    <div>{item.name}</div>
                                    <div>{item.message}</div>
                                </div>
                                <div>{formateDateWithTimeChat(item.createdAt)}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
