import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';

// ==============================|| REACT DOM RENDER  ||============================== //

const queryClient = new QueryClient();
const root = createRoot(document.getElementById('root'));

// const persistConfig = {
//     key: 'root',
//     storage,
//   }

root.render(
	<QueryClientProvider client={queryClient}>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</QueryClientProvider >,
);
