import React from 'react';
import { BlankStar, FillStar } from '../assets/img';

const StarRating = ({ rating }) => {
    return (
        <>
            {rating >= 1 ? (
                <i className='bi bi-star-fill'></i>
            ) : (
                <i className='bi bi-star'></i>
            )}
            {rating >= 2 ? (
                <i className='bi bi-star-fill'></i>
            ) : (
                <i className='bi bi-star'></i>
            )}
            {rating >= 3 ? (
                <i className='bi bi-star-fill'></i>
            ) : (
                <i className='bi bi-star'></i>
            )}
            {rating >= 4 ? (
                <i className='bi bi-star-fill'></i>
            ) : (
                <i className='bi bi-star'></i>
            )}
            {rating >= 5 ? (
                <i className='bi bi-star-fill'></i>
            ) : (
                <i className='bi bi-star'></i>
            )}
        </>
    );
};

export default StarRating;
