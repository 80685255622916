import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import successIcon from '../../../assets/img/success-icon.svg'
import { clearGetKycStatus, getKycStatus } from '../../../store/actions/getKycStatusAction';
import Modal from '../../../utils/Modal';

function About() {

  const [open, setOpen] = useState(false);

  const [status, setStatus] = useState([])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const kycStatusResponse = useSelector(state => state.getKycStatusReducer.data)

  useEffect(() => {
    dispatch(getKycStatus())
  }, [])

  useEffect(() => {
    if (kycStatusResponse != null) {
      if (kycStatusResponse.data != null) {
        console.log("kycStatusResponse", kycStatusResponse)
        setStatus(kycStatusResponse.data)
        dispatch(clearGetKycStatus())
      }
    }
  }, [kycStatusResponse])

  return (
    <section className="mid-wrap">
      <div className="pt-5 pb-5 kyc-form-block kyc-success-block">
        <div className='container'>
          <div className="mxw-800">
            <div className="card form-card">
              <div className="card-body pd-50">
                <div className="text-center mb-4">
                  <img src={successIcon} alt="success" />
                </div>
                <h5 className="card-title lg-title">Details Submitted <br />Successfully</h5>
                <p className="sm-title mt-4 mb-5 mxw-360">Thank you for submitting your details. Our team will
                  review the details and will get back to you in 3-5 working days.</p>
                <div className="success-menu">
                  <ul>
                    <li>
                      <Link to="https://www.realtimeaf.com/terms-of-use.html" target='_blank'>Terms of use</Link>
                    </li>
                    <li>
                      <Link to="https://www.realtimeaf.com/" target='_blank'>About us</Link>
                    </li>
                  </ul>
                </div>

                <div className="mt-5 text-center">
                  <div className="w-100 text-center">
                    <Link to="/my-profile/personalize-profile" className="gray-btn btn w-50">Personalize Your Profile</Link>
                  </div>
                  <div className="w-100 text-center mt-3">
                    <Link to="/dashboard" className="gray-btn btn w-50">Go to home</Link>
                  </div>
                  <div className="w-100 text-center mt-3">
                    <button type='button' className="theme-btn btn w-50" onClick={() => { setOpen(true) }}>Check KYC Status</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal commonModalShow={open}
        commonModalClose={() => { setOpen(false) }}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title' id='documentModalLabel'>Thank you for showing your interest</h5>
              <button type='button' className='btn-close' onClick={() => { setOpen(false) }}></button>
            </div>
            <div className='mb-4' id='documentModalLabel'>Here is the status of your account.</div>
            <div className="terms-accept-body">
              {
                status?.map((item, index) => {
                  return (
                    <div className="deal-flow-item" key={index}>
                      <span
                        className="deal-flow-circle"
                        style={{
                          background: item.masters.values[0].color,
                        }}
                      ></span>

                      <div className="sm-title text-start black-text fw-600">
                        {item.masters.values[0].name}
                      </div>

                      <div className="sm-title text-start mt-2 font-0-8em">
                        {moment(item.updatedAt).format(
                          "DD-MMM-YYYY, hh:mm A"
                        )}{" "}
                        IST
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="text-center mt-2">
              <button type="button" className="theme-btn px-4 terms-accept-btn" onClick={() => { navigate("/contact-us") }}>Contact Us</button>
            </div>
          </>
        }
      >
      </Modal>
    </section>
  );
};

export default About;