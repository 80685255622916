
import Cookies from 'js-cookie';
import DealLeaftMenu from './DealLeaftMenu'

const DashboardLayout = ({ children }) => {
    const userDetail = Cookies.get("userDetail")
        ? JSON.parse(Cookies.get("userDetail"))
        : {};
    return (
        <>
            <section className="mid-wrap">
                <div className="pt-5 pb-5 main-home-card">
                    <div className="container">
                        <div className="d-flex common-flex">
                            <DealLeaftMenu showMyBackrr={userDetail?.showMyBackrr} />
                            <div className="common-right">
                                <div className="custom-card my-profile-card cc-common-card">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DashboardLayout