const domainDetails = (domainSlug) => {

  var subdomain = process.env.REACT_APP_SUB_DOMAIN;
  console.log(subdomain,"subdomainsubdomainsubdomainsubdomainsubdomain")
  var exixtsDomain = ["app", "gsf", "waveform", "launchcapital","alluvium","beyondseed", "growx", "mainstreet", "climbercapital", "z1ncapital", "pransu", "drinkprime", "infinyteclub1", "nizamabadangels3", "super", "dfan", "culture", "appcom", "signal", "samarthya", "eckle", "aclr8", "shuru", "rogue", "rtaf", "goodsoul", "av", "ivay", "windt"];
  if (exixtsDomain.includes(domainSlug)) {
    return `${process.env.REACT_APP_BUCKET_URL}/fund/${domainSlug}/logo.png`;
  } else {
    return `${process.env.REACT_APP_BUCKET_URL}/fund/app/logo.png`;
  }

  // var details ={};
  // switch (domainSlug) {

  //   case 'localhost':

  //   details = {
  //       'logo':'http://admin.realtimeaf.com/static/media/backrr1.3b1b699e3dd91d04a2d6.png',
  //       'header_color':'#rrbbbb',
  //       'title':'backrr',
  //       'domain':domainSlug,
  //       'title':'GSF:backrr'

  //   }

  //   break

  //   case 'localhost2':

  //   details = {
  //       'logo':'http://admin.realtimeaf.com/static/media/backrr1.3b1b699e3dd91d04a2d6.png',
  //       'header_color':'#rrbbbb',
  //       'title':'backrr',
  //       'domain':domainSlug

  //   }
  //   break
  //   default:
  //     details = {
  //       'logo':'http://admin.realtimeaf.com/static/media/backrr1.3b1b699e3dd91d04a2d6.png',
  //       'header_color':'#rrbbbb',
  //       'title':'backrr',
  //       'domain':'default'

  //   }
  //   break
  // }
  // return details;
};

export default domainDetails;
