import {types} from './types';

export function sendSignedDocument(data) {
  return {
    type: types.SEND_REQUEST_SIGNED_DOCUMENTS,
    payload: data,
  };
}

export function sendSignedDocumentSuccess(data) {
  return {
    type: types.SEND_REQUEST_SIGNED_DOCUMENTS_SUCCESS,
    payload: data,
  };
}

export function sendSignedDocumentFailure(error) {
  return {
    type: types.SEND_REQUEST_SIGNED_DOCUMENTS_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearSendSignedDocument() {
  return {
    type: types.CLEAR_REQUEST_SIGNED_DOCUMENTS,
    payload: {},
  };
}