let constants = {
    PORTFOLIO_INFO: ""
}

const setConstants = (info) => {
    constants = { ...constants, ...info }
}

const getConstants = () => {
    return constants
}

export { setConstants, getConstants }