import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function AddInvestmentSplash() {

  const { state } = useLocation()
  const navigate = useNavigate()

  console.log("state", state)

  return (
    <div>
      <img src={state?.url} />
      <div>Track all your <span>Angel Investments</span> at one place</div>
      {
        state?.text?.map(item => {
          return (
            <ul>
              <li>{item}</li>
            </ul>
          )
        })
      }
      <div className="mt-3 text-center">
        <button type="button" className="theme-btn btn w-25" onClick={() => { navigate("/add-investment") }}>
          Get Started
        </button>
      </div>
    </div>
  )
}
