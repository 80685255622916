import { types } from './types';

export function saveExternalInvestment(data) {
  return {
    type: types.SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT,
    payload: data,
  };
}

export function saveExternalInvestmentSuccess(data) {
  return {
    type: types.SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_SUCCESS,
    payload: data,
  };
}

export function saveExternalInvestmentFailure(error) {
  return {
    type: types.SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearSaveExternalInvestment() {
  return {
    type: types.CLEAR_REQUEST_SAVE_EXTERNAL_INVESTMENT,
    payload: {},
  };
}
