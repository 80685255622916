import { types } from './types';

export function getCoordinates(data) {
    return {
        type: types.SEND_REQUEST_GET_COORDINATES,
        payload: data,
    };
}

export function getCoordinatesSuccess(data) {
    return {
        type: types.SEND_REQUEST_GET_COORDINATES_SUCCESS,
        payload: data,
    };
}

export function getCoordinatesFailure(error) {
    return {
        type: types.SEND_REQUEST_GET_COORDINATES_FAILURE,
        payload: {},
        error: error,
    };
}

export function clearGetCoordinates() {
    return {
        type: types.CLEAR_REQUEST_GET_COORDINATES,
        payload: {},
    };
}
