import React from "react";
import { Field, Form, Formik, FastField } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back } from "../../../../assets/img";
import {
  CustomInputComponent,
  CustomRadioComponent,
  CustomTextareaComponent,
  CustomMultDropDownComponent,
  CustomCheckbox,
  CustomCheckboxGroup,
} from "../../../../components/formik/FieldComponents";
import { SaveQuestions, FetchAllIndustries, GetUserDetailsUpdated } from "../../../../api";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_MORE_ABOUT_YOU,
  UPDATE_PERSONALIZE_YOUR_PROFILE
} from "../../../../store/actions";
import { useEffect, useState } from "react";
import { moreAboutYouData, personalizeProfile } from "../../../../store/lcocalStaticVariable";
import DashboardLayout from "../../../main/deals/DashboardLayout";
import backArrow from '../../../../assets/img/back.svg'

function PersonalizeProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [edit_access, setEditAccess] = useState(true);
  const [moreAboutYou, setMoreAboutYou] = useState(null)
  const [personalizeYourProfile, setPersonalizeYourProfile] = useState(null)

  const checkUSerType = useSelector((state) => state.userDetails);

  const { mutateAsync: saveQuestions, isLoading: isSaveQuestionsLoading } = SaveQuestions();
  const { data: userData, isLoading: isDocsLoading, refetch: refetch } = GetUserDetailsUpdated();

  const [isHowHearAbout, setHowHearAbout] = React.useState(false);

  // const moreAboutYou = useSelector(
  //   (state) => state.indianCitizenship.moreAboutYou
  // );
  // const personalizeYourProfile = useSelector(
  //   (state) => state.indianCitizenship.personalizeYourProfile
  // );

  useEffect(() => {
    if (userData != null) {
      if (userData.data != null) {
        let data = userData.data;
        console.log("UserdATA", data)

        console.log("personalized", data?.personalize_profile_static)

        if (data?.more_about_you_static?.length > 0) {
          let moreAboutAnswer = data?.more_about_you_static[0]
          setMoreAboutYou(moreAboutAnswer)
          // setInvestedBefore(moreAboutAnswer?.haveInvestedBefore)
          // setHowHear(moreAboutAnswer?.howHearAboutUs)
          // setReferralName(moreAboutAnswer?.referralName)
        }

        if (data?.personalize_profile_static?.length > 0) {
          let personalizedAnswer = data.personalize_profile_static[0]
          setPersonalizeYourProfile(personalizedAnswer)
          // setExpertiseSectors(personalizedAnswer?.expertiseInSectors)
          // setInvestSectors(personalizedAnswer?.investInSectors)
          // setInvestStages(personalizedAnswer?.investInStartUps)
          // setLinkedInUrl(personalizedAnswer?.linkedInProfileUrl)
        }
      }
    }
  }, [userData]);

  const toggleEdit = () => {
    // 👇️ passed function to setState
    setEditAccess(edit_access => !edit_access);
  };

  const handleRadioChange = (e) => {
    var checkValue = e.target.value;
    if (checkValue !== "search" && checkValue !== "news") {
      setHowHearAbout(true);
    } else {
      setHowHearAbout(false);
    }
  };

  const { data: industriesType } =
    FetchAllIndustries();

  useEffect(() => {
    var checkValue = moreAboutYou?.howHearAboutUs;
    if (checkValue !== "search" && checkValue !== "news") {
      setHowHearAbout(true);
    } else {
      setHowHearAbout(false);
    }

  }, [moreAboutYou?.howHearAboutUs]);

  const moreAboutYouValidationSchema = Yup.object().shape({
    // haveInvestedBefore: Yup.string().required("Please select an option"),
    // howHearAboutUs: Yup.string().required("Please select an option"),
    referralName: isHowHearAbout
      ? Yup.string().required("Please enter referral name")
      : Yup.string(),
  });


  return (
    <DashboardLayout>
      <div className='d-flex align-items-center justify-content-between cr-heading'>
        <div className="md-title text-start fw-700">
          Personalize Your Profile
        </div>

        <div>
          <button type="button" onClick={toggleEdit} className='theme-btn btn btn-sm' >{edit_access ? 'Edit' : 'Cancel'}</button>
        </div>

      </div>

      <div className="pt-4 pb-4 ps-5 pe-5">
        <div className="pt-0 pb-5 kyc-form-block pyp-block">
          <Formik
            initialValues={{ ...moreAboutYou, ...personalizeYourProfile }}
            enableReinitialize={true}
            validationSchema={moreAboutYouValidationSchema}
            onSubmit={(values) => {
              saveQuestions(
                {
                  payload: {
                    more_about_you_static: values,
                    personalize_profile_static: values,
                    page: checkUSerType?.userType == 'indian_corporate' || checkUSerType?.userType == 'foreign_corporate' || checkUSerType?.userType == 'huf' ? "6" : "7",
                    re_submit: false,
                  },
                },
                {
                  onSuccess: () => {
                    dispatch({
                      type: UPDATE_MORE_ABOUT_YOU,
                      payload: values,
                    });
                    dispatch({
                      type: UPDATE_PERSONALIZE_YOUR_PROFILE,
                      payload: values,
                    });
                    // dispatch({ type: SET_PAGE, payload: 6 });
                    // navigate("/indian-citizenship/nominee-details");
                    navigate(-1)
                  },
                }
              );
            }}
          >
            {({ errors, touched }) => (
              <Form className="custom-form">
                <div className="row" style={{ opacity: edit_access ? 0.5 : 1 }}>
                  {/* Invested before question */}
                  <div className="col-12 form-group">
                    <label className="">
                      Have you invested in startups before?
                    </label>
                    <Field
                      name="haveInvestedBefore"
                      component={CustomRadioComponent}
                      options={moreAboutYouData.haveInvestedBefore}
                      disabled={edit_access}
                      className="form-group"
                    />
                  </div>

                  {/* Hear About Us Question */}
                  <div className="col-12 form-group">
                    <label className="">
                      How did you hear about us?
                    </label>
                    <div className="rr-col-2">
                      <Field
                        name="howHearAboutUs"
                        component={CustomRadioComponent}
                        options={moreAboutYouData?.howHearAboutUs}
                        onChange={handleRadioChange}
                        disabled={edit_access}
                        className="form-group"
                      />
                    </div>
                  </div>

                  {isHowHearAbout && (
                    <div className="col-12 form-group">
                      <label className="required">Referral Name(Startup/Investor)</label>
                      <Field
                        name="referralName"
                        component={CustomInputComponent}
                        type="text"
                        disabled={edit_access}
                        className="form-control"
                      />
                    </div>
                  )}

                  <div
                    className="col-12 form-group"
                    role="group"
                    aria-labelledby="checkbox-group"
                  >
                    <label className="">
                      I have expertise in these sectors
                    </label>
                    <div className="rr-col-2">
                      <Field
                        name="expertiseInSectors"
                        component={CustomMultDropDownComponent}
                        options={industriesType}
                        initialOption={'Select an option'}
                        isDisabled={edit_access}
                        className="App"
                      />
                    </div>
                  </div>

                  <div
                    className="col-12 form-group"
                    role="group"
                    aria-labelledby="checkbox-group"
                  >
                    <label className="">
                      I want to invest in these sectors
                    </label>
                    <div className="rr-col-2">
                      <Field
                        name="investInSectors"
                        component={CustomMultDropDownComponent}
                        options={industriesType}
                        initialOption={'Select an option'}
                        isDisabled={edit_access}
                        className="App"
                      />
                    </div>
                  </div>


                  <div
                    className="col-12 form-group"
                    role="group"
                    aria-labelledby="checkbox-group"
                  >
                    <label className="">
                      I would like to invest in startup at these stages
                    </label>
                    <div className="rr-col-2">
                      <Field
                        name="investInStartUps"
                        component={CustomCheckboxGroup}
                        options={
                          personalizeProfile.likeToInvestInStartupStage
                        }
                        multiline={"yes"}
                        disabled={edit_access}
                        className="react-checkbox"
                      />
                    </div>
                  </div>


                  {/* <div className="col-12 form-group">
                    <label className="required">
                      How do you prefer to invest?
                    </label>
                    <Field
                      name="howPreferInvest"
                      component={CustomRadioComponent}
                      options={moreAboutYouData.howDoYouPreferToInvest}
                    />
                  </div> */}


                  <div className="col-12 form-group">
                    <label>Linkedin Profile URL</label>
                    <Field
                      name="linkedInProfileUrl"
                      component={CustomInputComponent}
                      type="text"
                      disabled={edit_access}
                      className="form-control"
                    />
                  </div>
                </div>
                {edit_access == false ? isSaveQuestionsLoading ? (
                  <BtnSpinner />
                ) : (
                  <div className="mt-3 text-center">
                    {/* <button
                          type="submit"
                          className="gray-btn btn idil-btn"
                          onClick={() => {
                            dispatch({ type: SET_PAGE, payload: 4 });
                            navigate(
                              "/indian-citizenship/personalize-your-profile"
                            );
                          }}
                        >
                          I'LL DO IT LATER
                        </button> */}
                    <button
                      type="submit"
                      className="theme-btn btn w-25 ml-15"
                    >
                      Submit
                    </button>
                  </div>
                ) : ""}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default PersonalizeProfile;
