import React from 'react';

const ShareDealComponent = ({ DealData }) => {
  // const [isVisible, setIsVisible] = useState(true);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsVisible(false);
  //   }, 3000);
  //   return () => clearTimeout(timeout);
  // }, []);

  return (

    <div className="d-inline-flex align-items-center">
    {/* <div>
      <a href="#" className="d-inline-block">
        <img src={chatIcon} alt="chat" />
      </a>
    </div>
    <div className="btn-group custom-dropdown ml-10">
      <button
        type="button"
        className="btn dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src={menuIcon} alt="menu icon" />
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        <li>
          <button className="dropdown-item" type="button">
            Bookmark
          </button>
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            // data-bs-toggle="modal"
            // data-bs-target="#shareModal"
          >
            Share
          </button>
        </li>
      </ul>
    </div> */}
  </div>
    
  );
};

export default ShareDealComponent;
