import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import storage from "redux-persist/lib/storage";
import { useDispatch } from "react-redux";
import {
  RESET_INDIAN_CITIZEN,
  SET_USER_TYPE,
  RESET_INDIAN_CORPORATE,
  RESET_FOREIGN_CITIZEN,
  RESET_FOREIGN_CORPORATE,
  RESET_INDIAN_CITIZEN_HUF,
} from "../../store/actions";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  Cookies.remove("jwtToken");
  Cookies.remove("userDetail");
  Cookies.remove("verifyEmail");
  Cookies.remove('jwtTokenemailVerify');
  Cookies.remove('verifyEmail');
  Cookies.remove('domainSlug');
  Cookies.remove('checkUSerType');

  Cookies.remove("checkNominee");
  Cookies.remove("redirectPath");
  // toast.success('You have logout successfully ')
  //window.location.reload(false);
  useEffect(() => {
    dispatch({ type: SET_USER_TYPE, payload: "" });

    dispatch({ type: RESET_INDIAN_CITIZEN });

    dispatch({ type: RESET_INDIAN_CITIZEN_HUF });

    dispatch({ type: RESET_INDIAN_CORPORATE });

    dispatch({ type: RESET_FOREIGN_CITIZEN });

    dispatch({ type: RESET_FOREIGN_CORPORATE });
    storage.removeItem("persist:root");
    Cookies.remove("userDetail");
    navigate("/login");
  }, []);
};

export default Logout;
