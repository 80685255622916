export const types = {

  PROFILE_IMAGE: 'PROFILE_IMAGE',

  SEND_REQUEST_GET_FEEDBACK_CATEGORIES: 'SEND_REQUEST_GET_FEEDBACK_CATEGORIES',
  SEND_REQUEST_GET_FEEDBACK_CATEGORIES_SUCCESS: 'SEND_REQUEST_GET_FEEDBACK_CATEGORIES_SUCCESS',
  SEND_REQUEST_GET_FEEDBACK_CATEGORIES_FAILURE: 'SEND_REQUEST_GET_FEEDBACK_CATEGORIES_FAILURE',
  CLEAR_REQUEST_GET_FEEDBACK_CATEGORIES: 'CLEAR_REQUEST_GET_FEEDBACK_CATEGORIES',

  SEND_REQUEST_SAVE_RATING: 'SEND_REQUEST_SAVE_RATING',
  SEND_REQUEST_SAVE_RATING_SUCCESS: 'SEND_REQUEST_SAVE_RATING_SUCCESS',
  SEND_REQUEST_SAVE_RATING_FAILURE: 'SEND_REQUEST_SAVE_RATING_FAILURE',
  CLEAR_REQUEST_SAVE_RATING: 'CLEAR_REQUEST_SAVE_RATING',

  SEND_REQUEST_SAVE_FEEDBACK: 'SEND_REQUEST_SAVE_FEEDBACK',
  SEND_REQUEST_SAVE_FEEDBACK_SUCCESS: 'SEND_REQUEST_SAVE_FEEDBACK_SUCCESS',
  SEND_REQUEST_SAVE_FEEDBACK_FAILURE: 'SEND_REQUEST_SAVE_FEEDBACK_FAILURE',
  CLEAR_REQUEST_SAVE_FEEDBACK: 'CLEAR_REQUEST_SAVE_FEEDBACK',

  SEND_REQUEST_GET_FEEDBACKS_LIST: 'SEND_REQUEST_GET_FEEDBACKS_LIST',
  SEND_REQUEST_GET_FEEDBACKS_LIST_SUCCESS: 'SEND_REQUEST_GET_FEEDBACKS_LIST_SUCCESS',
  SEND_REQUEST_GET_FEEDBACKS_LIST_FAILURE: 'SEND_REQUEST_GET_FEEDBACKS_LIST_FAILURE',
  CLEAR_REQUEST_GET_FEEDBACKS_LIST: 'CLEAR_REQUEST_GET_FEEDBACKS_LIST',

  SEND_REQUEST_GET_FEEDBACKS_DETAILS: 'SEND_REQUEST_GET_FEEDBACKS_DETAILS',
  SEND_REQUEST_GET_FEEDBACKS_DETAILS_SUCCESS: 'SEND_REQUEST_GET_FEEDBACKS_DETAILS_SUCCESS',
  SEND_REQUEST_GET_FEEDBACKS_DETAILS_FAILURE: 'SEND_REQUEST_GET_FEEDBACKS_DETAILS_FAILURE',
  CLEAR_REQUEST_GET_FEEDBACKS_DETAILS: 'CLEAR_REQUEST_GET_FEEDBACKS_DETAILS',

  SEND_REQUEST_SAVE_CHAT: 'SEND_REQUEST_SAVE_CHAT',
  SEND_REQUEST_SAVE_CHAT_SUCCESS: 'SEND_REQUEST_SAVE_CHAT_SUCCESS',
  SEND_REQUEST_SAVE_CHAT_FAILURE: 'SEND_REQUEST_SAVE_CHAT_FAILURE',
  CLEAR_REQUEST_SAVE_CHAT: 'CLEAR_REQUEST_SAVE_CHAT',

  SEND_REQUEST_SAVE_FAVOURITE: 'SEND_REQUEST_SAVE_FAVOURITE',
  SEND_REQUEST_SAVE_FAVOURITE_SUCCESS: 'SEND_REQUEST_SAVE_FAVOURITE_SUCCESS',
  SEND_REQUEST_SAVE_FAVOURITE_FAILURE: 'SEND_REQUEST_SAVE_FAVOURITE_FAILURE',
  CLEAR_REQUEST_SAVE_FAVOURITE: 'CLEAR_REQUEST_SAVE_FAVOURITE',

  SEND_REQUEST_GET_FAVOURITES: 'SEND_REQUEST_GET_FAVOURITES',
  SEND_REQUEST_GET_FAVOURITES_SUCCESS: 'SEND_REQUEST_GET_FAVOURITES_SUCCESS',
  SEND_REQUEST_GET_FAVOURITES_FAILURE: 'SEND_REQUEST_GET_FAVOURITES_FAILURE',
  CLEAR_REQUEST_GET_FAVOURITES: 'CLEAR_REQUEST_GET_FAVOURITES',

  SEND_REQUEST_GET_PORTFOLIO: 'SEND_REQUEST_GET_PORTFOLIO',
  SEND_REQUEST_GET_PORTFOLIO_SUCCESS: 'SEND_REQUEST_GET_PORTFOLIO_SUCCESS',
  SEND_REQUEST_GET_PORTFOLIO_FAILURE: 'SEND_REQUEST_GET_PORTFOLIO_FAILURE',
  CLEAR_REQUEST_GET_PORTFOLIO: 'CLEAR_REQUEST_GET_PORTFOLIO',

  SEND_REQUEST_CHANGE_PIN: 'SEND_REQUEST_CHANGE_PIN',
  SEND_REQUEST_CHANGE_PIN_SUCCESS: 'SEND_REQUEST_CHANGE_PIN_SUCCESS',
  SEND_REQUEST_CHANGE_PIN_FAILURE: 'SEND_REQUEST_CHANGE_PIN_FAILURE',
  CLEAR_REQUEST_CHANGE_PIN: 'CLEAR_REQUEST_CHANGE_PIN',

  SEND_REQUEST_UPDATE_PROFILE: 'SEND_REQUEST_UPDATE_PROFILE',
  SEND_REQUEST_UPDATE_PROFILE_SUCCESS: 'SEND_REQUEST_UPDATE_PROFILE_SUCCESS',
  SEND_REQUEST_UPDATE_PROFILE_FAILURE: 'SEND_REQUEST_UPDATE_PROFILE_FAILURE',
  CLEAR_SEND_REQUEST_UPDATE_PROFILE: 'CLEAR_SEND_REQUEST_UPDATE_PROFILE',

  SEND_REQUEST_GET_DYNAMIC_MESSAGES: 'SEND_REQUEST_DYNAMIC_MESSAGES',
  SEND_REQUEST_DYNAMIC_MESSAGES_SUCCESS: 'SEND_REQUEST_DYNAMIC_MESSAGES_SUCCESS',
  SEND_REQUEST_DYNAMIC_MESSAGES_FAILURE: 'SEND_REQUEST_DYNAMIC_MESSAGES_FAILURE',
  CLEAR_REQUEST_DYNAMIC_MESSAGES: 'CLEAR_REQUEST_DYNAMIC_MESSAGES',

  SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT: 'SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT',
  SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_SUCCESS: 'SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_SUCCESS',
  SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_FAILURE: 'SEND_REQUEST_SAVE_EXTERNAL_INVESTMENT_FAILURE',
  CLEAR_REQUEST_SAVE_EXTERNAL_INVESTMENT: 'CLEAR_REQUEST_SAVE_EXTERNAL_INVESTMENT',

  SEND_REQUEST_GET_EXTERNAL_INVESTMENT: 'SEND_REQUEST_GET_EXTERNAL_INVESTMENT',
  SEND_REQUEST_GET_EXTERNAL_INVESTMENT_SUCCESS: 'SEND_REQUEST_GET_EXTERNAL_INVESTMENT_SUCCESS',
  SEND_REQUEST_GET_EXTERNAL_INVESTMENT_FAILURE: 'SEND_REQUEST_GET_EXTERNAL_INVESTMENT_FAILURE',
  CLEAR_REQUEST_GET_EXTERNAL_INVESTMENT: 'CLEAR_REQUEST_GET_EXTERNAL_INVESTMENT',

  SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT: 'SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT',
  SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_SUCCESS: 'SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_SUCCESS',
  SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_FAILURE: 'SEND_REQUEST_DELETE_EXTERNAL_INVESTMENT_FAILURE',
  CLEAR_REQUEST_DELETE_EXTERNAL_INVESTMENT: 'CLEAR_REQUEST_DELETE_EXTERNAL_INVESTMENT',

  SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT: 'SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT',
  SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_SUCCESS: 'SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_SUCCESS',
  SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_FAILURE: 'SEND_REQUEST_UPDATE_EXTERNAL_INVESTMENT_FAILURE',
  CLEAR_REQUEST_UPDATE_EXTERNAL_INVESTMENT: 'CLEAR_REQUEST_UPDATE_EXTERNAL_INVESTMENT',

  SEND_REQUEST_GET_SECTORS: 'SEND_REQUEST_GET_SECTORS',
  SEND_REQUEST_GET_SECTORS_SUCCESS: 'SEND_REQUEST_GET_SECTORS_SUCCESS',
  SEND_REQUEST_GET_SECTORS_FAILURE: 'SEND_REQUEST_GET_SECTORS_FAILURE',
  CLEAR_REQUEST_GET_SECTORS: 'CLEAR_REQUEST_GET_SECTORS',

  SEND_REQUEST_GET_STAGES: 'SEND_REQUEST_GET_STAGES',
  SEND_REQUEST_GET_STAGES_SUCCESS: 'SEND_REQUEST_GET_STAGES_SUCCESS',
  SEND_REQUEST_GET_STAGES_FAILURE: 'SEND_REQUEST_GET_STAGES_FAILURE',
  CLEAR_REQUEST_GET_STAGES: 'CLEAR_REQUEST_GET_STAGES',

  SEND_REQUEST_GET_INDUSTRIES_TYPES: 'SEND_REQUEST_GET_INDUSTRIES_TYPES',
  SEND_REQUEST_GET_INDUSTRIES_TYPES_SUCCESS: 'SEND_REQUEST_GET_INDUSTRIES_TYPES_SUCCESS',
  SEND_REQUEST_GET_INDUSTRIES_TYPES_FAILURE: 'SEND_REQUEST_GET_INDUSTRIES_TYPES_FAILURE',
  CLEAR_REQUEST_GET_INDUSTRIES_TYPES: 'CLEAR_REQUEST_GET_INDUSTRIES_TYPES',

  SEND_REQUEST_GET_MY_BACKRRS: 'SEND_REQUEST_GET_MY_BACKRRS',
  SEND_REQUEST_GET_MY_BACKRRS_SUCCESS: 'SEND_REQUEST_GET_MY_BACKRRS_SUCCESS',
  SEND_REQUEST_GET_MY_BACKRRS_FAILURE: 'SEND_REQUEST_GET_MY_BACKRRS_FAILURE',
  CLEAR_REQUEST_GET_MY_BACKRRS: 'CLEAR_REQUEST_GET_MY_BACKRRS',

  SEND_REQUEST_GET_MY_BACKRR_DEALS: 'SEND_REQUEST_GET_MY_BACKRR_DEALS',
  SEND_REQUEST_GET_MY_BACKRR_DEALS_SUCCESS: 'SEND_REQUEST_GET_MY_BACKRR_DEALS_SUCCESS',
  SEND_REQUEST_GET_MY_BACKRR_DEALS_FAILURE: 'SEND_REQUEST_GET_MY_BACKRR_DEALS_FAILURE',
  CLEAR_REQUEST_GET_MY_BACKRR_DEALS: 'CLEAR_REQUEST_GET_MY_BACKRR_DEALS',

  SEND_REQUEST_SAVE_MY_BACKRR_INTEREST: 'SEND_REQUEST_SAVE_MY_BACKRR_INTEREST',
  SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_SUCCESS: 'SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_SUCCESS',
  SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_FAILURE: 'SEND_REQUEST_SAVE_MY_BACKRR_INTEREST_FAILURE',
  CLEAR_REQUEST_SAVE_MY_BACKRR_INTEREST: 'CLEAR_REQUEST_SAVE_MY_BACKRR_INTEREST',

  SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO: 'SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO',
  SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_SUCCESS: 'SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_SUCCESS',
  SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_FAILURE: 'SEND_REQUEST_GET_MY_BACKRR_PORTFOLIO_FAILURE',
  CLEAR_REQUEST_GET_MY_BACKRR_PORTFOLIO: 'CLEAR_REQUEST_GET_MY_BACKRR_PORTFOLIO',

  SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR: 'SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR',
  SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR_SUCCESS: 'SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR_SUCCESS',
  SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR_FAILURE: 'SEND_REQUEST_GET_DEAL_WISE_MY_BACKRR_FAILURE',
  CLEAR_REQUEST_GET_DEAL_WISE_MY_BACKRR: 'CLEAR_REQUEST_GET_DEAL_WISE_MY_BACKRR',

  SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO: 'SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO',
  SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_SUCCESS: 'SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_SUCCESS',
  SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_FAILURE: 'SEND_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO_FAILURE',
  CLEAR_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO: 'CLEAR_REQUEST_GET_MY_ALL_BACKRR_PORTFOLIO',

  SEND_REQUEST_AGREE_TERMS: 'SEND_REQUEST_AGREE_TERMS',
  SEND_REQUEST_AGREE_TERMS_SUCCESS: 'SEND_REQUEST_AGREE_TERMS_SUCCESS',
  SEND_REQUEST_AGREE_TERMS_FAILURE: 'SEND_REQUEST_AGREE_TERMS_FAILURE',
  CLEAR_REQUEST_AGREE_TERMS: 'CLEAR_REQUEST_AGREE_TERMS',

  SEND_REQUEST_GET_KYC_STATUS: 'SEND_REQUEST_GET_KYC_STATUS',
  SEND_REQUEST_GET_KYC_STATUS_SUCCESS: 'SEND_REQUEST_GET_KYC_STATUS_SUCCESS',
  SEND_REQUEST_GET_KYC_STATUS_FAILURE: 'SEND_REQUEST_GET_KYC_STATUS_FAILURE',
  CLEAR_REQUEST_GET_KYC_STATUS: 'CLEAR_REQUEST_GET_KYC_STATUS',

  SEND_REQUEST_GET_USER_INFO: 'SEND_REQUEST_GET_USER_INFO',
  SEND_REQUEST_GET_USER_INFO_SUCCESS: 'SEND_REQUEST_GET_USER_INFO_SUCCESS',
  SEND_REQUEST_GET_USER_INFO_FAILURE: 'SEND_REQUEST_GET_USER_INFO_FAILURE',
  CLEAR_REQUEST_GET_USER_INFO: 'CLEAR_REQUEST_GET_USER_INFO',

  SEND_REQUEST_SAVE_COMPLAINT: 'SEND_REQUEST_SAVE_COMPLAINT',
  SEND_REQUEST_SAVE_COMPLAINT_SUCCESS: 'SEND_REQUEST_SAVE_COMPLAINT_SUCCESS',
  SEND_REQUEST_SAVE_COMPLAINT_FAILURE: 'SEND_REQUEST_SAVE_COMPLAINT_FAILURE',
  CLEAR_REQUEST_SAVE_COMPLAINT: 'CLEAR_REQUEST_SAVE_COMPLAINT',

  SEND_REQUEST_SIGNED_DOCUMENTS: 'SEND_REQUEST_SIGNED_DOCUMENT',
  SEND_REQUEST_SIGNED_DOCUMENTS_SUCCESS: 'SEND_REQUEST_SIGNED_DOCUMENTS_SUCCESS',
  SEND_REQUEST_SIGNED_DOCUMENTS_FAILURE: 'SEND_REQUEST_SIGNED_DOCUMENTS_FAILURE',
  CLEAR_REQUEST_SIGNED_DOCUMENTS: 'CLEAR_REQUEST_SIGNED_DOCUMENTS',

  SEND_REQUEST_GET_COORDINATES: 'SEND_REQUEST_GET_COORDINATES',
  SEND_REQUEST_GET_COORDINATES_SUCCESS: 'SEND_REQUEST_GET_COORDINATES_SUCCESS',
  SEND_REQUEST_GET_COORDINATES_FAILURE: 'SEND_REQUEST_GET_COORDINATES_FAILURE',
  CLEAR_REQUEST_GET_COORDINATES: 'CLEAR_REQUEST_GET_COORDINATES',

  SEND_REQUEST_KYC_UPDATION: 'SEND_REQUEST_KYC_UPDATION',
  SEND_REQUEST_KYC_UPDATION_SUCCESS: 'SEND_REQUEST_KYC_UPDATION_SUCCESS',
  SEND_REQUEST_KYC_UPDATION_FAILURE: 'SEND_REQUEST_KYC_UPDATION_FAILURE',
  CLEAR_REQUEST_KYC_UPDATION: 'CLEAR_REQUEST_KYC_UPDATION',
};
