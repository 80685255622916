import * as Yup from "yup";
import AuthBtnSpinner from "../../utils/AuthBtnSpinner";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import { HomePlaySvg, HomeAPPStore } from "../../assets/img";

import { LoginApi, GetUserDetails } from "../../api";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../utils/Modal";
import { useDispatch, useSelector } from "react-redux";
import { agreeTerms, clearAgreeTerms } from "../../../src/store/actions/agreeTermsAction"
import { RedirectUser } from "../../api/user/GetUserDetails";

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email"),
  pin: Yup.string().required("Pin is required"),
});

const Login = () => {
  const [errorMsg, seterrorMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const agreeTermsResponse = useSelector(state => state.agreeTermsReducer.data)

  if (searchParams.get("kyc")) {
    Cookies.set("redirectPath", searchParams.get("kyc"));
  }

  const [isApiSuccess, setIsApiSuccess] = useState(false);

  const {
    data: data,
    status: GetUserDetailsStatus,
    error: userError,
    isLoading: detailLoad,
    refetch,
  } = GetUserDetails({ isApiSuccess: isApiSuccess });

  console.log(isApiSuccess);

  const {
    mutateAsync: userData,
    status: loginStatus,
    isLoading,
  } = LoginApi();

  useEffect(() => {
    if (loginStatus === "success") {
      setIsApiSuccess(true);
      // alert('ppp');
    }
  }, [loginStatus]);

  useEffect(() => {
    if (isApiSuccess) {
      setOpen(data?.data?.isAdminLoggedin)
    }
  }, [data])

  const submitTerms = () => {
    dispatch(agreeTerms())
  }

  useEffect(() => {
    if (agreeTermsResponse != null && data != null) {
      if (Object.keys(agreeTermsResponse).length != 0 && agreeTermsResponse.statusCode == 200) {
        console.log("agreeTermsResponse", agreeTermsResponse)
        // console.log("data", data)
        setOpen(false)
        toast.success(agreeTermsResponse.message)

        const finalData = data.data;
        const fName = finalData.data.first_name
          ? finalData.data.first_name
          : "";
        const checkStatus = finalData.data.master_status_id[0]?.values[0]?.name;
        const checkUSerType = finalData.data.user_individual_type;
        if (checkStatus === "Approved" || fName !== "") {
          const saveUserData =   JSON.parse(Cookies.get("userDetail"))
  
          console.log("saveUserData", saveUserData)
          saveUserData.isAdminLoggedin = false;
          Cookies.set("userDetail", JSON.stringify(saveUserData));
          navigate("/dashboard");
          // console.log(Cookies.get("userDetail"));
        } else {
          const redi = RedirectUser({
            checkUSerType: checkUSerType,
            page: finalData.data.page ? finalData.data.page : 0,
          });
          console.log(redi);

          if (redi) {
            if (redi === '/choose-option') {
              navigate(redi, { replace: true })
            } else {
              navigate(redi);
            }
          }
        }
        dispatch(clearAgreeTerms())
      }
    }
  }, [agreeTermsResponse])

  return (
    <section className="mid-wrap pt-5 pb-5">
      <div className="mid-card-block">
        <div className="mxw-750">
          {/* <div className="logo-vr mb-4 text-center">
            <img src={logoVr} alt="Backrr" />
          </div> */}

          <h5 className="card-title lg-title mb-4 fw-700">
            Sign In To Continue
          </h5>
          <div className="card form-card">
            <div className="card-body">
              <Formik
                initialValues={{
                  email:
                    localStorage.getItem("userEmail") != null
                      ? localStorage.getItem("userEmail")
                      : "",
                  pin: "",
                  device_id: "web",
                  device_token: "web",
                  device_type: "web",
                  slug: Cookies.get("domainSlug")
                }}
                validationSchema={LoginValidationSchema}
                onSubmit={(values) =>
                  userData(values, {
                    onError: (error) => {
                      if (error.response) {
                        let res = error.response.data;
                        if (error.response.status === 403) {
                          seterrorMsg(res.message);
                          //  toast.error(res.message);
                        }
                        if (error.response.status === 500) {
                          toast.error("Internal Server Error");
                        } else {
                          toast.error(error.response.message);
                        }
                      }
                    },
                  })
                }
              >
                {({ errors, touched }) => (
                  <Form className="custom-form">
                    {/* Email */}
                    <div className="form-group">
                      <label>Email Address</label>

                      <Field
                        type="text"
                        className="form-control"
                        name="email"
                        autoComplete="off"
                      />

                      {errors.email && touched.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>

                    {/* Pin */}
                    <div className="form-group">
                      <label>PIN</label>

                      <Field
                        type="password"
                        className="form-control"
                        name="pin"
                        autoComplete="off"
                      />

                      {errors.pin && touched.pin && (
                        <small className="text-danger">{errors.pin}</small>
                      )}

                      {seterrorMsg && !errors.pin && !errors.email && (
                        <small className="text-danger">{errorMsg}</small>
                      )}
                    </div>
                    <div className="form-group text-end">
                      <Link
                        to="/forgot-pin"
                        className="blank-link text-decoration-underline"
                      >
                        Forget/Generate Pin
                      </Link>
                    </div>

                    {isLoading || detailLoad ? (
                      <AuthBtnSpinner />
                    ) : (
                      <button className="theme-btn btn w-100" type="submit">
                        Login
                      </button>
                    )}

                    {
                      <div className="sm-title mt-4">
                        Don't have an account ?
                        <div className="mt-3">
                          <Link to="/register-email">
                            <u>Become a Contributor</u>
                          </Link>
                        </div>
                      </div>
                    }
                  </Form>
                )}
              </Formik>
              <div className="text-center mt-30">
                <Link
                  to="https://www.realtimeaf.com/terms-of-use.html"
                  target="_blank"
                  className="white-btn w-360"
                >
                  Terms of use
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <div className="d-inline-flex align-items-center home-store-link">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.backrr"
                target="_blank"
                rel="noreferrer"
              >
                <img src={HomePlaySvg} alt="google play" />
              </a>
            </div>
            <div>
              <a
                href="https://apps.apple.com/in/app/backrr/id1661793028"
                target="_blank"
                rel="noreferrer"
              >
                <img src={HomeAPPStore} alt="app store" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal commonModalShow={open}
        commonModalClose={() => { setOpen(false) }}
        commonModalData={
          <>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <h5 className='md-title mb-0' id='documentModalLabel'>Terms of Use</h5>
            </div>
            <div className="terms-accept-body">
              <iframe src="https://www.realtimeaf.com/terms-of-use.html" width="100%" height="600" frameborder="0"></iframe>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="theme-btn px-4 terms-accept-btn" onClick={submitTerms}>I Agree</button>
            </div>
          </>
        }
      >
      </Modal>
    </section>
  );
};

export default Login;
