import { types } from './types';

export function getFeedbacksDetail(data) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACKS_DETAILS,
    payload: data,
  };
}

export function getFeedbacksDetailSuccess(data) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACKS_DETAIL_SUCCESS,
    payload: data,
  };
}

export function getFeedbacksDetailFailure(error) {
  return {
    type: types.SEND_REQUEST_GET_FEEDBACKS_DETAIL_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearGetFeedbacksDetail() {
  return {
    type: types.CLEAR_REQUEST_GET_FEEDBACKS_DETAILS,
    payload: {},
  };
}
