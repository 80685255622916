import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../views/main/deals/DashboardLayout'
import iconStatement from '../../assets/img/statement_of_account.png'
import backArrow from '../../assets/img/back.svg'
import { Link } from 'react-router-dom'
import { GetUserDetailsUpdated } from '../../api'
const StatementOfAccount = () => {
    const [profile_data, setProfileData] = useState([]);
    const [name, setName] = useState(null)

    const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();

    useEffect(() => {
        if (!isDocsLoading) {
            setProfileData(userData.data)
            let firstname = userData.data.first_name;
            let lastname = userData.data.last_name;
            let fullname = firstname + ' ' + lastname
            setName(fullname)
        }
    }, [userData]);

    return (
        <>
            <DashboardLayout>
                <div className='header-mobile d-md-none border-b'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <Link className="back-btn blue-text d-block" to="/dashboard">
                                <img src={backArrow} alt="arrow" /> Statement of Account
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="md-title text-start fw-700 cr-heading header-desktop">
                    Statement of Account
                </div>
                <div className='pt-5 mt-5 pb-5 text-center'>
                    <div className='mb-3 statement-icon'>
                        <img src={iconStatement} />
                    </div>
                    <div className='md-title text-center fw-700'>{name}</div>
                    {
                        profile_data?.soa ? <div className='mt-4'>
                            <button type='button' className='theme-btn' onClick={() => {window.open(profile_data?.soa, '_blank')}}>View Statement Of Account</button>
                        </div> : <div>
                            <div>Account statement not available currently.</div>
                            <div>Please email us at <span className='blue-text'><strong>office@realtimeaf.com</strong></span> for any queries.</div>
                        </div>
                    }
                </div>
            </DashboardLayout>
        </>
    )
}

export default StatementOfAccount