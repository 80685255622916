import { Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import backArrow from "../../../../assets/img/back.svg";
import * as Yup from "yup";
import BtnSpinner from '../../../../utils/BtnSpinner';
import { CustomInputComponent, CustomSelectComponent } from '../../../../components/formik/FieldComponents';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getStages } from '../../../../store/actions/getStagesAction';
import { getIndustriesTypes } from '../../../../store/actions/getIndustriesTypesAction';
import { clearGetExternalInvestment, getExternalInvestment } from '../../../../store/actions/getExternalInvestmentAction'
import Modal from '../../../../utils/Modal';
import { fetchExtension, fetchExtensionFromURL } from '../../../../utils/utils';
import pdfIcon from "../../../../assets/img/pdfn.png"
import imageIcon from "../../../../assets/img/image.png"
import { ViewIcon } from "../../../../assets/img"
import moment from 'moment';
import { clearUpdateExternalInvestment, updateExternalInvestment } from '../../../../store/actions/updateExternalInvestmentAction';
import { clearSaveExternalInvestment, saveExternalInvestment } from '../../../../store/actions/saveExternalInvestmentAction';
import { clearDeleteExternalIvestment, deleteExternalIvestment } from '../../../../store/actions/deleteExternalIvestmentAction'
import { toast } from 'react-toastify';

export default function AddInvestment() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { state } = useLocation();

    const [startupData, setStartupData] = useState(null)
    const [roundArray, setRoundArray] = useState([])
    const [startupId, setStartupId] = useState(null)

    const [selectedDocument, setSelectedDocument] = useState(null)
    const [selectedRound, setSelectedRound] = useState(null)

    const [sectorList, setSectorList] = useState([])
    const [stageList, setStageList] = useState([])
    const [otherSectorSelected, setOtherSectorSelected] = useState(false)

    const [document_data, documentData] = useState([]);
    const [open_document, setOpenDocument] = useState(false);

    const commonModalClose = () => setOpenDocument(false);

    const [udActive, setUdActive] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false)

    const deleteModalClose = () => setOpenConfirmDelete(false);

    const getIndustriesTypesResponse = useSelector(state => state.getIndustriesTypesReducer.data);
    const getStagesResponse = useSelector(state => state.getStagesReducer.data);
    const getExternalInvestmentResponse = useSelector(state => state.getExternalInvestmentReducer.data);
    const deleteExternalInvestmentResponse = useSelector(state => state.deleteExternalInvestmentReducer.data);
    const saveExternalInvestmentResponse = useSelector(state => state.saveExternalInvestmentReducer.data)
    const saveExternalInvestmentLoading = useSelector(state => state.saveExternalInvestmentReducer.loading)
    const updateExternalInvestmentResponse = useSelector(state => state.updateExternalInvestmentReducer.data)

    // console.log("syndicate_id", state?.syndicate_id)

    useEffect(() => {
        setStartupData(
            {
                startup_name: null,
                sectors: null,
                sectorsOther: "",
                stages: null,
                founder_name: null,
                founder_email: null
            }
        )
        dispatch(getIndustriesTypes())
        dispatch(getStages())
        if (state?.syndicate_id?.length > 0) {
            getStartupDetail()
        }
    }, [])

    const getStartupDetail = () => {
        console.log("syndicate id", state?.syndicate_id)
        setStartupId(state?.syndicate_id)
        dispatch(getExternalInvestment({ investment_id: state?.syndicate_id }))
    }

    useEffect(() => {
        if (getIndustriesTypesResponse != null) {
            console.log("getIndustriesTypesResponse", getIndustriesTypesResponse)
            if (getIndustriesTypesResponse.data != null) {
                let industriesList = getIndustriesTypesResponse.data;
                let formattedIndustriesList = [];
                for (let industry of industriesList) {
                    formattedIndustriesList.push({
                        label: industry.name,
                        value: industry.name,
                    });
                }
                formattedIndustriesList.push({
                    label: 'Other',
                    value: 'Other',
                });
                setSectorList(formattedIndustriesList);
            }
        }
    }, [getIndustriesTypesResponse]);

    useEffect(() => {
        if (getStagesResponse != null) {
            console.log("getStagesResponse", getStagesResponse)
            if (getStagesResponse.data != null) {
                let stagesList = getStagesResponse.data;
                let formattedStagesList = [];
                for (let stage of stagesList) {
                    formattedStagesList.push({
                        label: stage.value,
                        value: stage.value,
                    });
                }
                setStageList(formattedStagesList);
            }
        }
    }, [getStagesResponse]);

    useEffect(() => {
        if (getExternalInvestmentResponse != null) {
            console.log("getExternalInvestmentResponse", getExternalInvestmentResponse)
            if (getExternalInvestmentResponse?.data != null) {
                let startupData = getExternalInvestmentResponse?.data
                setStartupData(startupData)
                setRoundArray(startupData?.rounds)
                dispatch(clearGetExternalInvestment())
            }
        }
    }, [getExternalInvestmentResponse]);

    const startupValidation = {
        startup_name: Yup.string().required("Startup name is required"),
        sectors: Yup.string().required("Sector is required"),
        sectorsOther: Yup.string().when('sectors', {
            is: (sectors) => sectors?.trim() === 'Other',
            then: () => Yup.string().required("Sector is required"),
            otherwise: () => Yup.string()
        }),
        stages: Yup.string().required("Stage is required"),
        founder_name: Yup.string().required("Founder's name is required"),
        founder_email: Yup.string().required("Founder's email is required").email("Invalid email"),
    };

    const formik = useFormik({
        initialValues: startupData,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(startupValidation),
        onSubmit: (values, { setSubmitting }) => {
            console.log("values", values)
            editInvestment(values)
            setSubmitting(false)
        }
    })

    const docFile = (doc) => {
        let file = new File([doc], doc.title)
        return file;
    }

    const editInvestment = (values) => {
        let request = new FormData()

        request.append("startup_name", values.startup_name)
        request.append("founder_name", values.founder_name)
        request.append("founder_email", values.founder_email)
        request.append("sectors", values.sectorsOther != null ? values.sectorsOther : values.sectors)
        request.append("stages", values.stages)

        for (let [i, round] of roundArray.entries()) {
            request.append("rounds[" + i + "][round_name]", round.round_name)
            request.append("rounds[" + i + "][investment_date]", round.investment_date)
            request.append("rounds[" + i + "][syndicate_name]", round.syndicate_name)
            request.append("rounds[" + i + "][security_type]", round.security_type)
            request.append("rounds[" + i + "][no_of_units]", round.no_of_units)
            request.append("rounds[" + i + "][per_share_unit]", round.per_share_unit)
            request.append("rounds[" + i + "][investment_amount]", round.investment_amount)
            request.append("rounds[" + i + "][current_value_per_unit]", round.current_value_per_unit)
            request.append("rounds[" + i + "][current_investment_value]", round.current_investment_value)
            request.append("rounds[" + i + "][additional_notes]", round.additional_notes)

            if (round.documents?.length > 0) {
                for (let doc of round.documents) {
                    console.log("doc", doc)
                    let count = i
                    let countKey = 'round' + count
                    console.log("countKey ===", countKey)
                    request.append(countKey, docFile(doc))
                    // request.append(`${countKey}[${doc._id}]`, "")
                }
            }
        }

        for (let pair of request.entries()) {
            console.log(pair);
        }

        if (startupId != null) {
            console.log("startup_id", startupId)
            dispatch(updateExternalInvestment({ request: request, id: startupId }))
        } else {
            dispatch(saveExternalInvestment(request));
        }
    }

    useEffect(() => {
        if (saveExternalInvestmentResponse != null) {
            console.log("saveExternalInvestmentResponse", saveExternalInvestmentResponse)
            if (saveExternalInvestmentResponse?.data != null) {
                navigate('/portfolio')
                toast.success(saveExternalInvestmentResponse.message)
                dispatch(clearSaveExternalInvestment())
            }
        }
    }, [saveExternalInvestmentResponse])

    useEffect(() => {
        if (updateExternalInvestmentResponse != null) {
            console.log("updateExternalInvestmentResponse", updateExternalInvestmentResponse)
            if (updateExternalInvestmentResponse?.data != null) {
                navigate('/portfolio')
                toast.success(updateExternalInvestmentResponse.message)
                dispatch(clearUpdateExternalInvestment())
            }
        }
    }, [updateExternalInvestmentResponse])

    const handleOnDocumentClick = (data) => {
        console.log("document data", data)
        setOpenDocument(true)
        documentData(data)
    }

    const deleteRound = (round) => {
        dispatch(deleteExternalIvestment({ round_id: round._id }))
    }

    useEffect(() => {
        if (deleteExternalInvestmentResponse != null) {
            console.log("deleteExternalInvestmentResponse", deleteExternalInvestmentResponse)
            if (deleteExternalInvestmentResponse?.data != null) {
                if (deleteExternalInvestmentResponse?.data?.startup_deleted == 'Yes') {
                    navigate('/portfolio')
                } else {
                    getStartupDetail()
                }
                toast.success(deleteExternalInvestmentResponse.message)
                dispatch(clearDeleteExternalIvestment())
            }
        }
    }, [deleteExternalInvestmentResponse]);

    // console.log("formik", formik)

    return (
        <>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" to="/portfolio">
                            <img src={backArrow} alt="arrow" /> Add Investment
                        </Link>
                    </div>
                </div>
            </div>
            <div className="pt-4 pb-4 ps-5 pe-5">
                <div className="pt-0 pb-0 kyc-form-block pyp-block">
                    <FormikProvider
                        value={formik}
                        sx={{ flex: 1 }}
                    >
                        <Form className="custom-form" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <h5 className="card-title md-title mb-4 text-center fw-700">
                                    Startup Details
                                </h5>
                                {/* Startup Name */}
                                <div className="col-md-6 form-group">
                                    <label className="required">Startup Name</label>
                                    <Field
                                        name="startup_name"
                                        component={CustomInputComponent}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                {/* Sector */}
                                <div className="col-md-6 form-group">
                                    <label className="required">
                                        Sector
                                    </label>
                                    <Field
                                        name="sectors"
                                        component={CustomSelectComponent}
                                        className="form-control"
                                        options={sectorList}
                                        onChange={(e) => {
                                            formik.values.sectors = e.target.value
                                            setOtherSectorSelected(e.target.value == 'Other')
                                        }}
                                    />
                                    {
                                        otherSectorSelected && <div className='mt-3'> <Field
                                            name="sectorsOther"
                                            component={CustomInputComponent}
                                            type="text"
                                            placeholder="Enter a sector"
                                            className="form-control"
                                        />
                                        </div>
                                    }
                                </div>

                                {/* Stage */}
                                <div className="col-md-6 form-group">
                                    <label className="required">
                                        Stage
                                    </label>
                                    <Field
                                        name="stages"
                                        component={CustomSelectComponent}
                                        className="form-control"
                                        options={stageList}

                                    />
                                </div>

                                {/* Founder Name */}
                                <div className="col-md-6 form-group">
                                    <label className="required">Founder's Name</label>
                                    <Field
                                        name="founder_name"
                                        component={CustomInputComponent}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                {/* Founder Email Address */}
                                <div className="col-md-6 form-group">
                                    <label>Founder's Email</label>
                                    <Field
                                        name="founder_email"
                                        component={CustomInputComponent}
                                        type="email"
                                        className="form-control"
                                    />
                                </div>

                                {
                                    roundArray?.length > 0 && <div className='mt-3 mb-2'>Round Details</div>
                                }

                                {roundArray?.map((val, indx) => (
                                    <>
                                        <div className='portfolio-card-list'>
                                            <div className="accordion cs-accordion startup-acc-btn mt-2">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <div className="ps-3 py-1 accordion-button d-flex align-items-center justify-content-between">
                                                            <div>{val.round_name}</div>
                                                            <div className='dropdown cs-admin-dropdown'>
                                                                <button
                                                                    type="button"
                                                                    className="btn dropdown-toggle user-dropdown d-flex align-items-center ps-1 text-lgray" data-bs-toggle="dropdown" id="defaultDropdown" aria-expanded="false" data-bs-auto-close="true"
                                                                >
                                                                    <i class="bi bi-three-dots-vertical"></i>
                                                                </button>
                                                                <ul className={`dropdown-menu dropdown-menu-end ud-menu ${udActive == indx ? "show" : ""}`} aria-labelledby="defaultDropdown">
                                                                    <li>
                                                                        <button className="dropdown-item" type="button" onClick={() => {
                                                                            if (window.confirm(`Do you want to Delete ${val.round_name}`)) {
                                                                                deleteRound(val)
                                                                            }
                                                                            setSelectedRound(val);
                                                                            // setOpenConfirmDelete(true)
                                                                        }}>
                                                                            Delete
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button className="dropdown-item" type="button" onClick={() => {
                                                                            let startup_data = formik.values
                                                                            if (startupId?.length > 0) {
                                                                                startup_data = { ...startup_data, ...{ startup_id: startupId } }
                                                                            }
                                                                            navigate("/add-rounds", { state: { roundData: val, startupData: startup_data } })
                                                                        }}>
                                                                            Edit
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </h2>
                                                    <div className="accordion-collapse collapse show">
                                                        <div className="accordion-body">
                                                            <div className='row'>
                                                                <div className="col-6 col-md-4 mt-3">
                                                                    <div className="di-sub-label mb-1">Investment Date</div>
                                                                    <div className="di-sub-value"> {moment(val?.investment_date).format("DD-MMM-YYYY")} </div>
                                                                </div>
                                                                <div className="col-6 col-md-4 mt-3">
                                                                    <div className="di-sub-label mb-1">Security Type</div>
                                                                    <div className="di-sub-value">{val.security_type}</div>
                                                                </div>
                                                                <div className="col-6 col-md-4 mt-3">
                                                                    <div className="di-sub-label mb-1">Platform/Syndicate Name</div>
                                                                    <div className="di-sub-value">{val.syndicate_name}</div>
                                                                </div>
                                                            </div>
                                                            <div className='mt-4 pt-3 view-doc-box text-center' />
                                                            <div className='row' >
                                                                <div className="col-6 col-md-4 mt-3">
                                                                    <div className="di-sub-label mb-1">Investment Amount</div>
                                                                    <div className="di-sub-value">{val.investment_amount_ref}</div>
                                                                </div>
                                                                <div className="col-6 col-md-4 mt-3">
                                                                    <div className="di-sub-label mb-1">Value Per Share/Unit</div>
                                                                    <div className="di-sub-value">{val.per_share_unit_ref}</div>
                                                                </div>
                                                                <div className="col-6 col-md-4 mt-3">
                                                                    <div className="di-sub-label mb-1">Current Value Per Share/Unit</div>
                                                                    <div className="di-sub-value " > {val.current_value_per_unit_ref}</div>
                                                                </div>
                                                                <div className="col-6 col-md-4 mt-3">
                                                                    <div className="di-sub-label mb-1"> No. of Shares/Units</div>
                                                                    <div className="di-sub-value">{val.no_of_units}</div>
                                                                </div>
                                                            </div>
                                                            {val.documents.length > 0 && <div className='mt-3 pt-3 view-doc-box text-center'>
                                                                <button type="button" onClick={() => handleOnDocumentClick(val.documents)} className="btn btn-sm view-doc-btn">View Documents</button>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}

                                <div className="mt-4 mb-4 text-center">
                                    <button className="add-investment-btn" type="button" onClick={() => {
                                        let startup_data = formik.values
                                        if (startupId?.length > 0) {
                                            startup_data = { ...startup_data, ...{ startup_id: startupId } }
                                        }
                                        navigate("/add-rounds", { state: { startupData: startup_data } })
                                    }} disabled={!formik?.isValid || formik?.values?.startup_name == undefined} style={{ opacity: !formik?.isValid || formik?.values?.startup_name == undefined ? 0.5 : 1 }}><i className="bi bi-plus-circle-fill"></i> &nbsp;&nbsp;Add Rounds</button>
                                </div>
                            </div>
                            {saveExternalInvestmentLoading ? (
                                <BtnSpinner />
                            ) : (
                                <div className="mt-3 text-center">
                                    <button type="submit" className="theme-btn btn w-25" disabled={(!formik?.isValid || roundArray?.length == 0)}>
                                        {roundArray?.length > 0 ? "Edit Investment" : "Add Investment"}
                                    </button>
                                </div>
                            )}
                        </Form>
                    </FormikProvider>
                </div>
            </div>

            <Modal
                commonModalTitle="Documents"
                commonModalShow={open_document}
                commonModalClose={commonModalClose}
                commonModalData={
                    <>
                        <button className='btn-close close-pos-btn' type="button" onClick={commonModalClose}></button>
                        <ul className="document-list mt-2">
                            {document_data &&
                                document_data.length > 0 &&
                                document_data?.map((doc) => (
                                    <Link to={doc.url} target="_blank" key={doc.title}>
                                        <li className="d-flex align-items-center">
                                            <div className="hm-icon">
                                                <img src={fetchExtensionFromURL(doc.url) == 'pdf' || fetchExtensionFromURL(doc.url) == 'PDF' ? pdfIcon : imageIcon} alt="pdf" />
                                            </div>

                                            <div className="ml-15">
                                                <div className="md-title text-start">{doc.title}</div>
                                            </div>

                                            <div className="ml-auto">
                                                <span className="view-icon">
                                                    <img src={ViewIcon} alt="view" />
                                                </span>
                                            </div>
                                        </li>
                                    </Link>
                                ))}
                        </ul>
                    </>
                }
            />
        </>
    )
}
