import axios from 'axios';
import config from '../config';


/**
 * Axios client instance
 */
export const axiosClient = axios.create({
    baseURL: config.apiBaseURL,
});

/**
 * Get deal founder names
 *
 * @param {Array} foundersArr
 * @param {Boolean} isString
 */
export const getFounderNames = (foundersArr, isString = false) => {
    let founders = [];
    for (let founder of foundersArr) {
        founders.push(founder.name);
    }

    if (isString) return founders.join(', ');

    return founders;
};

/**
 * Get deal lead investor names
 *
 * @param {Array} leadInvestorsArr
 * @param {Boolean} isString
 */
export const getLeadInvestorNames = (leadInvestorsArr, isString = false) => {
    let leadInvestors = [];
    for (let leadInvestor of leadInvestorsArr) {
        leadInvestors.push(leadInvestor.name);
    }

    if (isString) return leadInvestors.join(', ');

    return leadInvestors;
};
