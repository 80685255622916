import { Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react'
import * as Yup from "yup";
import { CustomInputComponent } from '../formik/FieldComponents';

export default function InvestmentDetails(props) {
    const investmentValidation = {
        no_of_units: Yup.string().required("No. of Units is required"),
        per_share_unit: Yup.string().required("Per Share Unit is required"),
        investment_amount: Yup.string().required("Investment Amount is required"),
        current_value_per_unit: Yup.string().required("Current Value per Unit is required"),
        current_investment_value: Yup.string().required("Current Investment Value is required"),
    };

    const formik = useFormik({
        initialValues: props.roundData,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(investmentValidation),
        onSubmit: (values) => {
            props.handleSubmit(values)
        }
    })

    const formatter = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
    });

    const formatValueToCurrency = (amount) => {
        return formatter.format(amount)
    }

    useEffect(() => {
        if (formik.values.no_of_units != null && formik.values.per_share_unit != null) {
            console.log("noOfUnit", formik.values.no_of_units, " perShareUnit", formik.values.per_share_unit)
            let share = Number(formik.values.no_of_units)
            let value = Number(formik.values.per_share_unit)
            let investment = share * value
            investment = formatValueToCurrency(investment)
            formik.values.investment_amount = investment.toString()
        }
    }, [formik.values.no_of_units, formik.values.per_share_unit])

    useEffect(() => {
        if (formik.values.no_of_units != null && formik.values.current_value_per_unit != null) {
            console.log("noOfUnit", formik.values.no_of_units, " currentValuePerUnit", formik.values.current_value_per_unit)
            let share = Number(formik.values.no_of_units)
            let value = Number(formik.values.current_value_per_unit)
            let investment = share * value
            investment = formatValueToCurrency(investment)
            formik.values.current_investment_value = investment.toString()
        }
    }, [formik.values.no_of_units, formik.values.current_value_per_unit])

    return (
        <div className="pt-4 pb-4 ps-5 pe-5">
            <div className="pt-0 pb-0 kyc-form-block pyp-block">
                <FormikProvider
                    value={formik}
                    sx={{ flex: 1 }}
                >
                    <Form className="custom-form" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            {/* <h5 className="card-title md-title mb-4 text-center fw-700">
                                Investment Details
                            </h5> */}
                            {/* No of Units */}
                            <div className="col-md-6 form-group">
                                <label className="required">No. of Shares/Units</label>
                                <Field
                                    name="no_of_units"
                                    component={CustomInputComponent}
                                    type="number"
                                    className="form-control"
                                />
                            </div>

                            {/* Per Share Unit */}
                            <div className="col-md-6 form-group">
                                <label className="required">
                                    Value Per Share/Unit
                                </label>
                                <Field
                                    name="per_share_unit"
                                    component={CustomInputComponent}
                                    type="number"
                                    className="form-control"
                                />
                            </div>

                            {/* Investment Amount */}
                            <div className="col-md-6 form-group">
                                <label className="required">
                                    Investment Amount
                                </label>
                                <Field
                                    name="investment_amount"
                                    component={CustomInputComponent}
                                    type="text"
                                    disabled
                                    className="form-control"
                                />
                            </div>

                            {/* Current Value per Unit */}
                            <div className="col-md-6 form-group">
                                <label className="required">Current Value Per Share/Unit</label>
                                <Field
                                    name="current_value_per_unit"
                                    component={CustomInputComponent}
                                    type="number"
                                    className="form-control"
                                />
                            </div>

                            {/* Current Investment Value */}
                            <div className="col-md-6 form-group">
                                <label className="required">
                                    Current Investment Value
                                </label>
                                <Field
                                    name="current_investment_value"
                                    component={CustomInputComponent}
                                    type="text"
                                    disabled
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="mt-3 text-center">
                            <button type="submit" className="theme-btn btn w-25" disabled={!formik?.isValid || formik?.values?.no_of_units == undefined}>
                                Next
                            </button>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </div>
    );
}
