import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../utils/BtnSpinner";
import {
  CustomInputComponent,
  CustomTextareaComponent,
} from "../../../components/formik/FieldComponents";
import Cookies from "js-cookie";
import { SaveContactUsInfo } from "../../../api";
import { useState, useEffect } from 'react';
import DashboardLayout from "../deals/DashboardLayout";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import backArrow from '../../../assets/img/back.svg'
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../store/actions/getUserInfoAction";



function ContactUs() {

  const [userDetail, setUserDetails] = useState(null)

  const dispatch = useDispatch()

  const getUserInfoResponse = useSelector(state => state.getUserInfoReducer.data);

  const LoginValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone_number: Yup.string().required("Phone number is required"),
    email_address: Yup.string()
      .required("Email is required")
      .email("Must be a valid email"),
    message: Yup.string().required("Message is required"),
  });

  useEffect(() => {
    dispatch(getUserInfo());
  }, [])

  useEffect(() => {
    if (getUserInfoResponse != null) {
      if (getUserInfoResponse.data != null) {
        let firstname = getUserInfoResponse.data.first_name;
        let middlename = getUserInfoResponse.data.middle_name;
        let lastname = getUserInfoResponse.data.last_name;
        let fullname = null;
        if (middlename == undefined) {
          fullname = firstname + ' ' + lastname
        } else {
          fullname = firstname + ' ' + middlename + ' ' + lastname;
        }
        setUserDetails({
          fullName: fullname,
          mob: getUserInfoResponse.data.mobile_number,
          email: getUserInfoResponse.data.email,
        })
      }
    }
  }, [getUserInfoResponse]);

  const [successMsg, setSuccessMsg] = useState(false);
  const { mutateAsync: saveContacts, isLoading } = SaveContactUsInfo();


  return (
    <DashboardLayout>

      <div className="md-title text-start fw-700 cr-heading header-desktop">
        Contact Us
      </div>
      <div className='header-mobile d-md-none border-b'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <Link className="back-btn blue-text d-block" to="/dashboard">
              <img src={backArrow} alt="arrow" /> Contact Us
            </Link>
          </div>
        </div>
      </div>

      <div className="pt-4 pb-4 ps-5 pe-5">
        <Formik
          enableReinitialize
          initialValues={{
            name: userDetail?.fullName,
            phone_number: userDetail?.mob,
            email_address: userDetail?.email,
            message: "",
          }}
          validationSchema={LoginValidationSchema}
          onSubmit={(values) =>
            saveContacts({ payload: values }, {
              onSuccess: (data) => {
                console.log("data", data)
                toast.success(data.data.message)
                setSuccessMsg(true);

              }
            })
          }
        >
          {({ errors, touched }) => (
            // Contact Us Form
            <Form className="custom-form">
              <div className="row">
                {/* First Name */}
                <div className="col-md-6 form-group">
                  <label>Name</label>
                  <Field
                    name="name"
                    component={CustomInputComponent}
                    type="text"
                    disabled={true}
                    className="form-control"
                  />
                </div>

                {/* Email */}
                <div className="col-md-6 form-group">
                  <label>Email</label>
                  <Field
                    name="email_address"
                    component={CustomInputComponent}
                    type="text"
                    disabled={true}
                    className="form-control"
                  />
                </div>

                {/* Email */}
                <div className="col-md-6 form-group">
                  <label>Mobile Number</label>
                  <Field
                    name="phone_number"
                    component={CustomInputComponent}
                    type="text"
                    disabled={true}
                    className="form-control"
                  />
                </div>

                {/* Message */}
                <div className="col-12 form-group">
                  <label>Message</label>
                  <Field
                    name="message"
                    component={CustomTextareaComponent}
                    type="email"
                    className="form-control"
                  />
                </div>
              </div>
              {isLoading ? (
                <BtnSpinner />
              ) : (
                <div className="mt-3 text-center">
                  <button type="submit" className="theme-btn btn w-25">
                    Send
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      {/* //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section> */}
    </DashboardLayout>
  );
}

export default ContactUs;
