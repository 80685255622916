import {types} from './types';

export function changePIN(data) {
  return {
    type: types.SEND_REQUEST_CHANGE_PIN,
    payload: data,
  };
}
export function changePINSuccess(data) {
  return {
    type: types.SEND_REQUEST_CHANGE_PIN_SUCCESS,
    payload: data,
  };
}

export function changePINFailure(error) {
  return {
    type: types.SEND_REQUEST_CHANGE_PIN_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearChangePIN() {
  return {
    type: types.CLEAR_REQUEST_CHANGE_PIN,
    payload: {},
  };
}
