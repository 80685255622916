import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../utils/BtnSpinner";
import {
  CustomRadioComponent,
} from "../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_USER_TYPE } from '../../../store/actions';
import { SaveChooseOption } from '../../../api'

const PersonalInformationValidationSchema = Yup.object().shape({
  choosenOption: Yup.string().required("Choose at least one option"),
});

function PersonalInformation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);

  const { mutateAsync: saveOption } = SaveChooseOption()

  const handleSubmit = (values) => {

    dispatch({ type: SET_USER_TYPE, payload: values.choosenOption })
    saveOption({
      payload: {
        user_individual_type: values.choosenOption,
        page: "0",
      }
    })
    setTimeout(() => {
      if (values.choosenOption) {
        navigate("/personal-information")
      }
    }, 300)

  };

  return (
    <section className="mid-wrap">
      <div className="forms-header pt-3 pb-3 choose-option-heading">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2"></div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Complete Your KYC</div>
            </div>
            <div className="col-md-2 col-2 text-end"></div>
          </div>
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4 mxw-460 choose-option-box">
            <div className="card-body">
              <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                What Describe's You Best?
              </h5>
              <Formik
                initialValues={{
                  choosenOption: userDetails.userType,
                }}
                validationSchema={PersonalInformationValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="custom-form">
                    <div className="row">
                      {/* Choose Option */}
                      <div className="col-md-12 form-group choose-option">
                        <Field
                          name="choosenOption"
                          component={CustomRadioComponent}
                          className="form-group"
                          options={[
                            {
                              label: "INDIAN CITIZEN",
                              value: "indian_citizen",
                            },
                            {
                              label: "Indian HUF",
                              value: "huf",
                            },
                            {
                              label: "Indian Corporate/Firm",
                              value: "indian_corporate",
                            },
                            {
                              label: "FOREIGN CITIZEN",
                              value: "foreign_citizen",
                            },
                            {
                              label: "FOREIGN CORPORATE",
                              value: "foreign_corporate",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    {false ? (
                      <div className="mt-3 text-center">
                        <BtnSpinner />
                      </div>
                    ) : (
                      <div className="mt-3 text-center">
                        <button type="submit" className="theme-btn btn w-75">
                          Next
                        </button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PersonalInformation;
