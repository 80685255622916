import { useQuery } from 'react-query';
import { axiosClient } from '../utils/helpers';
import axios from 'axios';
import Cookies from 'js-cookie'

export const FetchAcountTypes = ({accountType}) => {
  return useQuery(['fetch-account-types',accountType], 
    async() => {
      return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/master/account_type?user_individual_type=${accountType}`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        },
      }) 
  }, {
    select: (data)=>data.data.data.map((account)=>({label: account.value, value: account._id})),
    refetchOnWindowFocus:false,
  })
}

export const GetBankAccountDetails = () => {
    return useQuery(
        ['get-bank-accounts'],
        async () => {
            return await axiosClient.get('/setting/bank-accounts/realtime?page=1');
        },
        {
            select: (data) => {
                return data.data.data;
            },
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    );
};


export const GetDealBankAccountDetails = ({dealId}) => {
  return useQuery(
      ['get-deal-bank-accounts',dealId],
      // async () => {
      //     return await axiosClient.get(`/setting/bank-accounts/deal/${dealId}`);
      // },
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/setting/bank-accounts/deal/${dealId}`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          },
        }) 
      },
      {
          select: (data) => {
              return data.data.data;
          },
          refetchOnWindowFocus: false,
         // staleTime: Infinity,
      },
  );
};

export const FetchMoreAboutYouQuestions = () => {
    return useQuery(
      ['fetch-more-about-you-questions'], 
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/admin/master/more_about_you`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          },
        }) 
    },{
      select: (data)=>data.data.data.map((question)=>{
        if(question.values[0].type==="checkbox"){
          question.values[0]["answer"]={answer: []}
        }else{
          question.values[0]["answer"]={answer: ''}
        }
        return question
      }),
      refetchOnWindowFocus:false,
    //   retry: (failureCount, error)=>{
    //     return RetryOnError(failureCount, error)
    //   },
    //   onError: (err)=>{
    //     return verifyToken(err)
    //   },
    })
  }

  export const FetchAllPersonalisedQuestions = () => {
    return useQuery(
      ['fetch-all-personalised-questions'], 
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/admin/master/personalize_profile`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          },
        }) 
    },{
      select: (data)=>data.data.data.map((question)=>{
        if(question.values[0].type==="checkbox"){
          question.values[0]["answer"]={answer: []}
        }else{
          question.values[0]["answer"]={answer: ''}
        }
        return question
      }),
      refetchOnWindowFocus:false,
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      // onError: (err)=>{
      //   return verifyToken(err)
      // },
    })
  }



  export const FetchAllCorporateTypes = () => {
    return useQuery(
      ['fetch-all-corporates-types'], 
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/master/corporate_type`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          },
        }) 
    },{
      select: (data) => {
        var finalData = data.data.data;
        var newArr = [];
        newArr = finalData.map((returnData)=>{
          return {'value': returnData._id,'label':returnData.values[0]['name']} ;
        })

        return newArr;
 
      },
      refetchOnWindowFocus:false,
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      // onError: (err)=>{
      //   return verifyToken(err)
      // },
    })
  }



  export const FetchAllIndustries = () => {
    return useQuery(
      ['fetch-all-corporates-types'], 
      () => {
        return axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/master/industries/get_all`,
          headers: { 
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          },
        }) 
    },{
      select: (data) => {
        var finalData = data.data.data;
       // console.log(finalData);

        var newArr = [];
        newArr = finalData.map((returnData)=>{
          return {'value': returnData.name,'label':returnData.name} ;
        })

        return newArr;
 
      },
      refetchOnWindowFocus:false,
      
    })
  }