import React, { useEffect, useState } from "react";
import DashboardLayout from '../../deals/DashboardLayout'
import arrowRight from '../../../../assets/img/right-arrow.svg'
import { VictoryPie } from 'victory';
import Modal from '../../../../utils/Modal';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment';
import { Pdf, ViewIcon } from "../../../../assets/img";
import { useDispatch, useSelector } from "react-redux";
import { getConstants } from "../../../../utils/constants";
import backArrow from '../../../../assets/img/back.svg'
import { getMyAllBackrrsPortfolio } from '../../../../store/actions/getMyAllBackrrsPortfolioAction'
import { GetDealLists } from "../../../../api";

const colorArray = [
    "#ff9062",
    "#6161ff",
    "#6edf87",
    "#e36464",
    "#d49066",
    "#d4be6a",
    "#c2d46a",
    "#90d98b",
    "#84d1a3",
    "#85ccbd",
    "#84b6e3",
    "#9b85de",
    "#c985de",
    "#de85a7",
    "#48f080",
    "#c1f55f",
    "#c7bf69",
    "#e3b166",
    "#2ec799",
]

const AllBackrrPortfolio = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { state } = useLocation()

    const [open, setOpen] = useState(false);
    const filterBtn = () => {
        setOpen(true)
    }
    const closeFilter = () => {
        setOpen(false)
    }
    const [percentage, SetPercentageValues] = useState(false);
    const [sector_percentage, SetSectorPercentageValues] = useState(false);
    const [stages_percentage, SetStagePercentageValues] = useState(false);


    const [sectorArray, setSectorArray] = useState([]);
    const [fundArray, setFundArray] = useState([]);
    const [sector_name, setSectorValue] = useState('');
    const [syndicate_name, setSyndicateValue] = useState('');
    const [document_data, documentData] = useState([]);
    const [open_document, setOpenDocument] = useState(false);
    const [investment_data, setInvestmentData] = useState([]);
    const [showInfo, setShowInfo] = useState(false)
    const [infoMessage, setInfoMessage] = useState(null)

    const [udActive, setUdActive] = useState(null);
    const [selectedInvestment, setSelectedInvestment] = useState(null)

    const commonModalClose = () => setOpenDocument(false);
    const closeInfoModal = () => setShowInfo(false);


    const [filterApplied, setFilterApplied] = useState(false)
    const [selectedSort, setSelectedSort] = useState("Null")


    const portfolioResponse = useSelector(state => state.getMyAllBackrrsPortfolioReducer.data)
    const portfolioLoader = useSelector(state => state.getMyAllBackrrsPortfolioReducer.loading)

    const {
        data: listData
    } = GetDealLists({
        listId: "all-deal",
        page: 1,
    });

    const dealCount = listData ? listData.data.length : 0

    useEffect(() => {
        fetchPortfolio()
    }, [])

    const fetchPortfolio = () => {
        let sector = null
        let fund = null
        if (portfolioResponse?.data?.investments?.length > 0) {
            sector = sector_name
            fund = syndicate_name
        } else {
            setSectorValue(null)
            setSyndicateValue(null)
            setFilterApplied(false)
        }
        filterInvestments(sector, fund)
    }

    useEffect(() => {
        if (!portfolioLoader) {
            setInvestmentData(portfolioResponse?.data?.investments)
            // setInfoMessage(
            //     {
            //         html: `
            // <h1 style='font-size: 16px; font-family: Lato-Bold; color: #333333; margin-bottom: 0px; padding-bottom: 0px;'><b>Unrealised Gain:</b></h1>
            // <p style='font-size: 15px; font-family: Lato-Regular; color: #333333; padding: 0px; margin-top: 5px;'>The term “unrealised gain” refers to the increase in the value of an investment or asset that has not been sold or realised yet.</p>
            // <h1 style='font-size: 16px; font-family: Lato-Bold; color: #333333; margin-bottom: 0px; margin-top: 20px; padding-bottom: 0px;'><b>Unrealised Loss:</b></h1>
            // <p style='font-size: 15px; font-family: Lato-Regular; color: #333333; padding: 0px; margin-top: 5px;'>The term “unrealised loss” refers to the decrease in the value of an investment or asset that has not been sold or realised yet.</p>
            // <p style='font-size: 15px; font-family: Lato-Regular; color: #333333; padding: 0px; margin-top: 20px;'>The formula to calculate Unrealised Gain and Loss is as follows:</p>
            // <p style='font-size: 15px; font-family: Lato-Regular; color: #333333; padding: 0px; margin-top: 10px;'><b>Unrealised Gain =</b> Current Market Value - Original Cost</p>
            // <p style='font-size: 15px; font-family: Lato-Regular; color: #333333; padding: 0px; margin-top: 10px;'><b>Unrealised Loss =</b> Original Cost - Current Market Value</p>
            // `
            //     }
            // )
            setInfoMessage({
                html: `${getConstants()?.PORTFOLIO_INFO}`
            })
        }
    }, [portfolioLoader]);

    useEffect(() => {
        console.log("portfolioResponse", portfolioResponse)
        if (!portfolioLoader) {
            if (portfolioResponse.data != null) {
                const percentages = portfolioResponse?.data?.investments?.map(startup => parseFloat(startup.percentage));
                const formattedPercentages = percentages?.map(percentage => ({
                    x: `${percentage.toFixed(2)}%`, // Format as "XX.XX%"
                    y: percentage,
                }));

                SetPercentageValues(formattedPercentages)

                const sector_percentages = portfolioResponse?.data?.sectors?.map(startup => parseFloat(startup.percentage));
                const formatted_sector_Percentages = sector_percentages?.map(percentage => ({
                    x: `${percentage.toFixed(2)}%`, // Format as "XX.XX%"
                    y: percentage,
                }));

                SetSectorPercentageValues(formatted_sector_Percentages)

                const stage_percentages = portfolioResponse?.data?.stages?.map(startup => parseFloat(startup.percentage));
                const formatted_stage_Percentages = stage_percentages?.map(percentage => ({
                    x: `${percentage.toFixed(2)}%`, // Format as "XX.XX%"
                    y: percentage,
                }));

                SetStagePercentageValues(formatted_stage_Percentages)

                if (syndicate_name == "" && sector_name == "") {
                    setFilterApplied(false)
                    let sectors = []
                    let funds = []
                    let allSyndicates = []
                    for (let investment of portfolioResponse.data.investments) {
                        if (investment?.sector_name?.trim()?.length > 0) {
                            if ((sectors.filter(e => e.label === investment.sector_name).length <= 0)) {
                                sectors.push({ label: investment.sector_name, value: investment.sector_name })
                            }
                        }
                        for (let deal of investment.deals) {
                            allSyndicates = [...allSyndicates, ...deal.allsyndicateName]
                        }
                    }
                    for (let syndicate of allSyndicates) {
                        if ((funds.filter(e => e.label == syndicate).length <= 0)) {
                            funds.push({ label: syndicate, value: syndicate })
                        }
                    }
                    sectors = sectors.sort((a, b) => a?.label?.localeCompare(b.label))
                    funds = funds.sort((a, b) => a?.label?.localeCompare(b.label))
                    setSectorArray(sectors)
                    setFundArray(funds)
                }
            }
        }

        if (!portfolioLoader) {
            setInvestmentData(portfolioResponse?.data?.investments)
        }

    }, [portfolioResponse]);


    const handleSyndicateChange = (event) => {
        setSyndicateValue(event.target.value)
        setSelectedSort("Null")

    }
    const handleSectorChange = (event) => {
        setSectorValue(event.target.value)
        setSelectedSort("Null")
    }

    const filterInvestments = (sector, fund) => {
        let sectorName = ''
        let fundName = ''
        if (sector != null) {
            if (sector != 'All') {
                sectorName = sector
            }
            setFilterApplied(true)
        }
        if (fund != null) {
            if (fund != 'All') {
                fundName = fund
            }
            setFilterApplied(true)
        }
        dispatch(getMyAllBackrrsPortfolio({ sector_name: sectorName, syndicate_name: fundName }))
        setSelectedSort(null)
        setOpen(false);
    }

    const handleSortChange = event => {
        setSelectedSort(event.target.value);
        console.log("sort value", event.target.value)
        if (event.target.value != "Null") {
            let partnersArray = portfolioResponse?.data?.investments
            let sortedPartners = partnersArray
            console.log("partnersArray", partnersArray)
            switch (event.target.value) {
                case 'Returns - Low to High': {
                    sortedPartners = partnersArray.sort((a, b) => a.w_gain_value - b.w_gain_value)
                    break;
                }
                case 'Returns - High to Low': {
                    sortedPartners = partnersArray.sort((a, b) => b.w_gain_value - a.w_gain_value)
                    break;
                }
                case 'Investment - Low to High': {
                    sortedPartners = partnersArray.sort((a, b) => a.w_amount - b.w_amount)
                    break;
                }
                case 'Investment - High to Low': {
                    sortedPartners = partnersArray.sort((a, b) => b.w_amount - a.w_amount)
                    break;
                }
                case 'Oldest Investment': {
                    sortedPartners = partnersArray.sort((a, b) => moment(a.last_investment_date) - moment(b.last_investment_date))
                    break;
                }
                case 'Recent Investment': {
                    sortedPartners = partnersArray.sort((a, b) => moment(b.last_investment_date) - moment(a.last_investment_date))
                    break;
                }

            }

            const percentages_r = sortedPartners?.map(startup => parseFloat(startup.percentage));
            const formattedPercentage_rs = percentages_r?.map(percentage => ({
                x: `${percentage.toFixed(2)}%`, // Format as "XX.XX%"
                y: percentage,
            }));

            SetPercentageValues(formattedPercentage_rs)

            setInvestmentData(sortedPartners)

        } else {
            setInvestmentData(portfolioResponse?.data?.investments)

        }


    };

    function submitFilter() {
        // setSectorValueApi(sector_name)
        // setSyndicateValueApi(syndicate_name)
        filterInvestments(sector_name, syndicate_name)
        setOpen(false)
    }

    function handleOnDocumentClick(data) {
        setOpenDocument(true)
        documentData(data)
    }

    return (
        <>
            <DashboardLayout>
                <div className='header-mobile d-md-none border-b'>
                    <div className='d-flex align-items-center'>
                        <button className="back-btn blue-text" type="button" onClick={() => { navigate("/my-backrrs", { state: state }) }}><img src={backArrow} alt="arrow" className='d-inline-block' /></button>
                        <div className='back-btn blue-text'>My Backrrs Portfolio</div>
                    </div>
                </div>
                <div className='addround-header header-desktop'>
                    <div className='container'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <div className="md-title text-start fw-700 cursor-pointer d-flex align-items-center">
                                    <button className="back-btn" type="button" onClick={() => { navigate("/my-backrrs", { state: state }) }}><img src={backArrow} alt="arrow" className='d-inline-block' /></button>
                                    <div className='pt-1 blue-text'>My Backrrs Portfolio</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    portfolioResponse?.data?.investments?.length > 0 || portfolioResponse?.data?.pending_investments?.length > 0 ?
                        <div className="pt-4 pb-4 ps-5 pe-5">
                            {
                                portfolioResponse?.data?.investments?.length > 0 ?
                                    <div>
                                        <div className='row mt-3 align-items-center'>

                                            <div className='col-12 blue-box'>
                                                <div className='row'>
                                                    <div className={portfolioResponse?.data?.unallocated_amount != null && portfolioResponse?.data?.unallocated_amount != "0" ? 'col-md-4 col-sm-12 col-12 p-3 first-box' : 'col-md-6 col-sm-12 col-12 p-3 first-box'}>
                                                        <div className=''>
                                                            <div className='lg-title text-center text-white mt-2'>₹{portfolioResponse?.data?.currentValue}</div>
                                                            <div className='xs-title text-center text-white mt-1'>Current Value</div>
                                                        </div>
                                                    </div>
                                                    <div className={portfolioResponse?.data?.unallocated_amount != null && portfolioResponse?.data?.unallocated_amount != "0" ? 'col-md-4 col-sm-6 col-6 p-3 second-box' : 'col-md-6 col-sm-6 col-12 p-3'} style={{ borderLeft: "1px dashed rgb(255 255 255 / 40%)" }}>
                                                        <div className=''>
                                                            <div className='lg-title text-center text-white mt-2'>₹{portfolioResponse?.data?.investment_amount}</div>
                                                            <div className='xs-title text-center text-white mt-1'>Invested Amount</div>
                                                        </div>
                                                    </div>
                                                    {portfolioResponse?.data?.unallocated_amount != null && portfolioResponse?.data?.unallocated_amount != "0" && <div className='col-md-4 col-sm-6 col-6 third-box p-3' style={{ borderLeft: "1px dashed rgb(255 255 255 / 40%)" }}>
                                                        <div className=''>
                                                            <div className='lg-title text-center text-white mt-2'>₹{portfolioResponse?.data?.unallocated_amount}</div>
                                                            <div className='xs-title text-center text-white mt-1'>Unallocated Amount</div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className='col-12 text-center mt-4'>
                                                <div className='mxw-300'>
                                                    <svg viewBox="0 0 250 250">
                                                        <VictoryPie
                                                            standalone={false}
                                                            width={250} height={250}
                                                            data={percentage}
                                                            innerRadius={55} labelRadius={85}
                                                            padAngle={2}
                                                            cornerRadius={3}
                                                            colorScale={colorArray}
                                                            style={{ labels: { fontSize: 11, fill: "black" } }}
                                                            labels={({ datum }) => { return datum.y > 5 ? datum.y % 1 > 0 ? (parseFloat(datum.y).toFixed(2) + "%") : (parseFloat(datum.y).toFixed(0) + "%") : null }}
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3 mt-4 filter-selector">
                                            <div>All Investments ({portfolioResponse?.data?.investments.length})</div>
                                            <div className='ml-auto custom-form'>
                                                <select className='form-control cursor-pointer' value={selectedSort} onChange={handleSortChange}>
                                                    <option value="Null">Sort By</option>
                                                    <option value="Returns - High to Low">High to Low Returns</option>
                                                    <option value="Returns - Low to High">Low to High Returns</option>
                                                    <option value="Investment - High to Low">High to Low Investment</option>
                                                    <option value="Investment - Low to High">Low to High Investment</option>
                                                </select>
                                            </div>
                                            <div>
                                                <button type='button' className='filter-btn' style={{ color: filterApplied && '#FF6624' }} onClick={filterBtn}>
                                                    <i className={filterApplied ? "bi bi-funnel-fill" : "bi bi-funnel"}></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='portfolio-card-list accordion' id="accordionExample">
                                            {investment_data?.map((item, index) => (
                                                <div className='accordion-item  portfolio-card' style={{ borderLeftColor: colorArray[index] }} key={item.startup_id}>
                                                    <h2 class="accordion-header" id={`heading${index}`}>
                                                        <button class="accordion-button flex-wrap collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse1${index}`} aria-expanded="false" aria-controls={`collapse1${index}`}>
                                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                                <div className="d-flex align-items-center">
                                                                    {
                                                                        item.external ?
                                                                            <div className="circle-box circle-text" style={{ backgroundColor: colorArray[index] }}>
                                                                                {/* <img src={item.imageURL} /> */}
                                                                                <span className="ct-name">{item?.company_name?.split(" ")[0]?.substring(0, 1)}{item?.company_name?.split(" ")[1]?.substring(0, 1)}</span>
                                                                            </div>
                                                                            :
                                                                            (item.imageURL ? <div className="circle-box circle-text">
                                                                                <img src={item.imageURL} />
                                                                            </div> : <div className="circle-box circle-text" style={{ backgroundColor: colorArray[index] }}>
                                                                                <span className="ct-name">{item?.company_name?.split(" ")[0]?.substring(0, 1)}{item?.company_name?.split(" ")[1]?.substring(0, 1)}</span>
                                                                            </div>)
                                                                    }
                                                                    <div className="ms-2">
                                                                        <div className="md-title mb-0 text-start font-1em fw-700">{item.company_name}</div>
                                                                        <div className="sm-title font-0-8em text-start black-text mt-1">{item.sector_name}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='ml-auto'>
                                                                    <div className="sm-title font-0-7em text-end blank-link fw-500">Value (Invested)</div>
                                                                    <div className="sm-title font-0-8em text-end blank-link mt-1 fw-700"><span className='black-text'>{item.current_value}</span> ({item.amount})</div>
                                                                </div>
                                                                <div className="hm-arrow ms-3"><img src={arrowRight} alt="arrow" /></div>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between w-100 mt-2">
                                                                <div className='font-12 syndicate-name-tag'>{item.syndicate_name}</div>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id={`collapse1${index}`} class="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <div className="d-flex align-items-center justify-content-between w-100 mb-2">
                                                                <div>&nbsp;</div>
                                                                <div className="d-flex align-items-center">
                                                                    <div><span className="font-14" style={{ color: item.gain_color_code }}>{item?.gain_value} {item?.gain_percentage?.trim()?.length > 0 && "(" + item?.gain_percentage + ")"}</span></div>
                                                                    <button className="info-btn text-lgray font-16" onClick={() => { setShowInfo(true) }}><i class="bi bi-info-circle"></i></button>
                                                                </div>
                                                            </div>
                                                            <div className='xs-title fw-700'>You have invested in following deals/schemes of this startup</div>
                                                            {item?.deals?.map((val, indx) => (
                                                                <>  {!item.external ? <div class="accordion cs-accordion mt-2">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header">
                                                                            <button class="accordion-button collapsed d-flex align-items-center justify-content-between" type="button">
                                                                                <div onClick={() => { navigate("/deal-details?slug=" + val.random_deal_id) }}><u>{val.scheme_name}</u></div>
                                                                                <div className='font-14 gray-text'> {moment(val.deal_completed_date).format("DD-MMM-YYYY")}</div>
                                                                            </button>
                                                                        </h2>
                                                                        <div class="accordion-collapse collapse show">
                                                                            <div class="accordion-body">
                                                                                <div className='row'>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Face Value</div>
                                                                                        <div className="di-sub-value">{val.face_value}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Nav</div>
                                                                                        <div className="di-sub-value">{val.current_unit_value}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Invested</div>
                                                                                        <div className="di-sub-value">{item?.amount}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Value</div>
                                                                                        <div className="di-sub-value">{val.gain_value}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Returns</div>
                                                                                        <div className="di-sub-value " style={{ color: val.gain_color_code }} > {val.gain_value} {val.gain_percentage != "" ? "(" + val.gain_percentage + ")" : ""}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Units</div>
                                                                                        <div className="di-sub-value">{val.unit_alloted}</div>
                                                                                    </div>
                                                                                </div>
                                                                                {val.documents.length > 0 && <div className='mt-3 pt-3 view-doc-box text-center'>
                                                                                    <button onClick={() => handleOnDocumentClick(val.documents)} className="btn btn-sm view-doc-btn">View Documents</button>
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : <div class="accordion cs-accordion mt-2">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header">
                                                                            <button class="accordion-button collapsed d-flex align-items-center justify-content-between" type="button">
                                                                                <div>{val.scheme_name}</div>
                                                                                <div className='font-12 text-white ah-status-tag'> {val?.syndicate_name}</div>
                                                                            </button>
                                                                        </h2>
                                                                        <div class="accordion-collapse collapse show">
                                                                            <div class="accordion-body">
                                                                                <div className='row'>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Investment Date</div>
                                                                                        <div className="di-sub-value"> {moment(val?.last_investment_date).format("DD-MMM-YYYY")} </div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Security Type</div>
                                                                                        <div className="di-sub-value">{val.security_type}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Investment Amount</div>
                                                                                        <div className="di-sub-value">{val.investment_amount}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Value Per Share/Unit</div>
                                                                                        <div className="di-sub-value">{val.face_value}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1">Current Value Per Share/Unit</div>
                                                                                        <div className="di-sub-value " > {val.current_unit_value}</div>
                                                                                    </div>
                                                                                    <div className="col-6 col-md-4 mt-3">
                                                                                        <div className="di-sub-label mb-1"> No. of Shares/Units</div>
                                                                                        <div className="di-sub-value">{val.unit_alloted}</div>
                                                                                    </div>
                                                                                </div>
                                                                                {val.documents.length > 0 && <div className='mt-3 pt-3 view-doc-box text-center'>
                                                                                    <button onClick={() => handleOnDocumentClick(val.documents)} className="btn btn-sm view-doc-btn">View Documents</button>
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>}</>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div> :
                                    <div className="no-portfolio-box position-relative">
                                        <div className="no-portfolio-pos">
                                            <div className="sm-title text-center mb-3">Your portfolio will be shown here!</div>
                                            <div className="md-title text-center">Start building your portfolio</div>
                                            <div className="text-center mt-4">
                                                {dealCount > 0 && <div>
                                                    <button className="theme-btn px-4" type="button" onClick={() => { navigate("/deal-list?deal-id=all-deal") }}>View Deals</button>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                portfolioResponse?.data?.pending_investments?.length > 0 && <div className="portfolio-card mb-3">
                                    <div className='xs-title fw-700 mb-2'>Funds received for In-Progress deals</div>
                                    <div className="custom-table pfr-table">
                                        <table className="w-100">
                                            <thead>
                                                <tr>
                                                    <th>Startup Name</th>
                                                    <th>Amount Received</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {portfolioResponse?.data?.pending_investments?.map((item, index) => (
                                                    <tr key={item.startup_id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="circle-box mr-10 circle-text">
                                                                    { item.imageURL ? <img src={item.imageURL} /> : <span className="ct-name">{item?.company_name?.split(" ")[0]?.substring(0, 1)}{item?.company_name?.split(" ")[1]?.substring(0, 1)}</span>}
                                                                </div>
                                                                &nbsp;{item.company_name}
                                                            </div>
                                                        </td>
                                                        <td>{item.amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                (portfolioResponse?.data?.sectors?.length > 0 || portfolioResponse?.data?.stages?.length > 0 || portfolioResponse?.data?.stageDistribution?.length > 0) &&
                                <div className="accordion swd-accordion portfolio-card-list" id="accordionExample1">
                                    {
                                        portfolioResponse?.data?.sectors?.length > 0 && <div className="accordion-item portfolio-card">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Sector Wise Distribution
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample1">
                                                <div className="accordion-body">
                                                    <div className='text-center'>
                                                        <div className='mxw-300'>
                                                            <svg viewBox="0 0 250 250">
                                                                <VictoryPie
                                                                    standalone={false}
                                                                    width={250} height={250}
                                                                    data={sector_percentage}
                                                                    innerRadius={55} labelRadius={85}
                                                                    padAngle={2}
                                                                    cornerRadius={3}
                                                                    colorScale={colorArray}
                                                                    style={{ labels: { fontSize: 11, fill: "#948FB5", fontWeight: "600" } }}
                                                                    labels={({ datum }) => { return datum.y > 5 ? datum.y % 1 > 0 ? (parseFloat(datum.y).toFixed(2) + "%") : (parseFloat(datum.y).toFixed(0) + "%") : null }}
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="d-inline-flex align-items-center flex-wrap justify-content-center distribution-block">
                                                            {portfolioResponse?.data?.sectors?.map((item, index) => (
                                                                <div className='distribution-item me-3' key={item.sector_id}>
                                                                    <span
                                                                        className='distribution-circle'
                                                                        style={{
                                                                            background: colorArray[index],
                                                                        }}
                                                                    ></span>
                                                                    <div className="blank-link sm-title font-0-8em text-start">{item.name}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        portfolioResponse?.data?.stages?.length > 0 && <div className="accordion-item portfolio-card">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Portfolio Stages Name
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample1">
                                                <div className="accordion-body">
                                                    <div className='text-center'>
                                                        <div className='mxw-300'>
                                                            <svg viewBox="0 0 250 250">
                                                                <VictoryPie
                                                                    standalone={false}
                                                                    width={250} height={250}
                                                                    data={stages_percentage}
                                                                    innerRadius={55} labelRadius={85}
                                                                    padAngle={2}
                                                                    cornerRadius={3}
                                                                    colorScale={colorArray}
                                                                    style={{ labels: { fontSize: 11, fill: "#948FB5", fontWeight: "600" } }}
                                                                    labels={({ datum }) => { return datum.y > 5 ? datum.y % 1 > 0 ? (parseFloat(datum.y).toFixed(2) + "%") : (parseFloat(datum.y).toFixed(0) + "%") : null }}
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="d-inline-flex align-items-center flex-wrap justify-content-center distribution-block">
                                                            {portfolioResponse?.data?.stages?.map((item, index) => (
                                                                <div className='distribution-item me-3' key={item.stage_id}>
                                                                    <span
                                                                        className='distribution-circle'
                                                                        style={{
                                                                            background: colorArray[index],
                                                                        }}
                                                                    ></span>
                                                                    <div className="blank-link sm-title font-0-8em text-start">{item.name}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        portfolioResponse?.data?.stageDistribution?.length > 0 && <div className="accordion-item portfolio-card">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Stage Distribution
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                                data-bs-parent="#accordionExample1">
                                                <div className="accordion-body">
                                                    <div className="custom-table pfr-table">
                                                        <table className="w-100">
                                                            <thead>
                                                                <tr>
                                                                    <th>Startup Name</th>
                                                                    <th>Investment Stage</th>
                                                                    <th>Current Stage</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {portfolioResponse?.data?.stageDistribution?.map((item, index) => (

                                                                    <tr key={item.startup_id}>
                                                                        <td>{item.company_name}</td>
                                                                        <td>{item.ini_stage}</td>
                                                                        <td>{item.current_stage}</td>
                                                                    </tr>
                                                                ))}
                                                                {/* <tr>
                                                                    <td>Celloware</td>
                                                                    <td>Seed</td>
                                                                    <td>Seed</td>
                                                                </tr> */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div> :
                        <div className="no-portfolio-box position-relative">
                            <div className="no-portfolio-pos">
                                <div className="sm-title text-center mb-3">Your portfolio will be shown here!</div>
                                <div className="md-title text-center">Start building your portfolio</div>
                                <div className="text-center mt-4">
                                    {dealCount > 0 && <div>
                                        <button className="theme-btn px-4" type="button" onClick={() => { navigate("/deal-list?deal-id=all-deal") }}>View Deals</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                }
                {
                    portfolioLoader && <div className='spinner text-center'><span className='spinner-border spinner-border-xl text-warning'></span></div>
                }
            </DashboardLayout>
            <Modal commonModalShow={open} crossHide={true} hideHeading={true} bodyPadding={true}
                commonModalData={
                    <>
                        <div className='d-flex align-items-center p-3'>
                            <h5 className='md-title mb-0'>
                                Filter Portfolio
                            </h5>
                            <div className='ml-auto'>
                                <button className='transparent-btn text-uppercase' type='button' onClick={() => { setSyndicateValue(""); setSectorValue(""); setFilterApplied(false); closeFilter(); setSelectedSort("Null"); filterInvestments(null, null) }}>Clear All</button>
                            </div>
                        </div>
                        <div className='custom-tabs d-flex vertical-tabs'>
                            <ul class="nav nav-tabs d-block" id="filterTabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="fundSyndicate-tab" data-bs-toggle="tab" data-bs-target="#fundSyndicate" type="button" role="tab" aria-controls="fundSyndicate" aria-selected="true">Fund/Syndicate</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="sector-tab" data-bs-toggle="tab" data-bs-target="#sector" type="button" role="tab" aria-controls="sector" aria-selected="false">Sector</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="filterTabsContent">
                                <div class="tab-pane fade show active" id="fundSyndicate" role="tabpanel" aria-labelledby="fundSyndicate-tab">
                                    <div className="custom-radio mt-3">
                                        <input class="styled-radio" id="fundSyndicateAll" type="radio" value="" onChange={handleSyndicateChange} name="fundSyndicate" />
                                        <label for="fundSyndicateAll">Select All ({fundArray.length})</label>
                                    </div>
                                    {fundArray?.map((val, indx) => (
                                        <div className="custom-radio mt-3" key={val.label}>
                                            <input class="styled-radio" id={"Syndicate" + indx} type="radio" onChange={handleSyndicateChange} value={val.value} name="fundSyndicate"
                                                checked={syndicate_name === val.value}
                                            />

                                            <label for={"Syndicate" + indx}>{val.label}</label>
                                        </div>))}
                                    {/* <div className="custom-radio mt-3">
                                        <input class="styled-radio" id="fundSyndicateRtaf" type="radio" value="yes" name="fundSyndicate" />
                                        <label for="fundSyndicateRtaf">RTAF</label>
                                    </div> */}
                                </div>
                                <div class="tab-pane fade" id="sector" role="tabpanel" aria-labelledby="sector-tab">
                                    <div className="custom-radio mt-3">
                                        <input class="styled-radio" id="sectorAll0" type="radio" onChange={handleSectorChange} value="" name="sector" />
                                        <label for="sectorAll0">Select All ({sectorArray.length})</label>
                                    </div>
                                    {sectorArray?.map((secVal, Secindx) => (

                                        <div className="custom-radio mt-3" key={secVal.label}>
                                            <input class="styled-radio" id={"Sector" + Secindx} type="radio" onChange={handleSectorChange} value={secVal.value} name="sector"
                                                checked={sector_name === secVal.value}
                                            />
                                            <label for={"Sector" + Secindx}>{secVal.label} </label>

                                        </div>))}



                                    {/* <div className="custom-radio mt-3">
                                        <input class="styled-radio" id="sectorBeauty" type="radio" value="yes" name="sector" />
                                        <label for="sectorBeauty">Beauty & Personal Care</label>
                                    </div>
                                    <div className="custom-radio mt-3">
                                        <input class="styled-radio" id="sectorEdTech" type="radio" value="yes" name="sector" />
                                        <label for="sectorEdTech">EdTech</label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-3 mb-3 ps-3 pe-3'>
                            <button className='gray-btn sm-btn w-180 close-btn me-3' type="button" onClick={closeFilter}>CLOSE</button>
                            <button className='theme-btn w-180' onClick={() => submitFilter()} type="button">APPLY</button>
                        </div>
                    </>
                }>

            </Modal>

            <Modal
                commonModalTitle="Documents"
                commonModalShow={open_document}
                commonModalClose={commonModalClose}
                commonModalData={
                    <>
                        <button className='btn-close close-pos-btn' type="button" onClick={commonModalClose}></button>
                        <ul className="document-list mt-2">
                            {document_data &&
                                document_data.length > 0 &&
                                document_data?.map((doc) => (
                                    <Link to={doc.url} target="_blank" key={doc.name}>
                                        <li className="d-flex align-items-center">
                                            <div className="icon">
                                                <img src={Pdf} alt="pdf" />
                                            </div>

                                            <div className="ml-15">
                                                <div className="md-title text-start">{doc.title}</div>
                                                {/* <div className="sm-title text-start mt-1">
                                                    {moment(doc.date).format("DD MMM YYYY")}
                                                </div> */}
                                            </div>

                                            <div className="ml-auto">
                                                <span className="view-icon">
                                                    <img src={ViewIcon} alt="view" />
                                                </span>
                                            </div>
                                        </li>
                                    </Link>
                                ))}
                        </ul>
                    </>
                }
            />

            <Modal
                commonModalTitle="Unrealised Gain & Loss"
                commonModalShow={showInfo}
                commonModalClose={closeInfoModal}
                commonModalData={
                    <>
                        <div className='d-flex align-items-center justify-content-between mb-4'>
                            <h5 className='md-title mb-0' id='documentModalLabel'>Unrealised Gain & Loss</h5>
                            <button type='button' className='btn-close' onClick={closeInfoModal}></button>
                        </div>
                        <div className="unrealised-text">
                            {<div fontFamily={'Lato'} dangerouslySetInnerHTML={{ __html: infoMessage?.html }} />}
                        </div>
                    </>
                }
            />


        </>
    )
}

export default AllBackrrPortfolio