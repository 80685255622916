import { Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { fetchExtension } from '../../utils/utils';
import pdfIcon from "../../assets/img/pdfn.png"
import imageIcon from "../../assets/img/image.png"
import crossImg from "../../assets/img/cross-icon.svg";

export default function DocumentDetails(props) {

    const [documents, setDocuments] = useState(props.roundData.documents)
    const [documentTitle, setDocumentTitle] = useState(null)

    console.log("documents", props.roundData)

    const handleFileChange = (event) => {
        let file = event.target.files[0];
        let fileUploaded = new File([file], documentTitle + "." + file.name.split(/[#?]/)[0].split(".").pop().trim())
        // fileUploaded.filename = documentTitle
        console.log("fileUploaded", fileUploaded)
        let allowedExtensions = ["jpg", "jpeg", "png", "svg", "pdf", "PDF"]
        let extension = fileUploaded?.name.split(/[#?]/)[0].split(".").pop().trim()
        if (allowedExtensions.indexOf(extension) > -1) {
            let document = fileUploaded
            let documentUri = documents
            console.log('documentUri', documentUri)
            documentUri.push(document)

            setDocuments(documentUri)
            setDocumentTitle("")
        } else {
            toast.error("Only images and PDF are allowed")
        }
    };

    const deleteDocument = (selectedUri) => {
        let document = documents
        if (selectedUri.name) {
            document = document.filter(item => item.name != selectedUri.name)
        } else {
            document = document.filter(item => item.url != selectedUri.url)
        }
        setDocuments(document)
    }

    useEffect(() => {
        console.log("documentTitle", documentTitle)
    }, [documentTitle])

    return (
        <div className="pt-4 pb-4 ps-5 pe-5">
            <div className="pt-0 pb-0 kyc-form-block pyp-block">
                <div className="pt-0 pb-5 kyc-form-block pyp-block mxw-400">
                    {
                        documents?.length > 0 ?
                            <div className='mb-4'>
                                <ul className="document-list">
                                    {
                                        documents.map((item, index) => {
                                            return (
                                                <li className="p-0 d-flex align-items-center justify-content-between">
                                                    <Link onClick={(e) => {
                                                        if (item.url) {
                                                            window.open(item.url)
                                                        } else {
                                                            e.preventDefault()
                                                        }
                                                    }} target="_blank" key={item} className='d-flex align-items-center pt-3 pb-3'>
                                                        <div className="hm-icon">
                                                            <img src={fetchExtension(item) == 'pdf' || fetchExtension(item) == 'PDF' ? pdfIcon : imageIcon} alt="pdf" />
                                                        </div>
                                                        <div className="ml-15">
                                                            <div className="md-title text-start font-1em">{item.name ? item.name.substring(0, item.name.lastIndexOf('.')) : item.title}</div>
                                                        </div>

                                                    </Link>
                                                    <div className='d-flex align-items-center'>
                                                        {item.url && <div className="mt-1">
                                                            <span className="view-icon">
                                                                <i className='bi bi-eye font-1-2em'></i>
                                                            </span>
                                                        </div>}
                                                        <div
                                                            className="ms-3 cursor-pointer"
                                                            onClick={() => {
                                                                deleteDocument(item)
                                                            }}
                                                        >
                                                            <img src={crossImg} alt="cross" />
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div>
                                    <div className="d-flex align-items-center justify-content-between favourite-box pt-3 pb-3">
                                        <div className="d-flex align-items-end justify-content-between custom-form w-100">
                                            <div className='w-50'>
                                                <input placeholder='Document Title' type="text" className="form-control w-100" name='documentTitle' value={documentTitle} onChange={e => { setDocumentTitle(e.target.value) }} />
                                            </div>
                                            <div disabled={documentTitle == null || documentTitle?.trim()?.length == 0} style={{ opacity: documentTitle == null || documentTitle?.trim()?.length == 0 ? 0.5 : 1 }} className='w-50 ps-4'>
                                                <div class="custom-upload">
                                                    <button disabled={documentTitle == null || documentTitle?.trim()?.length == 0} class="custom-upload-btn">Upload</button>
                                                    <input
                                                        type="file"
                                                        accept="image/*,application/pdf"
                                                        onChange={handleFileChange}
                                                        disabled={documentTitle == null || documentTitle?.trim()?.length == 0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xs-title text-start mt-1">
                                        <div>
                                            Note:- Start uploading your documents such as Pitch Decks, Shareholder Agreements (SHA), Due Diligence documents and more.</div>
                                        <div className='mt-3'>Supported formats: PDF, JPEG,PNG and HEIC</div>
                                    </div>
                                </div>
                            </div>
                            : <div>
                                <div>
                                    <div className="d-flex align-items-center justify-content-between favourite-box pt-3 pb-3">
                                        <div className="d-flex align-items-end justify-content-between custom-form w-100">
                                            <div className='w-50'>
                                                <input placeholder='Document Title' type="text" className="form-control w-100" name='documentTitle' value={documentTitle} onChange={e => { setDocumentTitle(e.target.value) }} />
                                            </div>
                                            <div disabled={documentTitle == null || documentTitle?.trim()?.length == 0} style={{ opacity: documentTitle == null || documentTitle?.trim()?.length == 0 ? 0.5 : 1 }} className='w-50 ps-4'>
                                                <div class="custom-upload">
                                                    <button disabled={documentTitle == null || documentTitle?.trim()?.length == 0} class="custom-upload-btn">Upload</button>
                                                    <input
                                                        type="file"
                                                        accept="image/*,application/pdf"
                                                        onChange={handleFileChange}
                                                        disabled={documentTitle == null || documentTitle?.trim()?.length == 0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xs-title text-start mt-1">
                                        <div>
                                            Note:- Start uploading your documents such as Pitch Decks, Shareholder Agreements (SHA), Due Diligence documents and more.</div>
                                        <div className='mt-3'>Supported formats: PDF, JPEG,PNG and HEIC</div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <div className="mt-3 text-center">
                    <button type="submit" className="theme-btn btn w-25" onClick={() => { props.handleSubmit({ ...props.roundData, ...{ documents: documents } }) }}>
                        Done
                    </button>
                </div>
            </div >
        </div >
    );
}
