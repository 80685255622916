import * as Yup from "yup";
import AuthBtnSpinner from "../../utils/AuthBtnSpinner";
import { Field, Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { CustomInputComponent, CustomTextareaComponent } from "../../components/formik/FieldComponents";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearSaveComplaint, saveComplaint } from "../../store/actions/complaintAction";
const LoginValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile_no: Yup.string().required("Mobile number is required"),
  folio: Yup.string().required("Folio number is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email"),
  comments: Yup.string().required("Complaint is required"),
});

const Complaint = () => {
  const [errorMsg, seterrorMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const creducer = useSelector(state => state.complaintReducer.data);
  const isloding = useSelector(state => state.complaintReducer.isLoading);
  
  const submitComplaint = (req) => {
    dispatch(saveComplaint(req))
  }

  useEffect(() => {
    if (creducer != null) {
      if (Object.keys(creducer).length != 0 && creducer.statusCode == 200) {
        toast.success(creducer.message)
        dispatch(clearSaveComplaint());
          navigate("/");
      }
      if(Object.keys(creducer).length !=0 && creducer.statusCode != 200){
        toast.error(creducer.message)
        dispatch(clearSaveComplaint());
      }
    }
  }, [creducer])

  return (
    <section className="mid-wrap pt-5 pb-5">
      <div className="mid-card-block">
        <div className="mxw-750">
          <h5 className="card-title lg-title mb-4 fw-700 mt-3">
          Please provide details to raise a complaint  
          </h5>
          <div className="card form-card">
            <div className="card-body">
            <Formik
              validationSchema={LoginValidationSchema}
                initialValues={{
                  name: "",
                  email: "",
                  mobile_no: "",
                  folio: "",
                  comments: "",
                }}
                onSubmit={(values) => submitComplaint(values)}
              >
              {({ errors, touched }) => (
               <Form className="custom-form">
                    {/* Name */}
                    <div className="row">
                    <div className="col-md-6 form-group">
                      <label>Name</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                        component={CustomInputComponent}
                        autoComplete="off"
                      />
                    </div>
                    {/* email */}
                    <div className="col-md-6 form-group">
                      <label>Email</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="email"
                        component={CustomInputComponent}
                        autoComplete="off"
                      />
                    </div>
                    {/* mobile no */}
                    <div className="col-md-6 form-group">
                      <label>Mobile No</label>
                      <Field
                        type="text"
                        className="form-control"
                        component={CustomInputComponent}
                        name="mobile_no"
                        autoComplete="off"
                      />
                    </div>
                    {/* folio No */}
                    <div className="col-md-6 form-group">
                      <label>Folio No</label>
                      <Field
                        type="text"
                        className="form-control"
                        component={CustomInputComponent}
                        name="folio"
                        autoComplete="off"
                      />
                    </div>
                    {/* comment */}
                    <div className="col-md-12 form-group">
                      <label>Mention your complaint</label>
                      <Field
                        name="comments"
                        component={CustomTextareaComponent}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    </div>
                    {/* submit-button */}
                    {isloding ? (
                      <AuthBtnSpinner />
                    ) : (
                      <button className="theme-btn btn w-100" type="submit">
                        Submit
                      </button>
                    )}

                    <div className="smartodr mt-4">
                      <p>In case you would like to submit your complaint to SmartODR <a href="https://smartodr.in/login" target="_blank" rel="noopener noreferrer"> Click here</a></p>
                    </div>
            </Form>
                )}
            </Formik>
            </div>
          </div>
          {/* <div className="contact-complaint"> */}
          <div className="container p-4 mt-4">
            <div className="row">
              <div className="col-md-4">
                <h6>Jaipur</h6>
                <p>127, SKIT Road<br/>
                CBI Colony, Pawan Vihar<br/>
                Jaipur-302017, Rajasthan (INDIA)
                </p>
              </div>
              <div className="col-md-4">
                <h6>Registered Address</h6>
                <p>3011, Lower Ground Floor,<br/>
                Sector - 46, Gurugram - 122002, Haryana
                </p>
              </div>
              <div className="col-md-4">
                <h6>Connect With Us</h6>
                <a href="mailto:investors@realtimeaf.com">Email us on :<br/>
                <span className="complaint-link">Click here</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Complaint;
