import {types} from './types';

export function saveFeedback(data) {
  return {
    type: types.SEND_REQUEST_SAVE_FEEDBACK,
    payload: data,
  };
}

export function saveFeedbackSuccess(data) {
  return {
    type: types.SEND_REQUEST_SAVE_FEEDBACK_SUCCESS,
    payload: data,
  };
}

export function saveFeedbackFailure(error) {
  return {
    type: types.SEND_REQUEST_SAVE_FEEDBACK_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearSaveFeedback() {
  return {
    type: types.CLEAR_REQUEST_SAVE_FEEDBACK,
    payload: {},
  };
}
