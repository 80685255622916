import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import rightArrow from "../../../../assets/img/right-arrow.svg";
import DashboardLayout from '../../deals/DashboardLayout';
import { GetUserDetailsUpdated } from '../../../../api'
import avtarImg from "../../../../assets/img/dummy-profile.png";
import { useDispatch, useSelector } from 'react-redux';
import { clearUpdateProfile, updateProfile } from '../../../../store/actions/updateProfileAction';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { saveProfileImage } from '../../../../store/actions/saveProfileImageAction';
import backArrow from "../../../../assets/img/back.svg";
import { isEmptyObject, notEmpty } from '../../../../utils/utils';

const MyProfile = () => {

    const inputRef = useRef()
    const dispatch = useDispatch()

    const [profile_data, setProfileData] = useState([]);
    const [name, setName] = useState(null)
    const [profileImage, setProfileImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [cmrExist, setCmrExist] = useState(false)

    const { data: userData, isLoading: isDocsLoading } = GetUserDetailsUpdated();

    const updateProfileResponse = useSelector(state => state.updateProfileReducer.data);

    useEffect(() => {
        if (!isDocsLoading) {
            setProfileData(userData.data)
            let firstname = userData.data.first_name;
            let middlename = userData.data.middle_name;
            let lastname = userData.data.last_name;
            let fullname = null;
            if (middlename == undefined) {
                fullname = firstname + ' ' + lastname
            } else {
                fullname = firstname + ' ' + middlename + ' ' + lastname;
            }
            setName(fullname)
            setProfileImage(userData.data.profile_image)

            const docs = userData?.data?.documents?.filter((doc) => doc.slug === "onboarding_documents");
            let cmr = docs?.length > 0 ? docs[0]?.documents?.filter(item => { return item.upload_type == "client_master_report" }) : []
            setCmrExist(cmr?.length > 0)
        }
    }, [userData]);

    useEffect(() => {
        dispatch(saveProfileImage(profileImage))
        Cookies.remove("profileImage")
        Cookies.set("profileImage", profileImage)
    }, [profileImage])

    const handleFile = () => {
        inputRef.current.click()
    }

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            setSelectedFile(fileUploaded)
            console.log("fileUploaded", fileUploaded)
            let blockedExtensions = ["jpg", "jpeg", "png", "svg"]
            let extension = fileUploaded?.name.split(/[#?]/)[0].split(".").pop().trim()
            if (blockedExtensions.indexOf(extension) > -1) {
                let request = new FormData();
                request.append('profile_image', fileUploaded);
                request.append('email', profile_data?.email);
                request.append('mobile_number', profile_data?.mobile_number);
                request.append('phone_code', profile_data?.phone_code);
                request.append('linkedInProfileUrl', profile_data?.personalize_profile_static[0]?.linkedInProfileUrl ? profile_data?.personalize_profile_static[0]?.linkedInProfileUrl : "");
                console.log('profile_image', request)
                dispatch(updateProfile(request));
            } else {
                toast.error("Only images are allowed")
            }
        }
    };

    useEffect(() => {
        if (updateProfileResponse != null) {
            if (updateProfileResponse.statusCode == 200) {
                if (updateProfileResponse.data != null) {
                    if (selectedFile) {
                        setProfileImage(URL.createObjectURL(selectedFile));
                        // dispatch(saveProfileImage(URL.createObjectURL(selectedFile)))
                        // Cookies.remove("profileImage")
                        // Cookies.set("profileImage", URL.createObjectURL(selectedFile))
                    }
                } else if (updateProfileResponse.statusCode != 200) {
                    toast.error(updateProfileResponse.message);
                }
                dispatch(clearUpdateProfile());
            }
        }
    }, [updateProfileResponse]);

    return (
        <DashboardLayout>
            <div className='header-mobile d-md-none border-b'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <Link className="back-btn blue-text d-block" to="/dashboard">
                            <img src={backArrow} alt="arrow" /> My Profile
                        </Link>
                    </div>
                    <div>
                        <Link to="/change-pin" className='blue-btn'>Change Your PIN</Link>
                    </div>
                </div>
            </div>


            <div className='d-flex align-items-center justify-content-between cr-heading header-desktop'>
                <div className="md-title text-start fw-700">
                    My Profile
                </div>
                <div>
                    <Link to="/change-pin" className='theme-btn btn btn-sm'>Change Your PIN</Link>
                </div>
            </div>
            <div className="pt-4 pb-4 ps-5 pe-5">
                <div className='d-flex align-items-center profile-circle-flex'>
                    <div class="profile-circle-box d-inline-block position-relative  " >
                        <div class="profile-circle"><img src={profileImage != null ? profileImage : avtarImg} /></div>
                        <div class="pf-edit-btn" >
                            <i class="bi bi-pencil-fill"></i>
                            <input ref={inputRef} type="file" name="avatar" accept=".jpg, .jpeg, .png, .svg" style={{ display: "none" }} onChange={handleChange} /><button class="btn dashfile-upload-btn" type="button" onClick={handleFile} ></button>
                        </div>
                    </div>
                    <div className="ml-15">
                        <div className="md-title text-start font-1em">{name}</div>
                        <div className="sm-title text-start mt-1">{profile_data?.email}</div>
                        <div className="xs-title text-start mt-1">{profile_data?.lp_code}</div>
                    </div>
                </div>

                <ul className="home-tab-list">
                    <li>
                        <Link
                            to="/my-profile/personal-information"
                            className="d-flex align-items-center"
                        >
                            <div className="hml-title">
                                KYC Details
                            </div>
                            <div className='ml-auto d-flex align-items-center'>
                                {profile_data?.master_status_id && profile_data?.master_status_id.length == 0 && <div
                                    className="btn btn-danger text-nowrap btn-sm me-4"
                                >
                                    KYC Pending
                                </div>}
                                {profile_data?.master_status_id && profile_data?.master_status_id.length > 0 && <div
                                    className="btn btn-success text-nowrap btn-sm me-4" style={{ backgroundColor: profile_data?.master_status_id[0]?.values[0]?.color, borderColor: profile_data?.master_status_id[0]?.values[0]?.color }}
                                >
                                    {profile_data?.master_status_id[0].values[0].name}
                                </div>}

                                <div className="hm-arrow">
                                    <img src={rightArrow} alt="arrow" />
                                </div>
                            </div>
                        </Link>
                    </li>

                    {profile_data?.bankAccounts?.length > 0 && <li>
                        <Link
                            to="/my-profile/demat-account"
                            className="d-flex align-items-center"
                        >
                            <div className="hml-title">
                                Demat Account
                            </div>
                            <div className='ml-auto d-flex align-items-center'>
                                {
                                    notEmpty(profile_data?.bankAccounts[0]?.demat_account) ?
                                        isEmptyObject(userData.data?.bankAccounts[0]?.demat_account) ?
                                            cmrExist ? <div className="btn btn-success text-nowrap btn-sm me-4" style={{ backgroundColor: '#4BB543', borderColor: '#4BB543' }}>Submitted</div>
                                                : <div className="btn btn-warning text-nowrap btn-sm me-4">Not Submitted</div> :
                                            <div className="btn btn-success text-nowrap btn-sm me-4" style={{ backgroundColor: '#4BB543', borderColor: '#4BB543' }}>Submitted</div>
                                        :
                                        <div className="btn btn-warning text-nowrap btn-sm me-4">Not Submitted</div>
                                }
                                <div className="hm-arrow">
                                    <img src={rightArrow} alt="arrow" />
                                </div>
                            </div>
                        </Link>
                    </li>}

                    {(profile_data?.user_individual_type == "indian_citizen" || profile_data?.user_individual_type == "foreign_citizen") && <li>
                        <Link
                            to="/my-profile/nominee-details"
                            className="d-flex align-items-center"
                        >
                            <div className="hml-title">
                                Nominee Details
                            </div>
                            <div className='ml-auto d-flex align-items-center'>
                                {profile_data?.is_nominee_added == "No" && <div
                                    className="btn btn-warning btn-sm me-4 text-nowrap"
                                >
                                    Not Submitted
                                </div>}
                                {profile_data?.is_nominee_added == "Yes" && <div
                                    className="btn btn-success btn-sm me-4" style={{ backgroundColor: '#4BB543', borderColor: '#4BB543' }}
                                >
                                    Submitted
                                </div>}
                                <div className="hm-arrow">
                                    <img src={rightArrow} alt="arrow" />
                                </div>
                            </div>
                        </Link>
                    </li>}


                    {profile_data?.documents && profile_data?.documents.length > 0 && <li>
                        <Link
                            to="/my-profile/documents"
                            className="d-flex align-items-center"
                        >
                            <div className="hml-title">
                                Documents
                            </div>
                            <div className="hm-arrow ml-auto">
                                <img src={rightArrow} alt="arrow" />
                            </div>
                        </Link>
                    </li>}

                    <li>
                        <Link
                            to="/my-profile/personalize-profile"
                            className="d-flex align-items-center"
                        >
                            <div className="hml-title">
                                Personalize Profile
                            </div>
                            <div
                                className="btn btn-success btn-sm me-4 d-none"
                            >
                                Approved
                            </div>
                            <div className="hm-arrow ml-auto">
                                <img src={rightArrow} alt="arrow" />
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </DashboardLayout>
    )
}

export default MyProfile