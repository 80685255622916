import React from "react";
import MainLayout from "../layouts/MainLayout";
import DashBoard from '../views/main/deals/DashBoard';
import Complaint from "../views/auth/Complaint";

const MainRoutes = {
  path: "",
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <DashBoard />
    },

    {
      path: "*",
      element: <DashBoard />,
    },
    {
      path: "Complaint",
      element: <Complaint/>,
    },
  ],



};

export default MainRoutes;
