import React from 'react';

import DashBoard from '../views/main/deals/DashBoard';
import DealList from '../views/main/deals/DealLists';
import DealDetails from '../views/main/deals/DealDetails';
import MainLayout from '../layouts/MainLayout';
import MyProfile from '../views/main/pages/Profile/MyProfile';
import ChangePin from '../views/main/pages/KycDetails/ChangePin';
import DeclarationProfile from '../views/main/pages/KycDetails/DeclarationProfile';
import NomineeInformation from '../views/main/pages/KycDetails/NomineeInformation';
import PersonalInformation from '../views/main/pages/KycDetails/PersonalInformation';
import PersonalizeProfile from '../views/main/pages/KycDetails/PersonalizeProfile';


import PermanentAddress from '../views/main/pages/KycDetails/PermanentAddress';
import BankDetails from '../views/main/pages/KycDetails/BankDetails';
import UploadDocument from '../views/main/pages/KycDetails/UploadDocument';
import Favourite from '../components/Favourite/Favourite';
import StatementOfAccount from '../components/StatementOfAccount/StatementOfAccount';
import AboutUs from '../components/Common/AboutUs';
import RateAndFeedback from '../components/Common/RateAndFeedback';
import MyBackrrs from '../components/Common/MyBackrrs';
import Portfolio from '../views/main/pages/Portfolio/Portfolio';
import ProfileDocuments from '../views/main/pages/Profile/ProfileDocuments';
import AddInvestmentSplash from '../views/main/pages/Portfolio/AddInvestmentSplash';
import AddInvestment from '../views/main/pages/Portfolio/AddInvestment';
import AddRounds from '../views/main/pages/Portfolio/AddRounds';
import FeedbackList from '../components/Common/FeedbackList';
import FeedbackDetail from '../components/Common/FeedbackDetail';
import MyBackrrsDeals from '../components/Common/MyBackrrsDeals';
import MyBackrrPortfolio from '../views/main/pages/Portfolio/MyBackrrPortfolio';
import AllBackrrPortfolio from '../views/main/pages/Portfolio/AllBackrrPortfolio';
import DematAccount from '../views/main/pages/Profile/DematAccount';
import SignedPdf from '../views/main/deals/SignedPdf';


const PagesRoutes = {
  path: '',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <DashBoard />
    },
    {
      path: '/deal-list',
      element: <DealList />
    },

    {
      path: '/deal-details',
      element: <DealDetails />
    },
    {
      path: "my-profile",
      // element: <MyProfile />,
      children: [
        {
          path: "",
          element: <MyProfile />
        },
        {
          path: "personal-information",
          element: <PersonalInformation />
        },
        {
          path: "permanent-address",
          element: <PermanentAddress />
        },
        {
          path: "bank-details",
          element: <BankDetails />
        },
        {
          path: "upload-documents",
          element: <UploadDocument />
        },
        {
          path: "declaration",
          element: <DeclarationProfile />
        },
        {
          path: "personalize-profile",
          element: <PersonalizeProfile />
        },

        {
          path: "nominee-details",
          element: <NomineeInformation />
        },
        {
          path: "documents",
          element: <ProfileDocuments />
        },
        {
          path: "demat-account",
          element: <DematAccount />
        },
      ]
    },
    {
      path: "change-pin",
      element: <ChangePin />
    },
    {
      path: "favorites",
      element: <Favourite />
    },
    {
      path: "statement-of-account",
      element: <StatementOfAccount />
    },
    {
      path: "about-us",
      element: <AboutUs />
    },
    {
      path: "rate-and-feedback",
      element: <RateAndFeedback />
    },
    {
      path: "feedback-list",
      element: <FeedbackList />
    },
    {
      path: "feedback-detail",
      element: <FeedbackDetail />
    },
    {
      path: "my-backrrs",
      element: <MyBackrrs />
    },
    {
      path: "my-backrrs-deals",
      element: <MyBackrrsDeals />
    },
    {
      path: "portfolio",
      element: <Portfolio />
    },
    {
      path: "my-backrr-portfolio",
      element: <MyBackrrPortfolio />
    },
    {
      path: "all-backrr-portfolio",
      element: <AllBackrrPortfolio />
    },
    {
      path: "add-investment-splash",
      element: <AddInvestmentSplash />
    },
    {
      path: "add-investment",
      element: <AddInvestment />
    },
    {
      path: "add-rounds",
      element: <AddRounds />
    },
    {
      path: '/signedPdf',
      element: <SignedPdf />
    },
  ]
};

export default PagesRoutes;
