import React from 'react';
import * as Yup from 'yup';
import AuthBtnSpinner from '../../utils/AuthBtnSpinner';
import { Field, Form, Formik } from 'formik';
import logoVr from '../../assets/img/logo_vr.png';
import { Link } from 'react-router-dom';
import { SendOtp } from '../../api';
import { CustomInputComponent } from '../../components/formik/FieldComponents';
import { toast } from 'react-toastify';
import { useState, useEffect} from 'react';
import Cookies from "js-cookie";



const EmailValidationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Must be a valid email')
});

function RegisterEmail() {

    const [errorMsg,seterrorMsg] = useState(null);

    const { mutateAsync:sendOTP, status:OTPStatus, error, isLoading } = SendOtp();

    var  erorMesg = "";

   

    return (

        <section className="mid-wrap pt-5 pb-5">
            <div className="mid-card-block">
                <div className="mxw-750">
                    {/* <div className="text-center mb-4 vendor-info">
                        <img src={logoVr} alt="my mandi" />
                    </div> */}
                    <h5 className="card-title lg-title mb-4 fw-700">Create Account with Email</h5>
                    <div className="card form-card">
                        <div className="card-body">
                            
                            <p className="sm-title mb-4 mt-3 fw-700 font-16">Enter the email address to create account</p>

                            <Formik
                                initialValues={{
                                    email: Cookies.get('verifyEmail')?Cookies.get('verifyEmail'):'',
                                    device_id: 'web',
                                    device_token: 'web',
                                    device_type: 'web',
                                    slug: Cookies.get("domainSlug")
                                }}
                                validationSchema={EmailValidationSchema}
                                onSubmit={(values) => sendOTP(values,{
                                    onError: (error)=>{
                                         // console.log(error);
                                        if (error.response) {
                                              let res = error.response.data;
                                              if (error.response.status === 403) {
                                                  //toast.error(res.message);
                                                  seterrorMsg(res.message);
                                                 // errors.email = res.message;
                                              }else{
                                                 toast.error(error.response.message);
                                              }
                                              if (error.response.status === 500) {
                                                  toast.error('Internal Server Error');
                                              }
                                          }
                                      }
                                })}
                                validateOnBlur={true}
                                validateOnChange={true}
                            >

                                {({ errors, touched }) => (

                                    // Contact Us Form
                                    <Form className="custom-form">
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <Field name="email" component={CustomInputComponent} type="text" className="form-control" />
                                           
                                           {/* onChange={() => { seterrorMsg(null) } } */ }
                                           
                                            {seterrorMsg && !errors.email && (
                                                <small className='text-danger'>                                                   
                                                     {errorMsg}
                                                </small>
                                            )}
                                      
                                        </div>
                                        {isLoading ? (
                                             <AuthBtnSpinner />
                                        ) : (
                                            <button className='theme-btn btn w-100' type='submit'>
                                                Next
                                            </button>
                                        )}

                                        <div className="sm-title mt-4 fw-500">
                                            Already have an account? <Link to='/home'><u>Sign In</u></Link>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default RegisterEmail