import moment from 'moment';
import { useEffect, useState } from 'react';

export default function TimeDiff({ displayText = '', deadline, setActive = null }) {
    const [timeLeft, setTimeLeft] = useState({});



    const calculateTimeLeft = () => {
        const difference = moment(deadline) - moment();

        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    useEffect(() => {
        setTimeLeft(calculateTimeLeft());
    }, []);

    useEffect(() => {
        const countdown = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(countdown);
    });

    useEffect(() => {
        if (setActive != null) {
   
            var deadlineDate = moment(deadline).subtract(15, 'minutes');


            if (moment().isAfter(deadlineDate)) {
                // console.log("ttt");
                setActive(true);
            } else {
                // console.log("ffff");
                setActive(false);
            }
        }
    }, [timeLeft]);

    return moment().isAfter(moment(deadline)) ? !null : (
        <div className='sm-title text-start font-0-7em'>
            {displayText} &nbsp;
            <span className='green-text'>
                
                {timeLeft?.days > 0
                    ? `${timeLeft?.days} days ${
                          timeLeft?.hours > 0 ? `${timeLeft?.hours} hrs` : ''
                      }`
                    : timeLeft?.hours > 1
                    ? `${timeLeft?.hours} hrs ${
                          timeLeft?.minutes > 0 ? `${timeLeft?.minutes} mins` : ''
                      }`
                    : `${timeLeft?.minutes} mins ${
                          timeLeft?.seconds > 0 ? `${timeLeft?.seconds} secs` : ''
                      }`}


            </span>
        </div>
    );
}
