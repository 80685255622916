import {types} from './types';

export function saveComplaint(data) {
  return {
    type: types.SEND_REQUEST_SAVE_COMPLAINT,
    payload: data,
  };
}

export function saveComplaintSuccess(data) {
  return {
    type: types.SEND_REQUEST_SAVE_COMPLAINT_SUCCESS,
    payload: data,
  };
}

export function saveComplaintFailure(error) {
  return {
    type: types.SEND_REQUEST_SAVE_COMPLAINT_FAILURE,
    payload: {},
    error: error,
  };
}

export function clearSaveComplaint() {
  return {
    type: types.CLEAR_REQUEST_SAVE_COMPLAINT,
    payload: {},
  };
}
