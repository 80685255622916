import { Rocket, Dashboard, PieChart, Investors, DocumentFill, Hourglass } from '../../../assets/img'

function About() {
  return (
    <section className='inner-wrap'>
      <div className='container pt-5 pb-5'>
        <div className='lg-title blue-text text-center mb-5 pb-3'>
          <span>About Us</span>
        </div>
        <div className='mb-5 pb-5 border-b'>
          <div className='md-title text-center mb-2'>Who We Are?</div>
          <div className='mxw-600 common-content'>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text ever
              since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div className='mb-3 about-info-list'>
          <div className='lg-title text-center mb-4'>Why Choose Us..?</div>
          <div className='row'>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='gray-box'>
                <div className='icon-md text-center'>
                  <img src={Rocket} alt='' />
                </div>
                <div className='md-title text-center mt-3'>
                  Proprietary <br />
                  Startup Deals
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='gray-box'>
                <div className='icon-md text-center'>
                  <img src={Dashboard} alt='' />
                </div>
                <div className='md-title text-center mt-3'>
                  App Based <br />
                  Dashboard
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='gray-box'>
                <div className='icon-md text-center'>
                  <img src={PieChart} alt='' />
                </div>
                <div className='md-title text-center mt-3'>
                  Startup <br />
                  Portfolio
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='gray-box'>
                <div className='icon-md text-center'>
                  <img src={Investors} alt='' />
                </div>
                <div className='md-title text-center mt-3'>
                  Session with <br />
                  Experienced Investor
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='gray-box'>
                <div className='icon-md text-center'>
                  <img src={DocumentFill} alt='' />
                </div>
                <div className='md-title text-center mt-3'>
                  Hassle Free <br />
                  Documentation
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='gray-box'>
                <div className='icon-md text-center'>
                  <img src={Hourglass} alt='' />
                </div>
                <div className='md-title text-center mt-3'>
                  Exit Strategy At <br />
                  Right Time
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;