import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BtnSpinner from "../../../../utils/BtnSpinner";
import { Back } from "../../../../assets/img";
import avtarImg from "../../../../assets/img/dummy-profile.png";
import {
  CustomInputComponent,
  CustomSelectComponent,
  CustomRadioComponent,
} from "../../../../components/formik/FieldComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_PERSONAL_INFORMATION_FC,
  UPDATE_BANK_DETAILS_FC,
  UPDATE_PERSONALISED_INFORMATION,
  UPDATE_BANK_DETAILS,
  UPDATE_PERSONALISED_INFORMATION_HUF,
  UPDATE_BANK_DETAILS_HUF,
  UPDATE_BUSINESS_INFORMATION_FCorp,
  UPDATE_BANK_DETAILS_FCorp,
  UPDATE_BUSINEES_INFORMATION_IC,
  UPDATE_BANK_DETAILS_IC,
} from "../../../../store/actions";
import { SavePersonalInformation, FetchAllCountries, GetUserDetailsUpdated } from '../../../../api'
import { formateDate } from '../../../../utils/utils';
import { toast } from "react-toastify";
import { saveProfileImage } from "../../../../store/actions/saveProfileImageAction";
import Cookies from "js-cookie";


function PersonalInformation() {

  const inputRef = useRef()

  const { data: countries } = FetchAllCountries()
  const { data: userData, } = GetUserDetailsUpdated();
  const [user_information, setPersonalInfromation] = useState(null);
  const [checkUSerType, setCheckUserType] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [profileImage, setProfileImage] = useState(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const personalInformation = useSelector(
    (state) => state.indianCitizenship.personalInformation
  );
  const businessInformationInitialValues = useSelector(
    (state) => state.indianCorporate.businessInformation
  );
  const hufInformation = useSelector(
    (state) => state.indianhuf.personalInformation
  );
  const forign_Information = useSelector(
    (state) => state.foreignCitizenship.personalInformation
  );

  const businessInformation = useSelector(
    (state) => state.foreignCorporate.businessInformation
  );

  useEffect(() => {
    console.log("checkUSerType", checkUSerType)
    console.log("businessInformation", businessInformation)
    if (checkUSerType == "indian_citizen") {
      setPersonalInfromation(personalInformation)
    } else if (checkUSerType == "indian_corporate") {
      setPersonalInfromation(businessInformationInitialValues)
    } else if (checkUSerType == "huf") {
      setPersonalInfromation(hufInformation)
    } else if (checkUSerType == "foreign_citizen") {
      setPersonalInfromation(forign_Information)
    } else {
      setPersonalInfromation(businessInformation)
    }
  }, [personalInformation, businessInformationInitialValues, hufInformation, forign_Information, businessInformation, checkUSerType])

  useEffect(() => {
    if (userData != null) {
      if (userData.data != null) {
        let values = userData.data;
        console.log("UserdATA", values)
        setCheckUserType(values.user_individual_type)
        setProfileImage(values.profile_image)
        console.log("user_individual_type", values.user_individual_type)
        if (values.user_individual_type == "indian_citizen") {
          dispatch({
            type: UPDATE_PERSONALISED_INFORMATION,
            // payload: {
            //   firstName: values.first_name,
            //   middleName: values.middle_name,
            //   lastName: values.last_name,
            //   dateOfBirth: formateDate(values.dob),
            //   mobileNumber: values.mobile_number,
            //   gender: values.gender,
            //   occupation: values.occupation,
            //   countryOfCitizenship: values.country_cityzenship_id,
            //   referralPartner: values.referral_partner,
            //   individual_type: "individual",
            // },
            payload: { ...values, ...{ dob: formateDate(values.dob) } },
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else if (values.user_individual_type == "indian_corporate") {
          dispatch({
            type: UPDATE_BUSINEES_INFORMATION_IC,
            payload: { ...values, ...{ dob: formateDate(values.dob) } },
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_IC, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else if (values.user_individual_type == "huf") {
          dispatch({
            type: UPDATE_PERSONALISED_INFORMATION_HUF,
            // payload: {
            //   legal_entity_name: values.legal_entity_name,
            //   firstName: values.first_name,
            //   middleName: values.middle_name,
            //   lastName: values.last_name,
            //   dateOfBirth: formateDate(values.dob),
            //   mobileNumber: values.mobile_number,
            //   gender: values.gender,
            //   occupation: values.occupation,
            //   countryOfCitizenship: values.country_cityzenship_id,
            //   referralPartner: values.referral_partner,
            //   individual_type: "huf",
            // },
            payload: { ...values, ...{ dob: formateDate(values.dob) } },

          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_HUF, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else if (values.user_individual_type == "foreign_citizen") {
          dispatch({
            type: UPDATE_PERSONAL_INFORMATION_FC,
            // payload: {
            //   firstName: values.first_name,
            //   middleName: values.middle_name,
            //   lastName: values.last_name,
            //   dateOfBirth: formateDate(values.dob),
            //   mobileNumber: values.mobile_number,
            //   gender: values.gender,
            //   occupation: values.occupation,
            //   countryOfCitizenship: values.country_cityzenship_id,
            //   referralPartner: values.referral_partner,
            // }
            payload: { ...values, ...{ dob: formateDate(values.dob) } },
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_FC, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        } else {
          dispatch({
            type: UPDATE_BUSINESS_INFORMATION_FCorp,
            payload: { ...values, ...{ dob: formateDate(values.dob) } }
          });

          let firstname = values.first_name;
          let middlename = values.middle_name;
          let lastname = values.last_name;
          let fullname = null;
          if (middlename == undefined || middlename?.trim()?.length == 0) {
            fullname = firstname + ' ' + lastname
          } else {
            fullname = firstname + ' ' + middlename + ' ' + lastname;
          }
          dispatch({
            type: UPDATE_BANK_DETAILS_FCorp, payload: {
              bankName: "",
              accountHolderName: "",
              accountNumber: "",
              ifscCode: "",
              bankBranch: "",
              accountType: "",
              bank_name: "",
              swift_code: "",
              tin_number: "",
              dp_id: "",
              customer_id: "",
              client_id: "",
              account_details_type: "",
              demat_name: fullname,
              jhDetailsCheckbox: false,
            }
          });
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    dispatch(saveProfileImage(profileImage))
    Cookies.remove("profileImage")
    Cookies.set("profileImage", profileImage)
  }, [profileImage])


  const {
    mutateAsync: savePersonalInformation,
    isLoading: isSavePersonalInformationLoading
  } = SavePersonalInformation()

  const HufValidationSchema = Yup.object().shape({
    legal_entity_name: Yup.string().required("Legal name is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.date().required("Date is required"),
    mobile_number: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    occupation: Yup.string().required("Occupation is required"),
    country_cityzenship_id: Yup.string().required("Please select a country"),
  });
  const IndianCitizenValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.date().required("Date is required"),
    mobile_number: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    occupation: Yup.string().required("Occupation is required"),
    country_cityzenship_id: Yup.string().required("Please select a country"),
  });
  const IndianCorporateValidationSchema = Yup.object().shape({
    legal_entity_name: Yup.string().required("Legal name is required"),
    official_email: Yup.string().required("Official email address is required").email("Invalid email address"),
    authorize_personal_email: Yup.string().required("Authorized person email address is required").email("Invalid email address"),
    official_contact: Yup.string().required("Official contact is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.date().required("Date of Incorporation is required"),
    mobile_number: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    designation: Yup.string().required("Designation is required"),
  });
  const ForeignCorporateValidationSchema = Yup.object().shape({
    legal_entity_name: Yup.string().required("Legal name is required"),
    official_email: Yup.string().required("Official email address is required").email("Invalid email address"),
    authorize_personal_email: Yup.string().required("Authorized person email address is required").email("Invalid email address"),
    official_contact: Yup.string().required("Official contact is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    dob: Yup.date().required("Date of Incorporation is required"),
    country_cityzenship_id: Yup.string().required("Please select a country"),
    mobile_number: Yup.string().required("Mobile number is required"),
    gender: Yup.string().required("Gender is required"),
    designation: Yup.string().required("Designation is required"),
  });

  const getValidationSchema = () => {
    switch (checkUSerType) {
      case 'indian_citizen': {
        return IndianCitizenValidationSchema
      }
      case 'indian_corporate': {
        return IndianCorporateValidationSchema
      }
      case 'huf': {
        return HufValidationSchema
      }
      case 'foreign_citizen': {
        return IndianCitizenValidationSchema
      }
      case 'foreign_corporate': {
        return ForeignCorporateValidationSchema
      }
      default: {
        return IndianCitizenValidationSchema
      }
    }
  }

  const handleFile = () => {
    inputRef.current.click()
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      setSelectedFile(fileUploaded)
      console.log("fileUploaded", fileUploaded)
      let blockedExtensions = ["jpg", "jpeg", "png", "svg"]
      let extension = fileUploaded?.name.split(/[#?]/)[0].split(".").pop().trim()
      if (blockedExtensions.indexOf(extension) > -1) {
        setProfileImage(URL.createObjectURL(fileUploaded))
      } else {
        toast.error("Only images are allowed")
      }
    }
  };

  return (
    <section className="mid-wrap">
      <div className="forms-header pt-3 pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-2">
              <Link to="/choose-option" className="back-arrow">
                <img src={Back} alt="back" />
              </Link>
            </div>
            <div className="col-md-8 col-8">
              <div className="lg-title text-center">Complete Your KYC</div>
            </div>
            <div className="col-md-2 col-2 text-end">
              <span className="pl-15 sm-title">{(checkUSerType == 'indian_citizen' || checkUSerType == 'huf' || checkUSerType == 'foreign_citizen') ? '1/6' : '1/5'}</span>
            </div>
          </div>
          <div className="text-center step-process mt-1">
            <div className="d-inline-flex">
              <span className="process-step selected"></span>
              <span className="process-step"></span>
              <span className="process-step"></span>
              <span className="process-step"></span>
              <span className="process-step"></span>
              {(checkUSerType == 'indian_citizen' || checkUSerType == 'huf' || checkUSerType == 'foreign_citizen') && <span className="process-step"></span>}

            </div>
          </div>
          {/* <h5 className="card-title md-title mt-2 mb-0 text-center heading-formobile">
            Personal Information
          </h5> */}
        </div>
      </div>
      <div className="pt-0 pb-5 kyc-form-block">
        <div className="container">
          <div className="card form-card mt-4">
            <div className="card-body">
              {/* <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                Personal Information
              </h5> */}
              <Formik
                initialValues={user_information}
                enableReinitialize={true}
                validationSchema={getValidationSchema()}
                onSubmit={(values) => {
                  let request = new FormData();
                  request.append('page', "1");
                  request.append('re_submit', false);
                  if (selectedFile?.size) {
                    request.append('profile_image', selectedFile);
                  }
                  if (checkUSerType == 'indian_citizen') {
                    request.append('first_name', values.first_name);
                    request.append('middle_name', values.middle_name?.trim().length > 0 ? values.middle_name : "");
                    request.append('last_name', values.last_name);
                    request.append('mobile_number', values.mobile_number);
                    request.append('gender', values.gender);
                    request.append('dob', values.dob);
                    request.append('referral_partner', values.referral_partner?.trim().length > 0 ? values.referral_partner : "");
                    request.append('occupation', values.occupation);
                    request.append('country_cityzenship_id', values.country_cityzenship_id);
                  }
                  if (checkUSerType == 'indian_corporate') {
                    request.append('first_name', values.first_name);
                    request.append('middle_name', values.middle_name?.trim().length > 0 ? values.middle_name : "");
                    request.append('last_name', values.last_name);
                    request.append('mobile_number', values.mobile_number);
                    request.append('gender', values.gender);
                    request.append('dob', values.dob);
                    request.append('referral_partner', values.referral_partner?.trim().length > 0 ? values.referral_partner : "");
                    request.append('legal_entity_name', values.legal_entity_name);
                    request.append('official_contact', values.official_contact);
                    request.append('official_email', values.official_email);
                    request.append('designation', values.designation);
                    request.append('authorize_personal_email', values.authorize_personal_email);
                  }
                  if (checkUSerType == 'foreign_corporate') {
                    request.append('first_name', values.first_name);
                    request.append('middle_name', values.middle_name?.trim().length > 0 ? values.middle_name : "");
                    request.append('last_name', values.last_name);
                    request.append('mobile_number', values.mobile_number);
                    request.append('gender', values.gender);
                    request.append('dob', values.dob);
                    request.append('referral_partner', values.referral_partner?.trim().length > 0 ? values.referral_partner : "");
                    request.append('legal_entity_name', values.legal_entity_name);
                    request.append('official_contact', values.official_contact);
                    request.append('official_email', values.official_email);
                    request.append('designation', values.designation);
                    request.append('authorize_personal_email', values.authorize_personal_email);
                    request.append('authorize_personal_email', values.authorize_personal_email);
                  }
                  if (checkUSerType == 'huf') {
                    request.append('legal_entity_name', values.legal_entity_name);
                    request.append('first_name', values.first_name);
                    request.append('middle_name', values.middle_name?.trim().length > 0 ? values.middle_name : "");
                    request.append('last_name', values.last_name);
                    request.append('mobile_number', values.mobile_number);
                    request.append('gender', values.gender);
                    request.append('dob', values.dob);
                    request.append('referral_partner', values.referral_partner?.trim().length > 0 ? values.referral_partner : "");
                    request.append('occupation', values.occupation);
                    request.append('country_cityzenship_id', values.country_cityzenship_id);
                  }
                  if (checkUSerType == 'foreign_citizen') {
                    request.append('first_name', values.first_name);
                    request.append('middle_name', values.middle_name?.trim().length > 0 ? values.middle_name : "");
                    request.append('last_name', values.last_name);
                    request.append('mobile_number', values.mobile_number);
                    request.append('gender', values.gender);
                    request.append('dob', values.dob);
                    request.append('referral_partner', values.referral_partner?.trim().length > 0 ? values.referral_partner : "");
                    request.append('occupation', values.occupation);
                    request.append('country_cityzenship_id', values.country_cityzenship_id);
                  }
                  savePersonalInformation(
                    {
                      payload: request,
                    },
                    {
                      onSuccess: () => {
                        let firstname = values.first_name;
                        let middlename = values.middle_name;
                        let lastname = values.last_name;
                        let fullname = null;
                        if (middlename == undefined || middlename?.trim()?.length == 0) {
                          fullname = firstname + ' ' + lastname
                        } else {
                          fullname = firstname + ' ' + middlename + ' ' + lastname;
                        }
                        let bankPayload = {
                          bankName: "",
                          accountHolderName: "",
                          accountNumber: "",
                          ifscCode: "",
                          bankBranch: "",
                          accountType: "",
                          bank_name: "",
                          swift_code: "",
                          tin_number: "",
                          dp_id: "",
                          customer_id: "",
                          client_id: "",
                          account_details_type: "",
                          demat_name: fullname,
                          jhDetailsCheckbox: false,
                        }
                        if (selectedFile?.size) {
                          setProfileImage(URL.createObjectURL(selectedFile));
                        }
                        if (checkUSerType == "indian_citizen") {
                          dispatch({
                            type: UPDATE_PERSONALISED_INFORMATION,
                            payload: {
                              firstName: values.first_name,
                              middleName: values.middle_name,
                              lastName: values.last_name,
                              dateOfBirth: values.dob,
                              mobileNumber: values.mobile_number,
                              gender: values.gender,
                              occupation: values.occupation,
                              countryOfCitizenship: values.country_cityzenship_id,
                              referralPartner: values.referral_partner,
                              individual_type: "individual",
                            },
                          });
                          dispatch({ type: UPDATE_BANK_DETAILS, payload: bankPayload });
                        } else if (checkUSerType == "indian_corporate") {
                          dispatch({
                            type: UPDATE_BUSINEES_INFORMATION_IC,
                            payload: values,
                          });
                          dispatch({ type: UPDATE_BANK_DETAILS_IC, payload: bankPayload });
                        } else if (checkUSerType == "huf") {
                          dispatch({
                            type: UPDATE_PERSONALISED_INFORMATION_HUF,
                            payload: {
                              legal_entity_name: values.legal_entity_name,
                              firstName: values.first_name,
                              middleName: values.middle_name,
                              lastName: values.last_name,
                              dateOfBirth: values.dob,
                              mobileNumber: values.mobile_number,
                              gender: values.gender,
                              occupation: values.occupation,
                              countryOfCitizenship: values.country_cityzenship_id,
                              referralPartner: values.referral_partner,
                              individual_type: "huf",
                            },
                          });
                          dispatch({ type: UPDATE_BANK_DETAILS_HUF, payload: bankPayload });
                        } else if (checkUSerType == "foreign_citizen") {
                          dispatch({
                            type: UPDATE_PERSONAL_INFORMATION_FC, payload: {
                              firstName: values.first_name,
                              middleName: values.middle_name,
                              lastName: values.last_name,
                              dateOfBirth: values.dob,
                              mobileNumber: values.mobile_number,
                              gender: values.gender,
                              occupation: values.occupation,
                              countryOfCitizenship: values.country_cityzenship_id,
                              referralPartner: values.referral_partner,
                            }
                          });
                          dispatch({ type: UPDATE_BANK_DETAILS_FC, payload: bankPayload });
                        } else {
                          dispatch({
                            type: UPDATE_BUSINESS_INFORMATION_FCorp,
                            payload: values,
                          });
                          dispatch({ type: UPDATE_BANK_DETAILS_FCorp, payload: bankPayload });
                        }
                        navigate("/residential-address");
                      },
                    });
                }}
              >
                {({ errors, touched, values }) => (
                  <Form className="custom-form" >
                    {checkUSerType == "foreign_citizen" && <div className="row">
                      <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                        Personal Information
                      </h5>

                      <div className='col-md-12 form-group d-flex align-items-center justify-content-center profile-circle-flex'>
                        <div class="profile-circle-box d-inline-block position-relative" >
                          <div class="profile-circle"><img src={profileImage != null ? profileImage : avtarImg} /></div>
                          <div class="pf-edit-btn" >
                            <i class="bi bi-pencil-fill"></i>
                            <input ref={inputRef} type="file" name="avatar" accept=".jpg, .jpeg, .png, .svg" style={{ display: "none" }} onChange={handleChange} /><button class="btn dashfile-upload-btn" type="button" onClick={handleFile} ></button>
                          </div>
                        </div>
                      </div>
                      {/* First Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">First Name</label>
                        <Field
                          name="first_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-6 form-group">
                        <label>Middle Name</label>
                        <Field
                          name="middle_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Last Name</label>
                        <Field
                          name="last_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Date of Birth */}
                      <div className="col-md-6 form-group">
                        <label className="required">Date of Birth</label>
                        <Field
                          name="dob"
                          component={CustomInputComponent}
                          type="date"
                          className="form-control"
                        />
                      </div>

                      {/* Mobile Number */}
                      <div className="col-md-6 form-group">
                        <label className="required">Mobile Number</label>
                        <Field
                          name="mobile_number"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Gender */}
                      <div className="col-md-6 form-group">
                        <label className="required mb-3">Gender</label>
                        <Field
                          name="gender"
                          component={CustomRadioComponent}
                          className="form-group"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                        />
                      </div>

                      {/* Country of citizenship */}
                      <div className="col-md-6 form-group">
                        <label className="required">Country of Residence</label>
                        <Field
                          name="country_cityzenship_id"
                          component={CustomSelectComponent}
                          options={countries}
                          excludeIndia={"Yes"}
                          className="form-control"
                        />
                      </div>

                      {/* Occupation */}
                      <div className="col-md-6 form-group">
                        <label className="required">Occupation</label>
                        <Field
                          name="occupation"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Referral partner */}
                      <div className="col-md-6 form-group">
                        <label className="">Referral Partner</label>
                        <Field
                          name="referral_partner"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>}
                    {checkUSerType == "indian_citizen" && <div className="row">
                      <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                        Personal Information
                      </h5>

                      <div className='col-md-12 form-group d-flex align-items-center justify-content-center profile-circle-flex'>
                        <div class="profile-circle-box d-inline-block position-relative" >
                          <div class="profile-circle"><img src={profileImage != null ? profileImage : avtarImg} /></div>
                          <div class="pf-edit-btn" >
                            <i class="bi bi-pencil-fill"></i>
                            <input ref={inputRef} type="file" name="avatar" accept=".jpg, .jpeg, .png, .svg" style={{ display: "none" }} onChange={handleChange} /><button class="btn dashfile-upload-btn" type="button" onClick={handleFile} ></button>
                          </div>
                        </div>
                      </div>
                      {/* First Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">First Name</label>
                        <Field
                          name="first_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-6 form-group">
                        <label>Middle Name</label>
                        <Field
                          name="middle_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Last Name</label>
                        <Field
                          name="last_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Date of Birth */}
                      <div className="col-md-6 form-group">
                        <label className="required">Date of Birth</label>
                        <Field
                          name="dob"
                          component={CustomInputComponent}
                          type="date"
                          className="form-control"
                        />
                      </div>

                      {/* Mobile Number */}
                      <div className="col-md-6 form-group">
                        <label className="required">Mobile Number</label>
                        <Field
                          name="mobile_number"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Email Address */}
                      {/* <div className="col-md-6 form-group">
                        <label>Email Address</label>
                        <Field
                          name="emailAddress"
                          component={CustomInputComponent}
                          type="email"
                        />
                      </div> */}

                      {/* Gender */}
                      <div className="col-md-6 form-group">
                        <label className="required mb-3">Gender</label>
                        <Field
                          name="gender"
                          component={CustomRadioComponent}
                          className="form-group"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                        />
                      </div>

                      {/* Country of citizenship */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Country of Residence
                        </label>
                        <Field
                          name="country_cityzenship_id"
                          component={CustomSelectComponent}
                          options={countries}
                          className="form-control"
                        />
                      </div>

                      {/* Occupation */}
                      <div className="col-md-6 form-group">
                        <label className="required">Occupation</label>
                        <Field
                          name="occupation"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Referral partner */}
                      <div className="col-md-6 form-group">
                        <label className="">Referral Partner</label>
                        <Field
                          name="referral_partner"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Country of citizenship */}
                      {/* <div className="col-md-6 form-group">
                        <label className="required">
                          Select Individual Type
                        </label>
                        <Field
                          name="individual_type"
                          component={CustomSelectComponent}
                          options={IndianCitizenUserType.individual_type}
                        />
                      </div> */}

                      {/* NRI Checkbox */}
                      {/* <div className="col-md-12 form-group">
                        <div className="react-checkbox">
                          <Field
                            name="nriCheckbox"
                            component={CustomCheckbox}
                            label="Are you a Non-Resident Indian (NRI)?"
                          />
                        </div>
                      </div> */}
                    </div>}
                    {checkUSerType == "huf" && <div className="row">
                      <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                        Business Information
                      </h5>

                      <div className='col-md-12 form-group d-flex align-items-center justify-content-center profile-circle-flex'>
                        <div class="profile-circle-box d-inline-block position-relative" >
                          <div class="profile-circle"><img src={profileImage != null ? profileImage : avtarImg} /></div>
                          <div class="pf-edit-btn" >
                            <i class="bi bi-pencil-fill"></i>
                            <input ref={inputRef} type="file" name="avatar" accept=".jpg, .jpeg, .png, .svg" style={{ display: "none" }} onChange={handleChange} /><button class="btn dashfile-upload-btn" type="button" onClick={handleFile} ></button>
                          </div>
                        </div>
                      </div>
                      {/* Legal name of the entity */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Name of Incorporation
                        </label>
                        <Field
                          name="legal_entity_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      {/* Date of Birth */}
                      <div className="col-md-6 form-group">
                        <label className="required">Date of Incorporation</label>
                        <Field
                          name="dob"
                          component={CustomInputComponent}
                          type="date"
                          className="form-control"
                        />
                      </div>

                      <h5 className="card-title md-title mb-4 text-center">
                        Personal Information
                      </h5>
                      {/* First Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">First Name</label>
                        <Field
                          name="first_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-6 form-group">
                        <label>Middle Name</label>
                        <Field
                          name="middle_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Last Name</label>
                        <Field
                          name="last_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Mobile Number */}
                      <div className="col-md-6 form-group">
                        <label className="required">Mobile Number</label>
                        <Field
                          name="mobile_number"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Email Address */}
                      {/* <div className="col-md-6 form-group">
                        <label>Email Address</label>
                        <Field
                          name="emailAddress"
                          component={CustomInputComponent}
                          type="email"
                        />
                      </div> */}

                      {/* Gender */}
                      <div className="col-md-6 form-group">
                        <label className="required mb-3">Gender</label>
                        <Field
                          name="gender"
                          component={CustomRadioComponent}
                          className="form-group"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                        />
                      </div>

                      {/* Country of citizenship */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Country of Residence
                        </label>
                        <Field
                          name="country_cityzenship_id"
                          component={CustomSelectComponent}
                          options={countries}
                          className="form-control"
                        />
                      </div>

                      {/* Occupation */}
                      <div className="col-md-6 form-group">
                        <label className="required">Occupation</label>
                        <Field
                          name="occupation"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Referral partner */}
                      <div className="col-md-6 form-group">
                        <label className="">Referral Partner</label>
                        <Field
                          name="referral_partner"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Country of citizenship */}
                      {/* <div className="col-md-6 form-group">
                        <label className="required">
                          Select Individual Type
                        </label>
                        <Field
                          name="individual_type"
                          component={CustomSelectComponent}
                          options={IndianCitizenUserType.individual_type}
                        />
                      </div> */}

                      {/* NRI Checkbox */}
                      {/* <div className="col-md-12 form-group">
                        <div className="react-checkbox">
                          <Field
                            name="nriCheckbox"
                            component={CustomCheckbox}
                            label="Are you a Non-Resident Indian (NRI)?"
                          />
                        </div>
                      </div> */}
                    </div>}
                    {checkUSerType == "indian_corporate" && <div className="row">
                      <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                        Business Information
                      </h5>

                      <div className='col-md-12 form-group d-flex align-items-center justify-content-center profile-circle-flex'>
                        <div class="profile-circle-box d-inline-block position-relative" >
                          <div class="profile-circle"><img src={profileImage != null ? profileImage : avtarImg} /></div>
                          <div class="pf-edit-btn" >
                            <i class="bi bi-pencil-fill"></i>
                            <input ref={inputRef} type="file" name="avatar" accept=".jpg, .jpeg, .png, .svg" style={{ display: "none" }} onChange={handleChange} /><button class="btn dashfile-upload-btn" type="button" onClick={handleFile} ></button>
                          </div>
                        </div>
                      </div>
                      {/* Legal name of the entity */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Legal Name of the Entity
                        </label>
                        <Field
                          name="legal_entity_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Official email address */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Official Email Address
                        </label>
                        <Field
                          name="official_email"
                          component={CustomInputComponent}
                          type="email"
                          className="form-control"
                        />
                      </div>

                      {/* Offical contact */}
                      <div className="col-md-6 form-group">
                        <label className="required">Official Contact</label>
                        <Field
                          name="official_contact"
                          component={CustomInputComponent}
                          type="number"
                          className="form-control"
                        />
                      </div>

                      {/* Date of Birth */}
                      <div className="col-md-6 form-group">
                        <label className="required">Date of Incorporation</label>
                        <Field
                          name="dob"
                          component={CustomInputComponent}
                          type="date"
                          className="form-control"
                        />
                      </div>

                      <h5 className="card-title md-title mb-4 text-center">
                        Authorized Person's Information
                      </h5>
                      {/* First Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">First Name</label>
                        <Field
                          name="first_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-6 form-group">
                        <label>Middle Name</label>
                        <Field
                          name="middle_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Last Name</label>
                        <Field
                          name="last_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>



                      {/* Mobile Number */}
                      <div className="col-md-6 form-group">
                        <label className="required">Mobile Number</label>
                        <Field
                          name="mobile_number"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* authorize_personal_email */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Authorized Person Email Address
                        </label>
                        <Field
                          name="authorize_personal_email"
                          component={CustomInputComponent}
                          type="email"
                          className="form-control"
                        />
                      </div>


                      {/* master_corporate_type_id */}

                      {/* <div className="col-md-6 form-group">
                        <label className="required">
                        Organization Type
                        </label>
                        <Field
                          name="master_corporate_type_id"
                          component={CustomSelectComponent}
                          options={corporateTypes}
                        />
                      </div> */}

                      {/* gender */}
                      <div className="col-md-6 form-group">
                        <label className="required mb-3">Gender</label>
                        <Field
                          name="gender"
                          component={CustomRadioComponent}
                          className="form-group"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                        />
                      </div>
                      {/* Designation */}
                      <div className="col-md-6 form-group">
                        <label className="required">Designation</label>
                        <Field
                          name="designation"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>


                      {/* Referral partner */}
                      <div className="col-md-6 form-group">
                        <label className="">Referral Partner</label>
                        <Field
                          name="referral_partner"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>


                    </div>}
                    {checkUSerType == "foreign_corporate" && <div className="row">
                      <h5 className="card-title md-title mb-4 text-center heading-fordesktop">
                        Business Information
                      </h5>

                      <div className='col-md-12 form-group d-flex align-items-center justify-content-center profile-circle-flex'>
                        <div class="profile-circle-box d-inline-block position-relative" >
                          <div class="profile-circle"><img src={profileImage != null ? profileImage : avtarImg} /></div>
                          <div class="pf-edit-btn" >
                            <i class="bi bi-pencil-fill"></i>
                            <input ref={inputRef} type="file" name="avatar" accept=".jpg, .jpeg, .png, .svg" style={{ display: "none" }} onChange={handleChange} /><button class="btn dashfile-upload-btn" type="button" onClick={handleFile} ></button>
                          </div>
                        </div>
                      </div>
                      {/* Legal name of the entity */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Legal Name of the Entity
                        </label>
                        <Field
                          name="legal_entity_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Official email address */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Official Email Address
                        </label>
                        <Field
                          name="official_email"
                          component={CustomInputComponent}
                          type="email"
                          className="form-control"
                        />
                      </div>

                      {/* Offical contact */}
                      <div className="col-md-6 form-group">
                        <label className="required">Official Contact</label>
                        <Field
                          name="official_contact"
                          component={CustomInputComponent}
                          type="number"
                          className="form-control"
                        />
                      </div>
                      {/* Date of incorporation */}
                      <div className="col-md-6 form-group">
                        <label className="required">Date of Incorporation</label>
                        <Field
                          name="dob"
                          component={CustomInputComponent}
                          type="date"
                          className="form-control"
                        />
                      </div>
                      <h5 className="card-title md-title mb-4 text-center">
                        Authorized Person's Information
                      </h5>
                      {/* First Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">First Name</label>
                        <Field
                          name="first_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Middle Name */}
                      <div className="col-md-6 form-group">
                        <label>Middle Name</label>
                        <Field
                          name="middle_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6 form-group">
                        <label className="required">Last Name</label>
                        <Field
                          name="last_name"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>



                      {/* Mobile Number */}
                      <div className="col-md-6 form-group">
                        <label className="required">Mobile Number</label>
                        <Field
                          name="mobile_number"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Authorized Person Email Address */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Authorized Person Email Address
                        </label>
                        <Field
                          name="authorize_personal_email"
                          component={CustomInputComponent}
                          type="email"
                          className="form-control"
                        />
                      </div>

                      {/* Country of citizenship */}
                      <div className="col-md-6 form-group">
                        <label className="required">
                          Country of Residence
                        </label>
                        <Field
                          name="country_cityzenship_id"
                          component={CustomSelectComponent}
                          options={countries}
                          excludeIndia={"Yes"}
                          className="form-control"
                        />
                      </div>

                      {/* Gender */}
                      <div className="col-md-6 form-group">
                        <label className="required">Gender</label>
                        <Field
                          name="gender"
                          component={CustomRadioComponent}
                          className="form-group"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                        />
                      </div>

                      {/* Designation */}
                      <div className="col-md-6 form-group">
                        <label className="required">Designation</label>
                        <Field
                          name="designation"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      {/* Referral partner */}
                      <div className="col-md-6 form-group">
                        <label className="">Referral Partner</label>
                        <Field
                          name="referral_partner"
                          component={CustomInputComponent}
                          type="text"
                          className="form-control"
                        />
                      </div>


                    </div>}
                    {isSavePersonalInformationLoading ? (
                      <BtnSpinner />
                    ) : (
                      <div className="mt-3 text-center">
                        <button type="submit" className="theme-btn btn w-25">
                          Next
                        </button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {/* <div className="text-center mt-30">
            <a href="#" className="white-btn w-360">
              Terms of Use
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default PersonalInformation;
