import moment from "moment";
import { RedirectUser } from "../api/user/GetUserDetails";

export const kycPaths = ['/choose-option', '/personal-information', '/residential-address', '/bank-details', '/upload-documents', '/nominee-details', '/personalize-your-profile', '/verify-mobile-number', '/verify-mobile-otp', '/registration-status']

export const redirectToKYC = (finalData, navigate, checkUSerType) => {
    console.log("checkUSerType", checkUSerType)
    console.log("page", finalData.data.page)
    const redi = RedirectUser({
        checkUSerType: checkUSerType?.userType,
        page: finalData.data.page ? finalData.data.page : 0,
    });
    console.log(redi);

    if (redi) {
        navigate(redi);
    }
}

export const formateDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
}

export const fetchExtension = (file) => {
    console.log("file", file)
    const name = file.url ? file.url : file.name
    const extension = name.split(/[#?]/)[0].split(".").pop().trim();
    return extension;
}

export const fetchExtensionFromURL = (url) => {
    const extension = url.split(/[#?]/)[0].split(".").pop().trim();
    return extension;
}

export const formateDateWithTimeChat = (date) => {
    return moment(date).format("D MMM, YYYY hh:mm A")
}

export const fixedModulesAmount = (value) => {
    let numberedValue = Number(value);
    return numberedValue % 1 == 0 ? numberedValue.toString() : numberedValue.toFixed(2).replace(/\.?0+$/, '').toString();
}

export const notEmpty = (value) => {
    return !(value === null || value === undefined || value === '' ||
        (Array.isArray(value) && value.length === 0) ||
        (!(value instanceof Date) && typeof value === 'object' && Object.keys(value).length === 0));
}

export const isEmptyObject = (object) => {
    let valuesArray = Object.values(object)
    console.log("demat object check", valuesArray)
    for (const element of valuesArray) {
        if (element.trim() != "" && element != "null" && element != "undefined") {
            return false
        }
    }
    return true
}
export const isRequired = (requiredArray, field) => {
    return requiredArray?.indexOf(field) > -1
}

export const getCMRRequiredKey = (type) => {
    switch (type) {
        case "63aac72be36fdf0309e81b61": {
            return 'documents_cmr_indian_personal_saving'
        }
        case "63aac748e36fdf0309e81b65": {
            return 'documents_cmr_indian_joint_saving'
        }
        case "63aac75ae36fdf0309e81b69": {
            return 'documents_cmr_indian_current'
        }
        case "63aac765e36fdf0309e81b6c": {
            return 'documents_cmr_nre'
        }
        case "63aac76ce36fdf0309e81b6f": {
            return 'documents_cmr_nro'
        }
        case "646614c8c29583a3a4446dee": {
            return 'documents_cmr_foreign_saving'
        }
        case "646614e0c29583a3a4446df0": {
            return 'documents_cmr_foreign_current'
        }
        case "64b0edd07bfb212e65f2d406": {
            return 'documents_cmr_foreign_joint_saving'
        }
        case "6454e32e24efe8b5be5215fd": {
            return 'documents_cmr_foreign'
        }
        default: {
            return 'documents_cmr_indian_personal_saving'
        }
    }
}

// export const isExistInArray = (required, array, field, required_message) => {
//     if (required) {
//         if (field != null && field != "null" && field != undefined && field != "undefined") {
//             if (array?.indexOf(field) > -1) {
//                 return true
//             } else {
//                 return alert(required_message);
//             }
//         } else {
//             return alert(required_message);
//         }
//     } else {
//         return true
//     }
// }
