import { types } from './types';

export function getKycStatus(data) {
    return {
        type: types.SEND_REQUEST_GET_KYC_STATUS,
        payload: data,
    };
}

export function getKycStatusSuccess(data) {
    return {
        type: types.SEND_REQUEST_GET_KYC_STATUS_SUCCESS,
        payload: data,
    };
}

export function getKycStatusFailure(error) {
    return {
        type: types.SEND_REQUEST_GET_KYC_STATUS_FAILURE,
        payload: {},
        error: error,
    };
}

export function clearGetKycStatus() {
    return {
        type: types.CLEAR_REQUEST_GET_KYC_STATUS,
        payload: {},
    };
}
