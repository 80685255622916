const Modal = ({ commonModalTitle = '', commonModalShow, commonModalClose, crossHide, commonModalData, hideHeading, isDocumentModal, bodyPadding, modalWidth }) => {
    return (
        <div
            className={`modal fade common-modal ${isDocumentModal ? "document-modal" : ""} ${commonModalShow ? 'show' : ''}`}
            tabIndex='-1'
            aria-hidden='true'
        >
            <div className={`modal-dialog modal-dialog-centered ${modalWidth === "md" ? "modal-md":"modal-lg"}`}>
                <div className='modal-content'>
                    <div className={`${bodyPadding === true ? "modal-body p-0" : "modal-body"} `}>
                        {!hideHeading === "true" ?
                            <div className='d-flex align-items-center justify-content-between mb-4'>
                                <h5 className='md-title mb-0' id='documentModalLabel'>
                                    {commonModalTitle}
                                </h5>
                                {crossHide ? "" : <button
                                    type='button'
                                    className='btn-close'
                                    onClick={commonModalClose}
                                ></button>
                                }

                            </div> : ""
                        }


                        {commonModalData}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
