import {types} from '../actions/types';

const initialState = {
  loading: false,
  data: {},
  error: {},
};

const getDynamicMessagesReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case types.CLEAR_REQUEST_DYNAMIC_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case types.SEND_REQUEST_DYNAMIC_MESSAGES_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case types.SEND_REQUEST_DYNAMIC_MESSAGES_FAILURE:
      return {
        ...state,
        data: {},
        error: payload,
        loading: false,
      };
      case types.CLEAR_REQUEST_DYNAMIC_MESSAGES:
        return {
          ...state,
          data: {},
          loading: false,
        };
    default:
      return state;
  }
};

export default getDynamicMessagesReducer;
