// action - Indian Citizenship reducer
export const UPDATE_PERSONALISED_INFORMATION = '@indianCitizenship/UPDATE_PERSONALISED_INFORMATION';
export const UPDATE_RESIDENTIAL_ADDRESS = '@indianCitizenship/UPDATE_RESIDENTIAL_ADDRESS';
export const UPDATE_BANK_DETAILS = '@indianCitizenship/UPDATE_BANK_DETAILS';
export const UPDATE_DOCUMENTS = '@indianCitizenship/UPDATE_DOCUMENTS';
export const UPDATE_MORE_ABOUT_YOU = '@indianCitizenship/UPDATE_MORE_ABOUT_YOU';
export const UPDATE_NOMINEE_DETAILS = '@indianCitizenship/UPDATE_NOMINEE_DETAILS';
export const UPDATE_PERSONALIZE_YOUR_PROFILE = '@indianCitizenship/UPDATE_PERSONALIZE_YOUR_PROFILE';
export const SET_PAGE = '@indianCitizenship/SET_PAGE';

export const INTITIALIZE_INDIAN_CITIZEN = '@indianCitizenship/INTITIALIZE_INDIAN_CITIZEN';
export const RESET_INDIAN_CITIZEN = '@indianCitizenship/RESET_INDIAN_CITIZEN';
// export const INCREMENT_INDEX = '@indianCitizenship/INCREMENT_INDEX';
// export const DECREMENT_INDEX = '@indianCitizenship/DECREMENT_INDEX';




//action- Indian HUF 
export const UPDATE_PERSONALISED_INFORMATION_HUF = '@indianhuf/UPDATE_PERSONALISED_INFORMATION_HUF';
export const UPDATE_RESIDENTIAL_ADDRESS_HUF = '@indianhuf/UPDATE_RESIDENTIAL_ADDRESS_HUF';
export const UPDATE_BANK_DETAILS_HUF = '@indianhuf/UPDATE_BANK_DETAILS_HUF';
export const UPDATE_DOCUMENTS_HUF = '@indianhuf/UPDATE_DOCUMENTS_HUF';
export const UPDATE_MORE_ABOUT_YOU_HUF = '@indianhuf/UPDATE_MORE_ABOUT_YOU_HUF';
// export const UPDATE_NOMINEE_DETAILS_HUF = '@indianHUF/UPDATE_NOMINEE_DETAILS';
export const UPDATE_PERSONALIZE_YOUR_PROFILE_HUF = '@indianhuf/UPDATE_PERSONALIZE_YOUR_PROFILE_HUF';
export const SET_PAGE_HUF = '@indianhuf/SET_PAGE_HUF';

export const INTITIALIZE_INDIAN_CITIZEN_HUF = '@indianhuf/INTITIALIZE_INDIAN_CITIZEN_HUF';
export const RESET_INDIAN_CITIZEN_HUF = '@indianhuf/RESET_INDIAN_CITIZEN_HUF';


// action - Foreign Citizenship reducer
export const UPDATE_PERSONAL_INFORMATION_FC =
  "@foreignCitizenship/UPDATE_PERSONAL_INFORMATION_FC";
export const UPDATE_RESIDENTIAL_ADDRESS_FC =
  "@foreignCitizenship/UPDATE_RESIDENTIAL_ADDRESS_FC";
export const UPDATE_CURRENT_ADDRESS_FC =
  "@foreignCitizenship/UPDATE_CURRENT_ADDRESS_FC";
export const UPDATE_BANK_DETAILS_FC =
  "@foreignCitizenship/UPDATE_BANK_DETAILS_FC";
export const UPDATE_JOINT_HOLDER_DETAILS_FC =
  "@foreignCitizenship/UPDATE_JOINT_HOLDER_DETAILS_FC";
export const UPDATE_UPLOAD_DOCUMENTS_FC =
  "@foreignCitizenship/UPDATE_UPLOAD_DOCUMENTS_FC";
export const UPDATE_MORE_ABOUT_YOU_FC =
  "@foreignCitizenship/UPDATE_MORE_ABOUT_YOU_FC";
export const UPDATE_NOMINEE_DETAILS_FC =
  "@foreignCitizenship/UPDATE_NOMINEE_DETAILS_FC";
export const UPDATE_GUARDIAN_DETAILS_FC =
  "@foreignCitizenship/UPDATE_GUARDIAN_DETAILS_FC";
export const UPDATE_PESONALIZE_YOUR_PROFILE_FC =
  "@foreignCitizenship/UPDATE_PERSONALIZE_YOUR_PROFILE_FC";
export const SET_PAGE_FC = "foreignCitizenship/SET_PAGE_FC";
export const INTITIALIZE_FOREIGN_CITIZEN = "foreignCitizenship/INTITIALIZE_FOREIGN_CITIZEN";
export const RESET_FOREIGN_CITIZEN = "foreignCitizenship/RESET_FOREIGN_CITIZEN";






// action - indian corporate reducer
export const UPDATE_BUSINEES_INFORMATION_IC =
  "@indianCorporate/UPDATE_BUSINEES_INFORMATION_IC";
export const SET_PAGE_IC = "@indianCorporate/SET_PAGE_IC";
export const UPDATE_RESIDENTIAL_ADDRESS_IC =
  "@indianCorporate/UPDATE_RESIDENTIAL_ADDRESS_IC";
 export const UPDATE_BANK_DETAILS_IC = '@indianCorporate/UPDATE_BANK_DETAILS_IC';
 export const UPDATE_MORE_ABOUT_YOU_IC =
  "@indianCorporate/UPDATE_MORE_ABOUT_YOU_IC";
  export const UPDATE_PESONALIZE_YOUR_PROFILE_IC =
  "@indianCorporate/UPDATE_PESONALIZE_YOUR_PROFILE_IC";
  export const UPDATE_DOCUMENTS_IC = '@indianCorporate/UPDATE_DOCUMENTS_IC';
  export const INTITIALIZE_INDIAN_CORPORATE = '@indianCorporate/INTITIALIZE_INDIAN_CORPORATE';
  export const RESET_INDIAN_CORPORATE = '@indianCorporate/RESET_INDIAN_CORPORATE';
  


// action - User Details reducer
export const SET_USER_TYPE = '@userDetails/SET_USER_TYPE';

// action - foreign corporate reducer
export const UPDATE_BUSINESS_INFORMATION_FCorp = '@foreignCorporate/UPDATE_BUSINESS_INFORMATION_FCorp';
export const UPDATE_RESIDENTIAL_ADDRESS_FCorp = '@foreignCorporate/UPDATE_RESIDENTIAL_ADDRESS_FCorp';
export const UPDATE_CURRENT_ADDRESS_FCorp = '@foreignCorporate/UPDATE_CURRENT_ADDRESS_FCorp';
export const UPDATE_BANK_DETAILS_FCorp = '@foreignCorporate/UPDATE_BANK_DETAILS_FCorp';
export const UPDATE_JOINT_HOLDER_DETAILS_FCorp = '@foreignCorporate/UPDATE_JOINT_HOLDER_DETAILS_FCorp';
export const UPDATE_UPLOAD_DOCUMENTS_FCorp = '@foreignCorporate/UPDATE_UPLOAD_DOCUMENTS_FCorp';
export const UPDATE_MORE_ABOUT_YOU_FCorp = '@foreignCorporate/UPDATE_MORE_ABOUT_YOU_FCorp';
export const UPDATE_PERSONALIZE_YOUR_PROFILE_FCorp = '@foreignCorporate/UPDATE_PERSONALIZE_YOUR_PROFILE_FCorp';
export const SET_PAGE_FCorp = "foreignCitizenship/SET_PAGE_FCorp";
export const INTITIALIZE_FOREIGN_CORPORATE = '@foreignCorporate/INTITIALIZE_FOREIGN_CORPORATE';
export const RESET_FOREIGN_CORPORATE = '@foreignCorporate/RESET_FOREIGN_CORPORATE';