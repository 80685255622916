// project imports
// action - state management
import * as actionTypes from "./actions";
import moment from "moment";

export const initialState = {
  page: 0,
  businessInformation: {
    legal_entity_name: "",
    official_email: "",
    official_contact: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: "",
    mobile_number: "",
    gender: "",
    designation: "",
    master_corporate_type_id: "",
    referral_partner: "",
    authorize_personal_email: "",
    profileImage: null,
  },
  residentialAddress: {
    addressLineOne: "",
    addressLineTwo: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    currentAddressDifferentCheckbox: false,
    addressLineOneCurrent: "",
    addressLineTwoCurrent: "",
    countryCurrent: "",
    stateCurrent: "",
    cityCurrent: "",
    postalCodeCurrent: "",
  },
  bankDetails: {
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    bankBranch: "",
    accountType: "",
    jhDetailsCheckbox: false,
    jointHolderName: "",
    jointHolderMobileNumber: "",
    jointHolderEmail: "",
    jointHolderPAN: "",
    bank_name: "",
    swift_code: "",
    tin_number: "",
    id_name: "",
    dp_id: "",
    customer_id: "",
    client_id: "",
    account_details_type: "",
    demat_name: "",
    fileCMR: null
  },
  documents: {
    certificate: null,
    moaAndAoa: null,
    addressProof: null,
  },
  moreAboutYou: {
    haveInvestedBefore: "",
    howHearAboutUs: "",
    referralName: "",
    moreAboutYou: "",
  },
  personalizeYourProfile: {
    expertiseInSectors: [],
    investInSectors: [],
    investInStartUps: [],
    howPreferInvest: "",
    linkedInProfileUrl: "",
    confirmation: [],
    commitment_amount: ""
  },
};

// ==============================|| Indian Citizenship REDUCER ||============================== //

const indianCitizenship = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE_IC:
      return {
        ...state,
        page: action.payload,
      };
    case actionTypes.UPDATE_BUSINEES_INFORMATION_IC:
      return {
        ...state,
        businessInformation: action.payload,
      };
    case actionTypes.UPDATE_RESIDENTIAL_ADDRESS_IC:
      return {
        ...state,
        residentialAddress: action.payload,
      };
    case actionTypes.UPDATE_BANK_DETAILS_IC:
      return {
        ...state,
        bankDetails: action.payload,
      };
    case actionTypes.UPDATE_DOCUMENTS_IC:
      return {
        ...state,
        documents: action.payload,
      };
    case actionTypes.UPDATE_MORE_ABOUT_YOU_IC:
      return {
        ...state,
        moreAboutYou: action.payload,
      };

    case actionTypes.UPDATE_PESONALIZE_YOUR_PROFILE_IC:
      return {
        ...state,
        personalizeYourProfile: action.payload,
      };

    case actionTypes.INTITIALIZE_INDIAN_CORPORATE:
      const data = action.payload.data;
      const address = action.payload.addresses ? action.payload.addresses : [];

      const docs = data?.documents?.filter(
        (doc) => doc.slug === "onboarding_documents"
      );
      let cmr = docs[0]?.documents?.filter(item => { return item.upload_type == "client_master_report" })

      const investerPermaAdd = address?.filter(
        (add) =>
          add.user_type === "investor" && add.address_type === "residential"
      );

      const investerCurrentAdd = address?.filter(
        (add) => add.user_type === "investor" && add.address_type === "current"
      );

      let firstname = data.first_name;
      let middlename = data.middle_name;
      let lastname = data.last_name;
      let fullname = null;
      if (middlename == undefined || middlename?.trim()?.length == 0) {
        fullname = firstname + ' ' + lastname
      } else {
        fullname = firstname + ' ' + middlename + ' ' + lastname;
      }

      const newInitialState = {
        page: 0,
        businessInformation: {
          legal_entity_name: data.legal_entity_name || "",
          official_email: data.official_email || "",
          official_contact: data.official_contact || "",
          first_name: data.first_name || data.first_name || "",
          middle_name: data.middle_name || "",
          last_name: data.last_name || "",
          dob: data.dob ? moment(data.dob).utc().format('YYYY-MM-DD') : '',
          mobile_number: data.mobile_number || "",
          gender: data.gender || "",
          designation: data.designation || "",
          master_corporate_type_id: data.master_corporate_type_id || "",
          referral_partner: data.referral_partner || "",
          authorize_personal_email: data.authorize_personal_email || "",
          profileImage: data.profile_image || null,
        },
        residentialAddress: {
          addressLineOne: investerPermaAdd[0]?.address_line_1 || '',
          addressLineTwo: investerPermaAdd[0]?.address_line_2 || '',
          country: investerPermaAdd[0]?.country_id || '',
          state: investerPermaAdd[0]?.state_id || '',
          city: investerPermaAdd[0]?.city_id || '',
          postalCode: investerPermaAdd[0]?.zip_code || '',

          currentAddressDifferentCheckbox: investerCurrentAdd[0] ? true : false,
          addressLineOneCurrent: investerCurrentAdd[0]?.address_line_1 || '',
          addressLineTwoCurrent: investerCurrentAdd[0]?.address_line_2 || '',
          countryCurrent: investerCurrentAdd[0]?.country_id || '',
          stateCurrent: investerCurrentAdd[0]?.state_id || '',
          cityCurrent: investerCurrentAdd[0]?.city_id || '',
          postalCodeCurrent: investerCurrentAdd[0]?.zip_code || '',
        },
        bankDetails: {
          bankName: data.bankAccounts[0]?.bank_id?._id || '',
          accountHolderName: data.bankAccounts[0]?.account_holder_name || '',
          accountNumber: data.bankAccounts[0]?.bank_account_number || '',
          ifscCode: data.bankAccounts[0]?.ifsc_code || '',
          bankBranch: data.bankAccounts[0]?.branch_name || '',
          accountType: data.bankAccounts[0]?.account_type_id || '',
          jhDetailsCheckbox: data.bankAccounts[0]?.bank_id ? true : false,
          jointHolderName: data.bankAccounts[0]?.accounts[0]?.joint_holder_name || '',
          jointHolderMobileNumber: data.bankAccounts[0]?.accounts[0]?.joint_holder_mobile_number || '',
          jointHolderEmail: data.bankAccounts[0]?.accounts[0]?.joint_holder_email || '',
          jointHolderPAN: data.bankAccounts[0]?.accounts[0]?.joint_holder_pan_number || '',
          bank_name: data.bankAccounts[0]?.bank_name || '',
          swift_code: data.bankAccounts[0]?.swift_code || '',
          tin_number: data.tin_number || '',
          id_name: data.bankAccounts[0]?.demat?.id_name || "",
          dp_id: data.bankAccounts[0]?.demat?.dp_id || "",
          customer_id: data.bankAccounts[0]?.demat?.customer_id || "",
          client_id: data.bankAccounts[0]?.demat?.client_id || "",
          account_details_type: data.bankAccounts[0]?.demat?.account_details_type || "",
          demat_name: fullname || "",
          fileCMR: cmr
        },
        documents: {
          certificate: null,
          moaAndAoa: null,
          addressProof: null,
        },
        moreAboutYou: {
          haveInvestedBefore: data.more_about_you_static[0]?.haveInvestedBefore || '',
          howHearAboutUs: data.more_about_you_static[0]?.howHearAboutUs || '',
          referralName: data.more_about_you_static[0]?.referralName || '',
          moreAboutYou: data.more_about_you_static[0]?.moreAboutYou || '',
        },
        personalizeYourProfile: {
          expertiseInSectors: data.personalize_profile_static[0]?.expertiseInSectors || [],
          investInSectors: data.personalize_profile_static[0]?.investInSectors || [],
          investInStartUps: data.personalize_profile_static[0]?.investInStartUps || [],
          howPreferInvest: data.personalize_profile_static[0]?.howPreferInvest || '',
          linkedInProfileUrl: data.personalize_profile_static[0]?.linkedInProfileUrl || '',
          confirmation: data.personalize_profile_static[0]?.confirmation || [],
          commitment_amount: data.personalize_profile_static[0]?.commitment_amount || ""
        },
      };

      return newInitialState;

    case actionTypes.RESET_INDIAN_CORPORATE:
      return initialState;

    default:
      return state;
  }
};

export default indianCitizenship;
