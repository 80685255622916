// Packages
import { BrowserRouter } from 'react-router-dom';

// Configs
import config from './config';

// CSS Files
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// Routes
//import AppRoutes from './AppRoutes';

import Routes from './routes';
import ScrollToTop from './utils/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setConstants } from './utils/constants';
import { getDynamicMessages } from './store/actions/getDynamicMessagesAction';


const App = () => {

    const dispatch = useDispatch();
    const dynamicMessagesResponse = useSelector(state => state.getDynamicMessagesReducer.data)

    useEffect(() => {
        dispatch(getDynamicMessages())
    }, [])


    useEffect(() => {
        if (dynamicMessagesResponse != null) {
            console.log("dynamicMessagesResponse", dynamicMessagesResponse.data)
            let info = {
                PORTFOLIO_INFO: dynamicMessagesResponse?.data?.portfolio_html,
                DEAL_INTEREST_TEXT: dynamicMessagesResponse?.data?.dealInterestText,
                CMR_NOTE: dynamicMessagesResponse?.data?.demat_client_note
            }
            setConstants(info)
        }
    }, [dynamicMessagesResponse])

    return (
        <BrowserRouter basename={config.basename}>
            <ScrollToTop />
            <Routes />
        </BrowserRouter>
    );
};

export default App;
